import React from 'react';
import { Box, Divider } from '@mui/material';
import styles from './policy.module.css';
import { MotionBlock } from '../../components/motionBlock';
import { Logo } from '../../components/logo/logo';
import { ContentPageWrapper } from '../../components/contentPageWrapper';
import { Props } from './policy.types';

export const Policy: React.FC<Props> = ({ title, lastUpdatedAt, provider, children }) => {
  const outerElements = (
    <Box className={styles.logo}>
      <MotionBlock transitionDuration={1.5}>
        <Logo />
      </MotionBlock>
    </Box>
  );

  const innerElements = (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>{title}</h1>
      </div>
      <p className={styles.lastUpdated}>
        <strong>Last updated {lastUpdatedAt}</strong>
      </p>
      <Divider />
      <div className={styles.body}>{children}</div>
      {provider}
    </div>
  );

  return <ContentPageWrapper outerBoxElements={outerElements} innerBoxElements={innerElements} />;
};
