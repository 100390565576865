import { GameState } from '../useGameState';
import { CardNames, DICE_MANIPULATION_CARDS } from '../../../../card/cardTypes';
import { DraggedCardData } from '../../../../../components/draggable';

export type UseApplyCardRulesResult = {
  fillHandCardsAllowedApplyTypes: (gameState: GameState) => void;
  addApplyActiveEnergyNotificationOnDragging: (isDragging: boolean, draggableData: DraggedCardData) => void;
};

export type UseApplyCardRules = () => UseApplyCardRulesResult;

export const ALLOWED_DUPLICATED_ACTION_CARDS = [
  CardNames.ESSENCE_SHIELD,
  CardNames.BLACK_HOLE,
  CardNames.SPACE_DISTORTION,
  CardNames.DISINTEGRATOR,
  ...DICE_MANIPULATION_CARDS,
];
