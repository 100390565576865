import { ProjectColors } from '../themes/mainTheme';
import { zIndexes } from './components';
import { SxProps } from '@mui/material';

export const CONTENT_BOX_SX = {
  border: `3px solid ${ProjectColors.PURPLE}`,
  borderOpacity: '5%',
  borderStyle: 'ridge',
  backgroundColor: ProjectColors.PURPLE_VERY_DARK_TRANSPARENT,
  minHeight: '530px',
  maxHeight: '90%',
  zIndex: zIndexes.contentBox,
};

export const MODAL_CTA_BUTTON_LENGTH = '180px';

export const ACTION_BUTTON_SX = {
  borderRadius: '5px',
  backgroundColor: ProjectColors.ESSENCE_TRANSPARENT,
  color: `${ProjectColors.WHITE}`,
  verticalAlign: 'middle',
  boxShadow: `0.4px 0.4px 0.4px 0.4px ${ProjectColors.ESSENCE}`,
  border: `2px groove ${ProjectColors.GREY}`,
  '&:hover': {
    borderColor: ProjectColors.ESSENCE,
    backgroundColor: ProjectColors.BLUE_TRANSPARENT,
    color: ProjectColors.WHITE,
  },
};

export const TYPOGRAPHY_SHADOW_SX = {
  textShadow: `2px 2px 2px ${ProjectColors.PURPLE_VERY_DARK}`,
};

export const BOX_SHADOW_ELEMENT_HIGHER_LAYER = `0 8px 10px 0 ${ProjectColors.TRANSPARENT_BLACK}, 0 16px 20px 0 ${ProjectColors.TRANSPARENT_BLACK}`;
export const BOX_SHADOW_ELEMENT_LOWER_LAYER = `0 4px 6px 0 ${ProjectColors.TRANSPARENT_BLACK}, 0 6px 15px 0 ${ProjectColors.TRANSPARENT_BLACK}`;

export const BASE_MOBILE_PLAYER_CARD_SX: SxProps = {
  position: 'absolute',
  width: '40%',
  height: '40%',
  zIndex: 1,
};

export const MOBILE_PLAYER_ESSENCE_BAR_SX: SxProps = {
  top: '-20%',
  position: 'absolute',
  height: '22%',
  width: '40%',
  zIndex: zIndexes.one,
};

export const PLAYER_STATE_BOX_SX = {
  width: '100%',
  height: '100%',
  position: 'absolute',
};

export const CONTAINER_OUTER_COMMON_SX = {
  flex: '1',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

export const CONTAINER_INNER_COMMON_SX = {
  display: 'flex',
  position: 'relative',
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  backgroundColor: `${ProjectColors.PURPLE_VERY_DARK_TRANSPARENT}`,
  border: `2px groove ${ProjectColors.BLUE_QUANTUM_DICE}`,
  borderRadius: '30px',
};
