import { CardType, PlayingCard, PlayingCardType, RoleCard } from '../features/card/cardTypes';
import { ImagePath } from '../assets/assets';
import { ProjectColors } from '../themes/mainTheme';
import { DragProps, Props as CardProps, SelectorProps } from '../components/card';
import { SizeExtended } from './sizes';
import { PlayerCardGroup } from '../features/game/gameContextHandler';
import { EssenceUpdateType } from '../features/game/gameContext';
import { BlocksDirection } from './player';
import { DisperserLocation } from '../components/essenceBar';
import { LogType } from '../features/game/logContextProvider';
import { cardLabel } from './labels';

export type fromTo<X extends string | number | symbol, Y> = { [_ in X]: Y };

export const CARD_TYPE_TO_COVER_IMAGE: fromTo<CardType, string> = {
  [CardType.ROLE]: ImagePath.roleCardCover,
  [CardType.PLAYING_CARD]: ImagePath.playingCardCover,
};

export const CARD_TYPE_TO_BACKGROUND_COLOR: fromTo<PlayingCardType, ProjectColors> = {
  [PlayingCardType.ARSENAL]: ProjectColors.PLAY_CARD_ARSENAL,
  [PlayingCardType.NEBULA]: ProjectColors.PLAY_CARD_NEBULA,
  [PlayingCardType.RADIANCE]: ProjectColors.PLAY_CARD_RADIANCE,
  [PlayingCardType.CAPSULE]: ProjectColors.PLAY_CARD_CAPSULE,
};

export const ESSENCE_UPDATE_TYPE_TO_LOG_TYPE: fromTo<EssenceUpdateType, LogType> = {
  [EssenceUpdateType.ADD_ONE]: 'success',
  [EssenceUpdateType.REMOVE_ONE]: 'error',
};

export const ESSENCE_UPDATE_TYPE_TO_LOG_TEXT: fromTo<EssenceUpdateType, string> = {
  [EssenceUpdateType.ADD_ONE]: 'essence enclosed',
  [EssenceUpdateType.REMOVE_ONE]: 'essence lost',
};

export const PLAYER_CARD_GROUP_TO_LOG_TEXT: fromTo<PlayerCardGroup, string> = {
  cardsInHand: 'added to Hand',
  cardsOnTable: 'applied to Arsenal',
  activeRadiances: 'applied',
};

export const PLAYER_CARD_GROUP_TO_LOG_TYPE: fromTo<PlayerCardGroup, LogType> = {
  cardsInHand: 'info',
  cardsOnTable: 'warning',
  activeRadiances: 'warning',
};

export const BLOCKS_DIRECTION_TO_DISPERSER_LOCATION: fromTo<BlocksDirection, DisperserLocation> = {
  [BlocksDirection.LEFT_TO_RIGHT]: DisperserLocation.RIGHT,
  [BlocksDirection.RIGHT_TO_LEFT]: DisperserLocation.LEFT,
};

const playingCardToComponent = (
  card: PlayingCard,
  size: SizeExtended,
  rotation: number = 0,
  dragProps?: DragProps,
  selectorProps?: SelectorProps
): CardProps => ({
  isOpen: card.isOpen,
  size: size,
  imagePath: card.image,
  backCoverImagePath: CARD_TYPE_TO_COVER_IMAGE[card.cardType],
  content: { type: card.playingCardType, name: cardLabel(card), details: card.details },
  backgroundColor: CARD_TYPE_TO_BACKGROUND_COLOR[card.playingCardType],
  rotation: rotation,
  dragProps: dragProps,
  selectorProps: selectorProps,
});

const roleCardToComponent = (
  card: RoleCard,
  size: SizeExtended,
  rotation: number = 0,
  dragProps?: DragProps,
  selectorProps?: SelectorProps
): CardProps => ({
  isOpen: card.isOpen,
  size: size,
  imagePath: card.image,
  backCoverImagePath: CARD_TYPE_TO_COVER_IMAGE[card.cardType],
  content: { type: 'Role', name: card.role, details: card.details },
  backgroundColor: ProjectColors.ROLE_CARD,
  rotation: rotation,
  dragProps: dragProps,
  selectorProps: selectorProps,
});
export const cardToComponent = (
  card: PlayingCard | RoleCard,
  size: SizeExtended,
  dragProps?: DragProps,
  selectorProps?: SelectorProps,
  rotation: number = 0
): CardProps => {
  switch (card.cardType) {
    case CardType.PLAYING_CARD:
      return playingCardToComponent(card as PlayingCard, size, rotation, dragProps, selectorProps);
    case CardType.ROLE:
      return roleCardToComponent(card as RoleCard, size, rotation, dragProps, selectorProps);
  }
};

export const getPlaceholderCardComponent = (size: SizeExtended, backCoverImage?: string): CardProps => ({
  isOpen: false,
  size: size,
  imagePath: '',
  backCoverImagePath: backCoverImage ?? ImagePath.noCardPlaceholder,
  content: { type: '', name: '', details: '' },
  backgroundColor: ProjectColors.TRANSPARENT,
  rotation: 0,
  customStyles: { opacity: '70%' },
});
