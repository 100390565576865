import {
  Box,
  CardMedia,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import React, { ChangeEvent, SyntheticEvent } from 'react';
import { ProjectColors } from '../../../../../themes/mainTheme';
import {
  AccessMode,
  CLASSIC_MODE,
  GAME_MODES,
  GameMode,
  PRIVATE_ACCESS_MODE,
  PUBLIC_ACCESS_MODE,
  SIMPLE_MODE,
} from '../../../../game/gameTypes';
import { EXTRA_SMALL_SIZE_HEIGHT } from '../../../../../constants/components';
import { PlayingCard } from '../../../../card/cardTypes';
import { SizeExtended } from '../../../../../helpers/sizes';
import { Card } from '../../../../../components/card';
import { cardToComponent } from '../../../../../helpers/transformers';
import { usePreparePlayingCards } from '../../../../game/gameContextHandler/hooks/usePreparePlayingCards';
import { useWindowSize } from '../../../../../helpers/utils';
import { ImagePath } from '../../../../../assets/assets';
import { UseGameMode } from './useGameMode.types';
import { AppContext } from '../../../../../global/context/appContext';
import { sx } from './useGameMode.styles';
import { RadioButtonUnchecked } from '@mui/icons-material';

export const useGameMode: UseGameMode = () => {
  const window = useWindowSize();
  const showGameModeCards = window.height >= EXTRA_SMALL_SIZE_HEIGHT;

  const [gameMode, setGameMode] = React.useState<GameMode>(CLASSIC_MODE);
  const [accessMode, setAccessMode] = React.useState<AccessMode>(PRIVATE_ACCESS_MODE);
  const [gameModePlayingCards, setGameModePlayingCards] = React.useState<PlayingCard[]>([]);

  const { filterByGameMode } = usePreparePlayingCards();
  const { allPlayingCards } = React.useContext(AppContext);

  React.useEffect(() => {
    setGameModePlayingCards(filterByGameMode(allPlayingCards, gameMode));
  }, [gameMode, allPlayingCards]);

  const handleGameModeChange = (_: ChangeEvent<HTMLInputElement>, newMode: string) => {
    setGameMode(GAME_MODES.filter((mode) => (newMode ?? CLASSIC_MODE.name) === mode.name)[0]);
  };

  const handleAccessModeChange = (_: SyntheticEvent<Element, Event>, checked: boolean) => {
    setAccessMode(checked ? PUBLIC_ACCESS_MODE : PRIVATE_ACCESS_MODE);
  };

  const switcherIcon = (isChecked: boolean) => (
    <Box sx={{ ...sx.switcherIconBlock, borderColor: `${isChecked ? ProjectColors.PURPLE : ProjectColors.GREY}` }}>
      <Box sx={sx.switcherIconImageBlock}>
        <CardMedia component="img" alt="discard-pile" image={ImagePath.logoOutline} sx={sx.switcherIconImage} />
      </Box>
    </Box>
  );

  const gameModeBlock = () => {
    return (
      <Box sx={sx.gameModeBlock}>
        <Box sx={sx.gameModeFormBlock}>
          <FormControl>
            <RadioGroup
              name="game-mode-radio-group"
              sx={sx.gameModeRadioGroup}
              value={gameMode ? gameMode.name : CLASSIC_MODE.name}
              onChange={handleGameModeChange}
            >
              <FormControlLabel
                sx={sx.gameModeSimpleLabel}
                value={SIMPLE_MODE.name}
                control={<Radio checkedIcon={switcherIcon(true)} />}
                label={SIMPLE_MODE.name}
              />
              <FormControlLabel
                value={CLASSIC_MODE.name}
                control={<Radio checkedIcon={switcherIcon(true)} />}
                label={CLASSIC_MODE.name}
              />
            </RadioGroup>
          </FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  icon={<RadioButtonUnchecked />}
                  checkedIcon={switcherIcon(true)}
                  onChange={handleAccessModeChange}
                />
              }
              label={PUBLIC_ACCESS_MODE.name}
              sx={sx.accessModeControlLabel}
            />
          </FormGroup>
        </Box>
        {showGameModeCards && (
          <Box sx={sx.gameModeCardsBlock}>
            {gameModePlayingCards.map((card, index) => (
              <Box
                key={`grouped-card-` + index}
                onClick={() => undefined}
                onMouseEnter={() => undefined}
                onMouseLeave={() => undefined}
              >
                <Card {...cardToComponent(card, SizeExtended.XS)} customDisplayType={'showImageOnly'} />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  const gameDescriptionBlock = () => {
    return (
      <Box sx={sx.gameModeBlock}>
        <Typography sx={sx.gameModeDescription}>{gameMode.description + '. ' + accessMode.description}</Typography>
      </Box>
    );
  };

  return {
    gameDescriptionBlock: gameDescriptionBlock,
    gameModeBlock: gameModeBlock,
    gameMode: gameMode,
    accessMode: accessMode,
  };
};
