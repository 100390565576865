import React from 'react';
import { Props } from './logo.types';
import { Box } from '@mui/material';
import { ImagePath } from '../../../../../assets/assets';
import ArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import styles from './logo.module.css';
import cn from 'classnames';
import { isPortraitOrientation } from '../../../../../helpers/utils';
import { Capacitor } from '@capacitor/core';

export const Logo: React.FC<Props> = ({ isOpen, toggleMenu, isVisible, 'data-testid': dataTestId }) => {
  const arrowIcon = isOpen ? (
    <ArrowLeftIcon className={styles.menuIcon} />
  ) : (
    <ArrowRightIcon className={styles.menuIcon} />
  );

  const isPortrait = isPortraitOrientation();
  const isIos = Capacitor.getPlatform() === 'ios';

  return isVisible ? (
    <Box
      data-testid={dataTestId}
      onClick={toggleMenu}
      className={cn(styles.logoBox, {
        [styles.isPortrait]: isPortrait,
        [styles.isAlbum]: !isPortrait,
      })}
    >
      <Box
        className={cn(styles.logo, {
          [styles.isAlbum]: !isPortrait,
          [styles.isIosAlbum]: !isPortrait && isIos,
        })}
      >
        <img alt="deltaration-logo" src={ImagePath.logoOutline} width={'100%'} height={'100%'} />
      </Box>
      <Box className={cn(styles.line, { [styles.isAlbum]: !isPortrait })} />
      {arrowIcon}
    </Box>
  ) : (
    <></>
  );
};
