import { Role } from '../card/cardTypes';
import React from 'react';
import { NumberOfPlayers, PlayerData } from './gameTypes';
import { FinishDefendingFunc, FinishDefendingType } from './gameContextHandler/hooks/useDefendingActions';
import { GameState, WithUpdatingState } from './gameContextHandler/hooks/useGameState';
import { Player } from '../player/player.types';
import { EnableDragAndDropFunc } from './gameContextHandler/hooks/useDragAndDrop';
import { AddTurnAction } from './gameContextHandler/hooks/useTurnActions';
import { RevealCurrentPlayerRoleBy } from './gameContextHandler/hooks/useRevealRole';
import { ChangeEssenceCountAction } from './gameContextHandler/hooks/useChangeEssenceCount';

export type GameContextType = {
  gameState: GameState;
  expectedPlayersCount: NumberOfPlayers | undefined;
  playerDataList: PlayerData[];
  isReadyToPlay: boolean;
  changePlayerEssenceCount: ChangeEssenceCountAction;
  finishTurn: (onRemovalFromGame: boolean) => void;
  revealCurrentPlayerRoleBy: RevealCurrentPlayerRoleBy;
  addTurnAction: AddTurnAction;
  performFinishDefending: (type: FinishDefendingType) => void;
  performFinishAndLoseEssence: (player: Player) => void;
  performFinishAndRevealRole: () => void;
  performFinishAndConvert: () => void;
  performFinishAndEnableTeleport: () => void;
  performFinishAndEnableDisintegrator: () => void;
  reflectAttackingCard: () => void;
  finishChallenge: () => void;
  forceFinishChallenge: () => void;
  startDisorientationCheck: () => void;
  finishDisorientationCheck: () => void;
  finishDomeCheck: () => void;
  rollQuantumDice: () => void;
  applyFoggerEffect: () => void;
  isDiceReRollAllowed: boolean;
  allowDiceReRoll: (playerId: string) => void;
  leaveDiceLog: () => void;
  isUiBlocked: boolean;
  isDraggable: (playerId: string) => boolean;
  isDroppable: (playerId: string) => boolean;
  isAllowedToDrag: (playerId: string) => boolean;
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
  getGameWinningRole: () => Role | undefined;
  gameDurationLog: string;
  withUpdatingState: WithUpdatingState;
  enableDragAndDrop: EnableDragAndDropFunc;
  finishDefending: FinishDefendingFunc;
  isDiceRolling: boolean;
  setIsDiceRolling: (state: boolean) => void;
  idlePlayer: string | undefined;
  setIdlePlayer: (player: string | undefined) => void;
};

export const GameContext = React.createContext({} as GameContextType);

export enum EssenceUpdateType {
  ADD_ONE = 1,
  REMOVE_ONE = -1,
}
