import React from 'react';
import styles from './cardMovementDrawer.module.css';
import { useWindowSize } from '../../../helpers/utils';
import { Location } from '../../../features/game/locationTrackerContextProvider';
import { ProjectColors } from '../../../themes/mainTheme';
import { SECOND_IN_MILLISECONDS } from '../../../constants/time';
import { DEFAULT_COLOR_MOVEMENT_TYPES, Props } from './cardMovementDrawer.types';

export const CardMovementDrawer: React.FC<Props> = ({ stops, cards, movementType }) => {
  const canvasRef = React.useRef<HTMLCanvasElement | null>(null);
  const [currentMove, setCurrentMove] = React.useState<{ from: Location; to: Location } | null>(null);
  const [moveKey, setMoveKey] = React.useState(0);
  const { width, height } = useWindowSize();

  const shadowColor = React.useMemo(() => {
    if (movementType !== undefined && DEFAULT_COLOR_MOVEMENT_TYPES.includes(movementType)) return ProjectColors.WHITE;
    if (cards.some((card) => card.isOpen && card.category === 'ATTACKING')) return ProjectColors.RED;
    if (cards.some((card) => card.isOpen && card.category === 'DEFENDING')) return ProjectColors.BLUE;
    return ProjectColors.WHITE;
  }, [cards]);

  const averageBoltDuration = 1.25;
  const boltsMovementTotalDuration = 7.5;
  const oneMovementDuration = boltsMovementTotalDuration / (stops.length - 1);
  const boltMovementDuration =
    oneMovementDuration <= averageBoltDuration
      ? oneMovementDuration
      : averageBoltDuration + (oneMovementDuration % averageBoltDuration);

  React.useEffect(() => {
    if (!canvasRef.current || stops.length < 2) return;

    const context = canvasRef.current.getContext('2d');
    if (!context) return;

    const drawLine = (from: Location, to: Location) => {
      context.beginPath();
      context.moveTo(from.x, from.y);
      context.lineTo(to.x, to.y);
      context.strokeStyle = `${shadowColor}`;
      context.lineWidth = 6;
      context.lineCap = 'round';
      context.lineJoin = 'round';
      context.filter = 'blur(0.5rem) contrast(40%)';
      context.shadowBlur = 4;
      context.shadowColor = `${shadowColor}`;
      context.stroke();
    };

    const clearCanvas = () => {
      if (!canvasRef.current) return;
      context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
    };

    const totalDurationInMs = 4 * SECOND_IN_MILLISECONDS;
    let totalAnimationTime = 0;

    const drawLinesSequentially = (index: number) => {
      if (index >= stops.length - 1) return;

      const from = stops[index];
      const to = stops[index + 1];

      setTimeout(() => drawLine(from, to), totalAnimationTime);
      totalAnimationTime += totalDurationInMs / stops.length;

      drawLinesSequentially(index + 1);
    };

    clearCanvas();
    drawLinesSequentially(0);
  }, [stops, height, cards]);

  React.useEffect(() => {
    if (stops.length < 2) return;

    let boltAnimationTimeout: NodeJS.Timeout;
    let boltAnimationInterval: NodeJS.Timeout;

    const startBoltAnimation = () => {
      let moveIndex = 0;

      const animateBolt = () => {
        if (moveIndex >= stops.length - 1) {
          moveIndex = 0;
        }

        const from = stops[moveIndex];
        const to = stops[moveIndex + 1] || stops[0];

        setCurrentMove({ from, to });
        setMoveKey((prev) => prev + 1);

        moveIndex += 1;
      };

      boltAnimationInterval = setInterval(() => {
        animateBolt();
      }, boltMovementDuration * SECOND_IN_MILLISECONDS);

      animateBolt();
    };

    boltAnimationTimeout = setTimeout(() => {
      startBoltAnimation();

      setTimeout(() => {
        clearInterval(boltAnimationInterval);
        setCurrentMove(null);
      }, boltsMovementTotalDuration * SECOND_IN_MILLISECONDS);
    }, 3 * SECOND_IN_MILLISECONDS);

    return () => {
      clearTimeout(boltAnimationTimeout);
      clearInterval(boltAnimationInterval);
    };
  }, [stops]);

  return (
    <div>
      <canvas ref={canvasRef} width={width} height={height} className={styles.fadeCanvas} />
      {currentMove && (
        <div
          key={`bolt-${moveKey}`}
          style={
            {
              '--initial-x': `${currentMove.from.x}px`,
              '--initial-y': `${currentMove.from.y}px`,
              '--final-x': `${currentMove.to.x}px`,
              '--final-y': `${currentMove.to.y}px`,
              '--movement-duration': `${boltMovementDuration}s`,
            } as React.CSSProperties
          }
          className={styles.bolt}
        >
          <div className={styles.energyCore} style={{ '--bolt-color': `${shadowColor}` } as React.CSSProperties} />
        </div>
      )}
    </div>
  );
};
