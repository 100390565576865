import {
  allApplyRules,
  ApplyRule,
  ApplyRuleDtoType,
  ApplyRuleGroup,
  Card,
  CardNameDto,
  CardNames,
  CardType,
  EnergyColorDto,
  EnergyColors,
  PlayingCard,
  PlayingCardDto,
  PlayingCardDtoType,
  PlayingCardType,
  ShellColor,
} from './cardTypes';
import { ImagePath } from '../../assets/assets';
import { fromTo } from '../../helpers/transformers';
import { haveSameElements } from '../../helpers/arrays';
import { GAME_MODES } from '../game/gameTypes';

const playingCardTypeFromDto: fromTo<PlayingCardDtoType, PlayingCardType> = {
  ARSENAL: PlayingCardType.ARSENAL,
  NEBULA: PlayingCardType.NEBULA,
  RADIANCE: PlayingCardType.RADIANCE,
  CAPSULE: PlayingCardType.CAPSULE,
};

const applyRuleFromDto: fromTo<ApplyRuleDtoType, ApplyRule> = {
  ME: ApplyRule.ME,
  OTHER_PLAYER: ApplyRule.OTHER_PLAYER,
  ANY_PLAYER: ApplyRule.ANY_PLAYER,
  ALL: ApplyRule.ALL,
  ALL_BUT_ME: ApplyRule.ALL_BUT_ME,
  GAUNTLET: ApplyRule.GAUNTLET,
  SHELL: ApplyRule.SHELL,
  BACKPACK: ApplyRule.BACKPACK,
  DOME: ApplyRule.DOME,
  ENERGY: ApplyRule.ENERGY,
};

export const ENERGY_COLORS_BY_SHELL_COLOR: fromTo<ShellColor, EnergyColors[]> = {
  RED_BLACK: [EnergyColors.RED, EnergyColors.BLACK],
  YELLOW_BLUE: [EnergyColors.YELLOW, EnergyColors.BLUE],
};

export const APPLY_RULES_BY_GROUP: fromTo<ApplyRuleGroup, ApplyRule[]> = {
  [ApplyRuleGroup.ALL]: allApplyRules,
  [ApplyRuleGroup.ARSENAL]: [ApplyRule.GAUNTLET, ApplyRule.SHELL, ApplyRule.BACKPACK, ApplyRule.DOME],
  [ApplyRuleGroup.PLAYING_AREA]: [ApplyRule.ME, ApplyRule.ALL_BUT_ME, ApplyRule.ALL, ApplyRule.ANY_PLAYER],
};

const energyColorFromDto: fromTo<EnergyColorDto, EnergyColors> = {
  YELLOW: EnergyColors.YELLOW,
  BLUE: EnergyColors.BLUE,
  RED: EnergyColors.RED,
  BLACK: EnergyColors.BLACK,
};

const cardNameFromDto: fromTo<CardNameDto, CardNames> = {
  GAUNTLET: CardNames.GAUNTLET,
  SHELL: CardNames.SHELL,
  BACKPACK: CardNames.BACKPACK,
  DOME: CardNames.DOME,
  TELEPORT: CardNames.TELEPORT,
  DE_ESSENCER: CardNames.DE_ESSENCER,
  BLACK_HOLE: CardNames.BLACK_HOLE,
  SPY: CardNames.SPY,
  MASS_DE_ESSENCER: CardNames.MASS_DE_ESSENCER,
  ESSENCE_THIEF: CardNames.ESSENCE_THIEF,
  CHALLENGE: CardNames.CHALLENGE,
  SPACE_DISTORTION: CardNames.SPACE_DISTORTION,
  DISINTEGRATOR: CardNames.DISINTEGRATOR,
  DISORIENTATION: CardNames.DISORIENTATION,
  SQUANDER: CardNames.SQUANDER,
  ESSENCE_SHIELD: CardNames.ESSENCE_SHIELD,
  EQUALIZER: CardNames.EQUALIZER,
  MASS_EQUALIZER: CardNames.MASS_EQUALIZER,
  UNCHAINED_ESSENCE: CardNames.UNCHAINED_ESSENCE,
  MASS_ESSENCE_ESCAPE: CardNames.MASS_ESSENCE_ESCAPE,
  FOGGER: CardNames.FOGGER,
  AMNESIA: CardNames.AMNESIA,
  ENERGY: CardNames.ENERGY,
  REVEALER: CardNames.REVEALER,
  CONVERSIONER: CardNames.CONVERSIONER,
};

export function cardNameToLog(cardName: CardNames): string {
  return cardName === CardNames.UNCHAINED_ESSENCE ? 'for' : 'against';
}

export const dtoToPlayCards = (dto: PlayingCardDto[]): PlayingCard[] => {
  const allCards: PlayingCard[] = [];

  dto.forEach((item) => {
    const cardName = cardNameFromDto[item.name];
    const energyColors = item.colors.map((colorDto) => energyColorFromDto[colorDto]);

    const cardWithoutId = {
      cardType: CardType.PLAYING_CARD,
      image: resolveCardImage(cardName, energyColors),
      details: item.onCardDescription.value,
      isOpen: false,
      playingCardType: playingCardTypeFromDto[item.type],
      energyColors: energyColors,
      applyRule: applyRuleFromDto[item.applyRule],
      category: item.category,
      name: cardName,
      allowedApplyTypes: [],
      gameModes: GAME_MODES.filter((mode) => item.gameModes.includes(mode.name.toUpperCase())).map((mode) =>
        mode.name.toLowerCase()
      ),
    };
    allCards.push(
      ...item.itemIds.map((itemId) => ({
        ...cardWithoutId,
        id: itemId,
      }))
    );
  });

  return allCards;
};

export const openCard = <T extends Card>(card: T): T => ({ ...card, isOpen: true });
export const openCards = <T extends Card>(cards: T[]): T[] => cards.map((card) => openCard(card));
export const closeCard = <T extends Card>(card: T): T => ({ ...card, isOpen: false });
export const closeCards = <T extends Card>(cards: T[]): T[] => cards.map((card) => closeCard(card));

export const resolveCardImage = (name: CardNames, colors?: EnergyColors[]): string => {
  const throwInvalidCard = () => {
    throw Error(`No image for card with name ${name} and colors '${colors}'`);
  };

  switch (name) {
    case CardNames.GAUNTLET:
      if (colors?.length !== 1) {
        return throwInvalidCard();
      }
      if (colors?.includes(EnergyColors.RED)) {
        return ImagePath.gauntletRed;
      }
      if (colors?.includes(EnergyColors.BLACK)) {
        return ImagePath.gauntletBlack;
      }
      if (colors?.includes(EnergyColors.YELLOW)) {
        return ImagePath.gauntletYellow;
      }
      if (colors?.includes(EnergyColors.BLUE)) {
        return ImagePath.gauntletBlue;
      }
      return throwInvalidCard();
    case CardNames.SHELL:
      if (colors !== undefined && haveSameElements(ENERGY_COLORS_BY_SHELL_COLOR['YELLOW_BLUE'], colors)) {
        return ImagePath.shellYellowBlue;
      }
      if (colors !== undefined && haveSameElements(ENERGY_COLORS_BY_SHELL_COLOR['RED_BLACK'], colors)) {
        return ImagePath.shellRedBlack;
      }
      return throwInvalidCard();
    case CardNames.BACKPACK:
      return ImagePath.backpack;
    case CardNames.DOME:
      return ImagePath.dome;
    case CardNames.TELEPORT:
      return ImagePath.teleport;
    case CardNames.DE_ESSENCER:
      return ImagePath.deEssencer;
    case CardNames.BLACK_HOLE:
      return ImagePath.blackHole;
    case CardNames.SPY:
      return ImagePath.spy;
    case CardNames.MASS_DE_ESSENCER:
      return ImagePath.massDeEssencer;
    case CardNames.ESSENCE_THIEF:
      return ImagePath.essenceThief;
    case CardNames.CHALLENGE:
      return ImagePath.challenge;
    case CardNames.SPACE_DISTORTION:
      return ImagePath.spaceDistortion;
    case CardNames.DISINTEGRATOR:
      return ImagePath.disintegrator;
    case CardNames.DISORIENTATION:
      return ImagePath.disorientation;
    case CardNames.SQUANDER:
      return ImagePath.squander;
    case CardNames.ESSENCE_SHIELD:
      return ImagePath.essenceShield;
    case CardNames.EQUALIZER:
      return ImagePath.equalizer;
    case CardNames.MASS_EQUALIZER:
      return ImagePath.massEqualizer;
    case CardNames.UNCHAINED_ESSENCE:
      return ImagePath.unchainedEssence;
    case CardNames.MASS_ESSENCE_ESCAPE:
      return ImagePath.massEssenceEscape;
    case CardNames.FOGGER:
      return ImagePath.fogger;
    case CardNames.AMNESIA:
      return ImagePath.amnesia;
    case CardNames.REVEALER:
      return ImagePath.revealer;
    case CardNames.CONVERSIONER:
      return ImagePath.conversioner;
    case CardNames.ENERGY:
      if (colors?.length !== 1) {
        return throwInvalidCard();
      }

      if (colors?.includes(EnergyColors.RED)) {
        return ImagePath.energyRed;
      }
      if (colors?.includes(EnergyColors.BLACK)) {
        return ImagePath.energyBlack;
      }
      if (colors?.includes(EnergyColors.YELLOW)) {
        return ImagePath.energyYellow;
      }
      if (colors?.includes(EnergyColors.BLUE)) {
        return ImagePath.energyBlue;
      }

      return throwInvalidCard();
  }
};
