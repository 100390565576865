import React from 'react';
import { UseAccessCodeLoginModal } from './useAccessCodeLogin.types';
import { AccessCodeLoginModal } from '../accessCodeLoginModal';
import { AccessCodeLoginButton } from '../accessCodeLoginButton';

export const useAccessCodeLogin: UseAccessCodeLoginModal = ({ iconOnlyButton } = { iconOnlyButton: false }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return {
    accessCodeLoginModal: <AccessCodeLoginModal isOpen={isModalOpen} onClose={closeModal} />,
    accessCodeLoginButton: <AccessCodeLoginButton openModal={openModal} iconOnly={iconOnlyButton} />,
  };
};
