import React from 'react';
import { UseFoggerEffect } from './useFoggerEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import { toAppliedAction, toImpactedAction } from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { LogContext } from '../../../../game/logContextProvider';

export const useFoggerEffect: UseFoggerEffect = () => {
  const { gameState, applyFoggerEffect, allowDiceReRoll } = React.useContext(GameContext);
  const { setNotification } = React.useContext(LogContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(gameState.turnActions.filter((action) => action.player === currentPlayer.id))?.action;

    if (lastTurnAction === undefined) return;

    if (lastTurnAction === toAppliedAction(CardNames.FOGGER)) {
      return applyFoggerEffect();
    }

    if (lastTurnAction === toImpactedAction(CardNames.FOGGER)) {
      void setNotification({ type: 'warning', text: 'You can re-roll the quantum dice' });
      return allowDiceReRoll(currentPlayer.id);
    }
  };
};
