import { GameState } from '../gameContextHandler/hooks/useGameState';
import { isEmpty as isEmptyArray } from '../../../helpers/arrays';

export const isEmpty = (gameState: GameState): boolean => {
  return (
    gameState.diceRollAllowedFor === '' &&
    !gameState.isFinished &&
    gameState.activePlayer === '' &&
    gameState.activeDefendingPlayer === '' &&
    gameState.previousDefendingPlayer === '' &&
    gameState.roundNumber === 1 &&
    isEmptyArray(gameState.turnActions) &&
    isEmptyArray(gameState.previousTurnActions) &&
    isEmptyArray(gameState.cardsInDiscardPile) &&
    isEmptyArray(gameState.cardsInPlayingArea) &&
    isEmptyArray(gameState.defendingPlayerIds) &&
    isEmptyArray(gameState.allPlayers.flatMap((player) => player.cardsInHand).filter((card) => card.isOpen))
  );
};
