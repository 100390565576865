import React from 'react';
import { isEmpty, last } from '../../../../../helpers/arrays';
import { cardsAndActionTypesToActions, ignoreProvidedActionTypes } from '../../../../game/utils/turnActions';
import { AnimationContext } from '../../../../game/animationContextProvider';
import { SECOND_IN_MILLISECONDS } from '../../../../../constants/time';
import { IMPACTED, TurnAction } from '../../../../game/gameTypes';
import { CardNames } from '../../../../card/cardTypes';
import { UseAnimateDiscardCards } from './useAnimateDiscardCards.types';
import { useMovingCardsPropsTransformers } from '../../allPlayers/useMovingCardsPropsTransformers';

export const useAnimateDiscardCards: UseAnimateDiscardCards = ({ gameState }) => {
  const { queueMovingCardsProps } = React.useContext(AnimationContext);

  const { lostCardsCurrentPlayerActionToMovingCards } = useMovingCardsPropsTransformers({ gameState });

  const canAnimate = (currentPlayerId: string, lastTurnAction: TurnAction) => {
    const impactedActions = cardsAndActionTypesToActions([CardNames.DISORIENTATION, CardNames.SQUANDER], [IMPACTED]);
    return (
      ['lost cards upon removal', ...impactedActions].includes(lastTurnAction.action) &&
      currentPlayerId === lastTurnAction.appliedTo
    );
  };

  const animate = (currentPlayerId: string, turnActions: TurnAction[]) => {
    const filteredTurnActions = ignoreProvidedActionTypes(turnActions, ['quantum dice rolled', 'create log']);
    if (isEmpty(filteredTurnActions)) return;

    const turnAction = last(filteredTurnActions);
    if (!turnAction || !canAnimate(currentPlayerId, turnAction)) return;

    const movingCardsPropsList = lostCardsCurrentPlayerActionToMovingCards(turnAction, currentPlayerId);
    if (isEmpty(movingCardsPropsList)) return;

    //TODO remove setTimeout after animation is not effected by re-renders
    setTimeout(() => queueMovingCardsProps(movingCardsPropsList), SECOND_IN_MILLISECONDS * 0.5);
  };

  return {
    animate: animate,
  };
};
