import { TurnAction } from '../../../../game/gameTypes';
import { Player } from '../../../../player/player.types';
import { getPlayer, getPlayerAppliedDome } from '../../../../game/gameContextHandler/utils/player';
import React from 'react';
import { LocationTrackerContext } from '../../../../game/locationTrackerContextProvider';
import { AnimationContext } from '../../../../game/animationContextProvider';
import { roomItems } from '../../../../room/hooks/useTrackLocation';
import { ignoreProvidedActionTypes, toImpactedAction } from '../../../../game/utils/turnActions';
import { CardNames } from '../../../../card/cardTypes';
import { PASSING_DICE_VALUES } from '../../../../game/gameContextHandler';
import { penultimate } from '../../../../../helpers/arrays';
import { UseAnimateDome } from './useAnimateDome.types';

export const useAnimateDome: UseAnimateDome = () => {
  const { getItemLocation } = React.useContext(LocationTrackerContext);
  const { queuePulsatingCardProps } = React.useContext(AnimationContext);

  const canAnimate = (turnActions: TurnAction[]) => {
    const penultimateAction = penultimate(ignoreProvidedActionTypes(turnActions, ['create log']));

    return (
      penultimateAction &&
      penultimateAction.action === toImpactedAction(CardNames.DOME) &&
      penultimateAction.diceValue !== undefined &&
      PASSING_DICE_VALUES.includes(penultimateAction.diceValue)
    );
  };

  const animate = (allPlayers: Player[], turnActions: TurnAction[]) => {
    if (!canAnimate(turnActions)) return;

    const player = getPlayer(allPlayers, penultimate(turnActions)!.player);
    const dome = getPlayerAppliedDome(player);
    if (!dome) return;

    const playerArsenalLocation = getItemLocation(roomItems.playerArsenal(player.id));
    if (!playerArsenalLocation) return;

    const domeLocation = getItemLocation(roomItems.card(dome.id));
    queuePulsatingCardProps({ card: dome, cardLocation: domeLocation, displayLocation: playerArsenalLocation });
  };

  return {
    animate: animate,
  };
};
