import React from 'react';
import { ApiGameState, GameState, UseGameState, UseGameStateResult, WithUpdatingState } from './useGameState.types';
import {
  apiGameStateToGameState,
  gameStateApiToGameStateDataString,
  gameStateApiDataToApiGameState,
  gameStateToApiGameState,
} from '../../../gameTransformers';
import { GAME_TOKEN_STORAGE_KEY } from '../../../../../constants/delarationApi';
import { useGameId } from '../useGameId';
import { DEFAULT_DRAW_CARD_AMOUNT, StartGameRequest, GameStateRequest, PlayerData } from '../../../gameTypes';
import { isEmpty, sortByStartingElement } from '../../../../../helpers/arrays';
import { isEmpty as isEmptyState } from '../../../utils/gameState';
import { validateCount, validateGameStateCards } from '../../../gameValidators';
import { createApiAuthRequest } from '../../utils/api';
import { getCurrentPlayer } from '../../utils/player';
import { useSocketClient } from '../useSocketClient/useSocketClient';
import { AnyFunction, getHttpStatusFromErr, logResponseErr, withFunc } from '../../../../../helpers/utils';
import { AppContext } from '../../../../../global/context/appContext';
import { ApiContext } from '../../../../../global/app/api/apiContext';
import { useApplyCardRules } from '../useApplyCardRules';
import { useApplyTurnActions } from './useApplyTurnActions';
import { LogContext } from '../../../logContextProvider';
import { useGameApi } from '../../../gameApi/useGameApi';

export const useGameState: UseGameState = ({
  allPlayingCards,
  playersAdded,
  isReadyToPlay,
  setExpectedPlayersCount,
  setPlayersDataList,
  setPlayersAdded,
}): UseGameStateResult => {
  const [apiGameState, setApiGameState] = React.useState<ApiGameState>();
  const id = useGameId();
  const { profile } = React.useContext(AppContext);
  const { gameApi } = React.useContext(ApiContext);
  const { createRealtimeLog } = React.useContext(LogContext);
  const { startGame: gameApiStartGame, invalidateGameCache, game } = useGameApi();

  const createDefaultGameState = (): GameState => {
    return {
      isFinished: false,
      roundNumber: 1,
      drawCardAmount: DEFAULT_DRAW_CARD_AMOUNT,
      allPlayers: [],
      playersOrdered: [],
      turnActions: [],
      previousTurnActions: [],
      activePlayer: '',
      activeDefendingPlayer: '',
      previousDefendingPlayer: '',
      defendingPlayerIds: [],
      cardsInDeck: [],
      cardsInDiscardPile: [],
      cardsInPlayingArea: [],
      droppableList: [],
      diceRollAllowedFor: '',
      createdAt: null,
    };
  };

  const [gameState, setGameState] = React.useState<GameState>(createDefaultGameState());
  const [gameStateHash, setGameStateHash] = React.useState<string>();
  const [isUiBlocked, setIsUiBlocked] = React.useState(false);
  const gameToken = localStorage.getItem(GAME_TOKEN_STORAGE_KEY);

  const [gameStateQueue, setGameStateQueue] = React.useState<GameState[]>([]);
  const addStateToQueue = (state: GameState) => setGameStateQueue((prevQueue) => [...prevQueue, state]);
  const [isUpdateStateAllowed, setIsUpdateStateAllowed] = React.useState(true);

  const { emitGameStateUpdated } = useSocketClient({
    setPlayersAdded: setPlayersAdded,
    setGameStateHash: setGameStateHash,
    setApiGameState: setApiGameState,
    invalidateGameCache: invalidateGameCache,
  });

  const { fillHandCardsAllowedApplyTypes } = useApplyCardRules();
  const { applyGameStateTurnActions } = useApplyTurnActions({ gameState });

  const sortPlayers = (dataList: PlayerData[]): PlayerData[] => {
    const sortedPlayers = sortByStartingElement(dataList, (player) => player.id === profile!.playerId);

    if (sortedPlayers === undefined) {
      return dataList;
    }

    return sortedPlayers;
  };

  const initApiGameData = async () => {
    if (!game) return;
    setExpectedPlayersCount(game.numberOfPlayers);
    const retrievedList = game.players.map((playerData) => ({
      id: playerData.id,
      name: playerData.name,
    }));
    setPlayersDataList(sortPlayers(retrievedList));
  };

  const addApiStateToQueue = () => {
    if (apiGameState === undefined || game === undefined) {
      return;
    }

    const newGameState = apiGameStateToGameState(apiGameState, allPlayingCards, game, profile!.playerId);
    addStateToQueue(newGameState);
  };

  const applyGameState = (rawGameState: GameState) => {
    fillHandCardsAllowedApplyTypes(rawGameState);

    if (isEmptyState(gameState)) return setGameStateWithAllowedUpdate(rawGameState);

    const runAfter = () => setGameStateWithAllowedUpdate(rawGameState);
    applyGameStateTurnActions(rawGameState, runAfter);
  };

  const setGameStateWithAllowedUpdate = (newGameState: GameState) => {
    setGameState(newGameState);
    setIsUpdateStateAllowed(true);
  };

  React.useEffect(() => {
    addApiStateToQueue();
  }, [apiGameState]);

  React.useEffect(() => {
    if (!isUpdateStateAllowed || isEmpty(gameStateQueue)) return;
    setIsUpdateStateAllowed(false);
    applyGameState(gameStateQueue[0]);
    setGameStateQueue((prevQueue) => prevQueue.slice(1));
  }, [gameStateQueue.length, isUpdateStateAllowed, game]);

  React.useEffect(() => {
    if (!profile || !game) return;
    void initApiGameData();
  }, [playersAdded, profile, id, game]);

  const createGameState = async () => {
    if (game === undefined || gameToken === null) {
      throw Error('Cannot create GameState');
    }

    const gameStateData = gameStateToApiGameState(gameState, allPlayingCards);
    validateGameStateCards(gameStateData);

    const gameStateRequest: GameStateRequest = {
      data: gameStateApiToGameStateDataString(gameStateData),
      previousDataHash: gameStateHash === undefined ? '' : gameStateHash,
    };

    try {
      setIsUiBlocked(true);
      const gameStateApiData = await gameApi.createGameState(game.uniqueId, createApiAuthRequest(), gameStateRequest);
      setIsUiBlocked(false);

      setGameStateHash(gameStateApiData.dataHash);
      setApiGameState(gameStateApiDataToApiGameState(gameStateApiData));

      emitGameStateUpdated(gameStateApiData);
    } catch (e: any) {
      logResponseErr(e);
      window.location.reload();
    }
  };

  const startGame = async () => {
    const gameStartRequest: StartGameRequest = {
      startedAt: new Date(),
      players: gameState.playersOrdered,
    };

    gameApiStartGame({ gameId: id, authRequest: createApiAuthRequest(), request: gameStartRequest });
  };

  //TODO to find out when updated more than once, temporary check
  const checkUpdateCount = validateCount(1, 'gameState update');
  const updateGameState = () => {
    checkUpdateCount();
    void createGameState();
  };

  const getCurrentGameState = async () => {
    try {
      const gameStateApiData = await gameApi.getCurrentGameState(id, createApiAuthRequest());

      if (gameStateHash === gameStateApiData.dataHash) return;

      setGameStateHash(gameStateApiData.dataHash);
      setApiGameState(gameStateApiDataToApiGameState(gameStateApiData));
    } catch (e: any) {
      if (getHttpStatusFromErr(e) === 404 && e.response.data.message === 'GameState not found') {
        void createInitialGameState();
        return;
      }

      logResponseErr(e);
    }
  };

  const createInitialGameState = async () => {
    if (
      !isReadyToPlay ||
      game === undefined ||
      game.creator.id !== profile!.playerId ||
      gameToken === null ||
      apiGameState !== undefined
    ) {
      return;
    }

    const currentPlayer = getCurrentPlayer(gameState);
    gameState.playersOrdered = gameState.allPlayers.map((player) => player.id);
    gameState.activePlayer = currentPlayer.id;

    void startGame();
    void createGameState();
    createRealtimeLog({ type: 'info', text: `${currentPlayer.playerName}: Deltaration started` });
  };

  const withUpdatingState: WithUpdatingState = <T extends AnyFunction>(fn: T): T => withFunc(fn, updateGameState) as T;

  return {
    gameState,
    apiGameState,
    isUiBlocked,
    withUpdatingState,
    getCurrentGameState,
  };
};
