import React from 'react';
import { Positioned, Props } from './widgetsPanel.types';
import { Box } from '@mui/material';
import { WithCssClass } from '../../../helpers/types';
import styles from './widgetsPanel.module.css';
import cn from 'classnames';

export const WidgetsPanel: React.FC<Props & Positioned & WithCssClass> = ({ widgets, position, className }) => {
  const defaultClassNames = cn(styles.widgets, {
    [styles.isPositionedLeft]: position === 'left',
    [styles.isPositionedRight]: position === 'right',
  });

  return (
    <React.Fragment>
      <Box className={className ?? defaultClassNames}>{widgets ?? []}</Box>
    </React.Fragment>
  );
};
