import { CardNames, EnergyColors, PlayingCard } from '../card/cardTypes';
import { DiceValue, PlayerCardGroup } from './gameContextHandler';
import { Log } from './logContextProvider';

export enum NumberOfPlayers {
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
}

export type GameMode = {
  name: string;
  description: string;
};

export const SIMPLE_MODE: GameMode = {
  name: 'Simple',
  description: 'Includes only essential card types, perfect for beginners',
};

export const CLASSIC_MODE: GameMode = {
  name: 'Classic',
  description: 'Features primary card types, ideal for experienced players',
};

export const EXTENDED_MODE: GameMode = {
  name: 'Extended',
  description: 'Contains extensive card types, best for advanced players',
};

export const GAME_MODES: GameMode[] = [SIMPLE_MODE, CLASSIC_MODE];

export type AccessMode = {
  name: string;
  description: string;
};

export const PRIVATE_ACCESS_MODE: GameMode = {
  name: 'Private',
  description: 'Share the Game link with players you want to play with',
};

export const PUBLIC_ACCESS_MODE: GameMode = {
  name: 'Public',
  description: 'Game is available for anyone to join from Playing Now section',
};

export type CreateGameData = {
  numberOfPlayers: number;
  gameMode: string;
  accessMode: string;
};

export type JoinGameData = {
  gameId: string;
};

export type CreatedGameData = {
  gameToken: string;
  numberOfPlayers: number;
  uniqueId: string;
};

export type JoinedGameData = {
  gameToken: string;
};

export type PlayerData = {
  id: string;
  name: string;
};

export type ApiAuthRequest = {
  'game-token': string;
};

export type GameStateRequest = {
  data: string;
  previousDataHash: string;
};

export type StartGameRequest = {
  startedAt: Date;
  players: string[];
};

export type StartGameRequestApiData = {
  gameId: string;
  authRequest: ApiAuthRequest;
  request: StartGameRequest;
};

export type PlayerState = {
  id: string;
  role: string;
  isWinner: boolean;
};

export type FinishGameRequest = {
  finishedAt: Date;
  playerStates: PlayerState[];
};

export type FinishGameRequestApiData = {
  gameId: string;
  authRequest: ApiAuthRequest;
  request: FinishGameRequest;
};

export type GameStateApiData = {
  gameId: string;
  data: string;
  dataHash: string;
  createdAt?: string;
};

export type CommandExecutionApiData = {
  status: string;
};

export type GameLogRequest = Log;

export type CardsApiGameStateData = {
  allPlayingCards: string[];
  cardsInDeck: string[];
  cardsInDiscardPile: string[];
  cardsInPlayingArea: string[];
};

//TODO should be probably moved in the future to some useCards.types
export type CardsGameStateData = {
  cardsInDeck: PlayingCard[];
  cardsInDiscardPile: PlayingCard[];
  cardsInPlayingArea: PlayingCard[];
};

export type TurnAction = {
  player: string;
  action: Action;
  cardIds?: string[];
  appliedTo?: string;
  groupedCardIds?: [PlayerCardGroup, string[]][];
  diceValue?: DiceValue;
  logs?: Log[];
};

export type TurnActionTimeStamped = TurnAction & {
  timestamp: number;
};

export const DEFAULT_DRAW_CARD_AMOUNT = 2;

export const APPLIED = 'applied';
export const IMPACTED = 'impacted';
export const IN_PROGRESS = 'in progress';
export const REARRANGED = 'rearranged';
export const STARTED = 'started';
export const CANCELED = 'canceled';
export const FINISHED = 'finished';

export type TurnActionType = typeof STARTED | typeof FINISHED | typeof CANCELED;

export type CardActionType =
  | typeof APPLIED
  | typeof IMPACTED
  | typeof IN_PROGRESS
  | typeof REARRANGED
  | typeof CANCELED;

export type Action =
  | 'defendingFinished'
  | 'drawCard'
  | `quantum dice rolled`
  | `essence hunt ${TurnActionType}`
  | `${typeof APPLIED} ${CardNames.ENERGY} with source ${EnergyColors}`
  | `${CardNames} ${CardActionType}`
  | `cardsDiscarded`
  | `cardsDiscardedFromEngagementZone`
  | `disintegrated from ${PlayerCardGroup}`
  | `teleported from ${PlayerCardGroup}`
  | 'create log'
  | `lost cards upon removal`;

export type StateOfGame = 'pending' | 'active' | 'finished';
