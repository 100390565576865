import React from 'react';
import { CardContent, CustomDisplayType, Props } from './card.types';
import { Card as MuiCard } from '@mui/material';
import { SizeExtended } from '../../helpers/sizes';
import { initSx } from './card.styles';
import { RuleBookWidget } from '../../features/room/ruleBookWidget';
import { useCardSelection } from './hooks/useCardSelection';
import { AppContext } from '../../global/context/appContext';
import { getActiveActionButtonSx, SxStyles } from '../../helpers/styles';
import { useCardDimension } from './hooks/useCardDimension';
import { DisplayMode } from '../../features/player/player.types';
import { ProjectColors } from '../../themes/mainTheme';
import { GameContext } from '../../features/game/gameContext';
import { ImagePath } from '../../assets/assets';
import styles from './card.module.css';
import cn from 'classnames';
import { isMobile } from 'react-device-detect';

type BackCoverProps = {
  backCoverImagePath: string;
};

const BackCover: React.FC<BackCoverProps> = ({ backCoverImagePath }) => (
  <img alt="card-back-cover" src={backCoverImagePath} className={styles.backCoverImage} />
);

type LargeCardProps = {
  content: CardContent;
  imagePath: string;
  hideRuleBookWidget?: boolean;
  sx: SxStyles;
};

const LargeCard: React.FC<LargeCardProps> = ({ content, imagePath, hideRuleBookWidget, sx }) => (
  <div className={cn(styles.cardContainer, styles.largeCard, styles[content.type], { [styles.isMobile]: isMobile })}>
    <div className={styles.cardBackground}>
      <div className={styles.backgroundTexture}>
        <img src={ImagePath.cardTexture} className={styles.backgroundTextureImage} alt="texture" />
      </div>
      <div>
        <img src={ImagePath.cardFrameLarge} className={styles.backgroundFrame} alt="frame" />
      </div>
    </div>
    <div className={styles.largeTopBar}>
      <div className={styles.largeTopBarTextContainer}>
        <p className={styles.largeTopBarText}>{content.type}</p>
      </div>
      <div className={styles.largeTopBarBackground}>
        <div className={styles.gradientContainer}>
          <div className={styles.gradientContainerBackground}></div>
          <div className={styles.gradientContainerBase}></div>
        </div>
        <div className={styles.gradientOverlay}></div>
      </div>
      {!hideRuleBookWidget && (
        <div className={styles.ruleBookWidget}>
          <RuleBookWidget defaultSearch={content.name} iconSize={sx.ruleBookSize as string} />
        </div>
      )}
    </div>
    <div className={styles.openLargeCardContainer}>
      <img src={imagePath} className={styles.openLargeCardPicture} alt={`card-${content.name}`} />
    </div>
    <div className={styles.largeBottomBar}>
      <div className={styles.largeBottomBarBackground}>
        <div className={styles.gradientContainer}>
          <div className={styles.gradientContainerBackground}></div>
          <div className={styles.gradientContainerBase}></div>
        </div>
        <div className={styles.gradientOverlay}></div>
      </div>
      <div className={styles.largeBottomBarTextContainer}>
        <p className={styles.bottomBarCardNameText}>{content.name}</p>
        <p className={styles.bottomBarCardDetailsText}>{content.details}</p>
      </div>
    </div>
  </div>
);

type SmallCardProps = {
  content: CardContent;
  imagePath: string;
  customDisplayType?: CustomDisplayType;
  isActive?: boolean;
  isCardSelected: boolean;
  handleCardClick: (event: React.MouseEvent) => void;
};

const SmallCard: React.FC<SmallCardProps> = ({
  content,
  imagePath,
  customDisplayType,
  isActive,
  isCardSelected,
  handleCardClick,
}) => (
  <div
    className={cn(styles.cardContainer, styles.smallCard, styles[content.type], { [styles.isMobile]: isMobile })}
    onClick={(event) => handleCardClick(event)}
  >
    <div
      className={cn(styles.cardBackground, {
        [styles.isInactive]: !isActive,
      })}
    >
      <div className={styles.backgroundTexture}>
        <img src={ImagePath.cardTexture} className={styles.backgroundTextureImage} alt="texture" />
      </div>
      <div>
        <img src={ImagePath.cardFrameSmall} className={styles.backgroundFrame} alt="frame" />
      </div>
    </div>
    <div
      className={cn(styles.openSmallCardContainer, {
        [styles.isInactive]: !isActive,
        [styles.cardContainerShowFullSizeImage]: customDisplayType === 'showFullSizeImageAndName',
      })}
    >
      <img
        src={imagePath}
        className={cn(styles.openSmallCardPicture, {
          [styles.cardPictureShowFullSizeImage]: customDisplayType === 'showFullSizeImageAndName',
        })}
        alt={`card-${content.name}`}
      />
    </div>
    <div
      className={cn(styles.smallBottomBar, {
        [styles.isSelected]: isCardSelected,
      })}
    >
      <div
        className={cn(styles.smallBottomBarBackground, {
          [styles.isInactive]: !isActive,
        })}
      >
        <div className={styles.gradientContainer}>
          <div className={styles.gradientContainerBackground}></div>
          <div className={styles.gradientContainerBase}></div>
        </div>
        <div className={styles.gradientOverlay}></div>
      </div>
      <div className={styles.smallBottomBarTextContainer}>
        <p className={styles.bottomBarCardNameText}>{content.name}</p>
      </div>
    </div>
  </div>
);

type SmallCardImageOnlyProps = {
  size: SizeExtended;
  content: CardContent;
  imagePath: string;
  isActive?: boolean;
  handleCardClick: (event: React.MouseEvent) => void;
};

const SmallCardImageOnly: React.FC<SmallCardImageOnlyProps> = ({
  size,
  content,
  imagePath,
  isActive,
  handleCardClick,
}) => (
  <div
    className={cn(
      styles.cardContainer,
      styles[content.type],
      {
        [styles.tinyCard]: size === SizeExtended.XXS,
        [styles.isMobile]: isMobile,
      },
      styles.smallCard
    )}
    onClick={(event) => handleCardClick(event)}
  >
    <div
      className={cn(styles.cardBackground, {
        [styles.isInactive]: !isActive,
      })}
    >
      <div className={styles.backgroundTexture}>
        <img src={ImagePath.cardTexture} className={styles.backgroundTextureImage} alt="texture" />
      </div>
      <div>
        <img src={ImagePath.cardFrameSmall} className={styles.backgroundFrame} alt="frame" />
      </div>
    </div>
    <div
      className={cn(styles.openSmallCardContainer, styles.cardContainerShowFullSizeImage, {
        [styles.isInactive]: !isActive,
      })}
    >
      <img
        src={imagePath}
        className={cn(styles.openSmallCardPicture, styles.cardPictureShowFullSizeImage)}
        alt={`card-${content.name}`}
      />
    </div>
  </div>
);

export const Card: React.FC<Props> = ({
  isOpen,
  size,
  content,
  imagePath,
  backCoverImagePath,
  backgroundColor,
  rotation,
  dragProps,
  selectorProps,
  ignoreWindowSize = false,
  hideRuleBookWidget = false,
  isActive = true,
  hasActiveAnimation = false,
  customDisplayType = undefined,
  customStyles,
}) => {
  const { screenSizeModifier } = React.useContext(AppContext);
  const { isDragging } = React.useContext(GameContext);
  const [isHovered, setIsHovered] = React.useState(false);
  const { dimension } = useCardDimension({ size: size });
  const sx = initSx(size, dimension, ignoreWindowSize ? 1 : screenSizeModifier, backgroundColor, rotation, isActive);
  const { isCardSelected, handleCardClick } = useCardSelection({ dragProps, selectorProps });

  const getCardContent = () => {
    if (!isOpen) return <BackCover backCoverImagePath={backCoverImagePath} />;

    if (size === SizeExtended.L) {
      return (
        <LargeCard
          content={content}
          imagePath={imagePath}
          hideRuleBookWidget={hideRuleBookWidget}
          sx={sx as SxStyles}
        />
      );
    }

    if (customDisplayType === 'showImageOnly') {
      return (
        <SmallCardImageOnly
          size={size}
          content={content}
          imagePath={imagePath}
          isActive={isActive}
          handleCardClick={handleCardClick}
        />
      );
    }

    return (
      <SmallCard
        content={content}
        imagePath={imagePath}
        customDisplayType={customDisplayType}
        isActive={isActive}
        isCardSelected={isCardSelected}
        handleCardClick={handleCardClick}
      />
    );
  };

  return (
    <MuiCard
      sx={{
        ...(hasActiveAnimation && !isDragging && !isHovered
          ? getActiveActionButtonSx(5, DisplayMode.MOBILE, ProjectColors.PURPLE)
          : {}),
        ...sx.card,
        ...customStyles,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={styles.cardOuterContainer}>{getCardContent()}</div>
    </MuiCard>
  );
};
