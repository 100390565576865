import { UseQuantumDice } from './useQuantumDice.types';
import { DiceValue, SQUANDER_DICE_MODIFIER } from '../../gameContextHandler.types';
import { getCurrentPlayer } from '../../utils/player';
import { isEmpty, removeElement } from '../../../../../helpers/arrays';
import { CardNames, PlayingCard } from '../../../../card/cardTypes';
import { getRandomValue } from '../../../../../helpers/diceValues';
import React from 'react';
import { toImpactedAction } from '../../../utils/turnActions';
import { Log, LogContext } from '../../../logContextProvider';

export const useQuantumDice: UseQuantumDice = ({ gameState, addTurnAction }) => {
  const { createLog, fetchLogs } = React.useContext(LogContext);

  const [diceValue, setDiceValue] = React.useState<DiceValue>();
  const [isDiceReRollEnabled, setIsDiceReRollEnabled] = React.useState(false);
  const [isDiceLogRequired, setIsDiceLogRequired] = React.useState(false);
  const [isDiceRolling, setIsDiceRolling] = React.useState(false);

  const currentPlayer = getCurrentPlayer(gameState);

  const updateDiceValue = (value: DiceValue) => {
    let finalDiceValue = value;

    const squanderCard = findSquanderCard();
    if (squanderCard) {
      finalDiceValue = applySquanderEffect(value, squanderCard);
    }

    gameState.diceRollAllowedFor = '';
    addTurnAction({ player: currentPlayer.id, action: 'quantum dice rolled', diceValue: finalDiceValue });
    setIsDiceLogRequired(true);
    setDiceValue(finalDiceValue);
  };

  const findSquanderCard = (): PlayingCard | undefined => {
    if (isEmpty(currentPlayer.activeRadiances)) return undefined;

    const squanderCards = currentPlayer.activeRadiances.filter((card) => card.name === CardNames.SQUANDER);
    return isEmpty(squanderCards) ? undefined : squanderCards[0];
  };

  const applySquanderEffect = (initialVal: DiceValue, squanderCard: PlayingCard): DiceValue => {
    removeElement(currentPlayer.activeRadiances, (card) => card.id === squanderCard.id);
    gameState.cardsInDiscardPile.push(squanderCard);

    const log: Log = {
      type: 'warning',
      text: `${[currentPlayer.playerName]}: quantum dice result will be modified by Squander`,
    };
    addTurnAction({
      player: currentPlayer.id,
      action: toImpactedAction(squanderCard.name),
      appliedTo: currentPlayer.id,
      groupedCardIds: [['activeRadiances', [squanderCard.id]]],
      logs: [log],
    });
    void createLog(log);

    return initialVal - SQUANDER_DICE_MODIFIER >= 0 ? ((initialVal - SQUANDER_DICE_MODIFIER) as DiceValue) : 0;
  };

  const enableDiceRollForPlayerId = (playerId: string) => {
    gameState.diceRollAllowedFor = playerId;
  };

  const rollQuantumDice = () => {
    updateDiceValue(getRandomValue());
    enableDiceRollForPlayerId('');
    setIsDiceReRollEnabled(false);
  };

  const isDiceReRollAllowed = currentPlayer && isDiceReRollEnabled && gameState.diceRollAllowedFor === currentPlayer.id;
  const allowDiceReRoll = (playerId: string) => {
    enableDiceRollForPlayerId(playerId);
    setIsDiceReRollEnabled(true);
  };

  const leaveDiceLog = () => {
    if (diceValue === undefined || !isDiceLogRequired) {
      void fetchLogs();
      return;
    }

    setIsDiceLogRequired(false);
    setDiceValue(undefined);

    void createLog({ type: 'info', text: `${[currentPlayer.playerName]}: quantum dice result ${diceValue}` });
  };

  return {
    isDiceReRollAllowed: isDiceReRollAllowed,
    allowDiceReRoll: allowDiceReRoll,
    rollQuantumDice: rollQuantumDice,
    leaveDiceLog: leaveDiceLog,
    isDiceRolling: isDiceRolling,
    setIsDiceRolling: setIsDiceRolling,
  };
};
