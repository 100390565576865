import { PlayingCard } from '../features/card/cardTypes';
import { convertToCapitalCase } from './utils';

export const handLabel = (cardsCount: number, allowedCardsCount: number, isShort: boolean = false) => {
  const shortLabel = `${cardsCount}/${allowedCardsCount}`;
  if (isShort) return shortLabel;

  return `Hand (${shortLabel})`;
};

export const cardLabel = (card: PlayingCard) => {
  if (card.energyColors.length === 0) return card.name;

  return convertToCapitalCase(card.energyColors.join('/')) + ' ' + card.name;
};
