import { ImagePath } from '../assets/assets';
import {
  ApplyRule,
  CardNames,
  CardType,
  EnergyColors,
  PlayingCard,
  PlayingCardType,
  Role,
  RoleCard,
} from '../features/card/cardTypes';
import { ulid } from 'ulid';
import { Player } from '../features/player/player.types';

export const TEST_PLAYING_CARDS: PlayingCard[] = [
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.ARSENAL,
    applyRule: ApplyRule.ME,
    category: 'OTHER',
    energyColors: [EnergyColors.BLUE],
    name: CardNames.GAUNTLET,
    details: 'Create blue nebulas',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.ARSENAL,
    applyRule: ApplyRule.ME,
    category: 'OTHER',
    energyColors: [EnergyColors.BLUE],
    name: CardNames.GAUNTLET,
    details: 'Create blue nebulas',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.ARSENAL,
    applyRule: ApplyRule.ME,
    category: 'OTHER',
    energyColors: [EnergyColors.BLUE],
    name: CardNames.GAUNTLET,
    details: 'Create blue nebulas',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.NEBULA,
    applyRule: ApplyRule.ME,
    category: 'ATTACKING',
    energyColors: [],
    name: CardNames.CHALLENGE,
    details: 'Challenge with quantum dice roll',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.NEBULA,
    applyRule: ApplyRule.ME,
    category: 'ATTACKING',
    energyColors: [],
    name: CardNames.CHALLENGE,
    details: 'Challenge with quantum dice roll',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.NEBULA,
    applyRule: ApplyRule.OTHER_PLAYER,
    category: 'ATTACKING',
    energyColors: [],
    name: CardNames.CHALLENGE,
    details: 'Challenge with quantum dice roll',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.CAPSULE,
    applyRule: ApplyRule.ALL_BUT_ME,
    category: 'OTHER',
    energyColors: [],
    name: CardNames.MASS_ESSENCE_ESCAPE,
    details: 'Destroy essence from all but me',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.CAPSULE,
    applyRule: ApplyRule.ALL,
    category: 'OTHER',
    energyColors: [],
    name: CardNames.MASS_ESSENCE_ESCAPE,
    details: 'Destroy essence from all but me',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.CAPSULE,
    applyRule: ApplyRule.ME,
    category: 'OTHER',
    energyColors: [],
    name: CardNames.MASS_ESSENCE_ESCAPE,
    details: 'Destroy essence from all but me',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.RADIANCE,
    applyRule: ApplyRule.OTHER_PLAYER,
    category: 'ATTACKING',
    energyColors: [],
    name: CardNames.DISORIENTATION,
    details: 'Skip turn chance',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.RADIANCE,
    applyRule: ApplyRule.OTHER_PLAYER,
    category: 'ATTACKING',
    energyColors: [],
    name: CardNames.DISORIENTATION,
    details: 'Skip turn chance',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.RADIANCE,
    applyRule: ApplyRule.OTHER_PLAYER,
    category: 'ATTACKING',
    energyColors: [],
    name: CardNames.DISORIENTATION,
    details: 'Skip turn chance',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
];

export const TEST_PLAYING_CARDS_CLOSED = TEST_PLAYING_CARDS.map((card) => ({ ...card, isOpen: false }));

export const TEST_RADIANCES: PlayingCard[] = [
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.RADIANCE,
    applyRule: ApplyRule.OTHER_PLAYER,
    category: 'ATTACKING',
    energyColors: [],
    name: CardNames.DISORIENTATION,
    details: 'Skip turn chance',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.RADIANCE,
    applyRule: ApplyRule.OTHER_PLAYER,
    category: 'ATTACKING',
    energyColors: [],
    name: CardNames.SQUANDER,
    details: '-3 from dice result',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
];

export const TEST_ARSENAL_CARDS: PlayingCard[] = [
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.ARSENAL,
    applyRule: ApplyRule.ME,
    category: 'OTHER',
    energyColors: [EnergyColors.BLUE],
    name: CardNames.GAUNTLET,
    details: 'Create blue nebulas',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.ARSENAL,
    applyRule: ApplyRule.ME,
    category: 'OTHER',
    energyColors: [EnergyColors.BLUE, EnergyColors.YELLOW],
    name: CardNames.SHELL,
    details: 'Protects from blue and yellow nebulas',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.ARSENAL,
    applyRule: ApplyRule.ME,
    category: 'OTHER',
    energyColors: [],
    name: CardNames.BACKPACK,
    details: '+2 cards on the hand',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
  {
    id: ulid(),
    cardType: CardType.PLAYING_CARD,
    playingCardType: PlayingCardType.ARSENAL,
    energyColors: [],
    applyRule: ApplyRule.ME,
    category: 'OTHER',
    name: CardNames.DOME,
    details: 'Possible protection from nebulas',
    image: ImagePath.testGauntlet,
    isOpen: true,
    allowedApplyTypes: [],
    gameModes: [],
  },
];

export const TEST_ROLE: RoleCard = {
  cardType: CardType.ROLE,
  role: Role.DELTARED,
  details:
    'are those mortals, who are possessed by Deltas and whose essences are overridden.' +
    ' They are fighting for full control of the planet.' +
    ' And this can only be achieved when the last forces of the Immunars are depleted.',
  description: 'are those mortals, who are possessed by Deltas and whose essences are overridden',
  image: ImagePath.testGauntlet,
  isOpen: true,
};

export const TEST_ROLE_DELTARED: RoleCard = {
  cardType: CardType.ROLE,
  role: Role.DELTARED,
  details:
    'Mortals, possesses by Deltas. They are fighting for full control over the planet. Goal: Deplete all Immunars',
  description:
    'Mortals, possesses by Deltas. They are fighting for full control over the planet. Goal: Deplete all Immunars',
  image: ImagePath.deltaredV1,
  isOpen: true,
};
export const TEST_ROLE_IMMUNAR: RoleCard = {
  cardType: CardType.ROLE,
  role: Role.IMMUNAR,
  details:
    'Mortals, who were not deltared and were injected with the cure.' +
    ' They can no longer be possessed by the Deltas. Goal: Deplete Deltared and Ortegonists',
  description: 'Mortals, who were not deltared and were injected with the cure',
  image: ImagePath.immunarV1,
  isOpen: true,
};
export const TEST_ROLE_ORTEGONIST: RoleCard = {
  cardType: CardType.ROLE,
  role: Role.ORTEGONIST,
  details:
    'Supporters of Dr. Ortega, whose minds were changed by the deadly dosage of vaccine. Goal: Deplete Deltared and then Immunars',
  description: 'Supporters of Dr. Ortega, whose minds were changed by the deadly dosage of vaccine',
  image: ImagePath.ortegonist,
  isOpen: true,
};

export const TEST_ROLE_CLOSED = { ...TEST_ROLE, isOpen: false };

export const onTestCardClick = (card: PlayingCard | RoleCard) => {
  alert(`card clicked:, ${JSON.stringify(card)}`);
};

export const generateTestPlayer = (): Player => ({
  id: ulid(),
  isCurrent: false,
  isWinner: false,
  cardsInHand: [],
  cardsOnTable: [],
  roleCard: TEST_ROLE,
  roleVisibleTo: [],
  playerName: 'Test Player',
  essences: 10,
  activeRadiances: [],
  actionSkips: 0,
});
