import React from 'react';
import { Props } from './cookieTable.types';
import styles from './cookieTable.module.css';

export const CookieTable: React.FC<Props> = (props) => {
  const { name, purpose, provider, service, type, expires } = props;
  return (
    <table className={styles.table}>
      <tbody>
        <tr>
          <td>Name:</td>
          <td>{name}</td>
        </tr>
        <tr>
          <td>Purpose:</td>
          <td>{purpose}</td>
        </tr>
        <tr>
          <td>Provider:</td>
          <td>{provider}</td>
        </tr>
        <tr>
          <td>Service:</td>
          <td>
            {service.name}
            {service.url && (
              <>
                {' '}
                <a href={service.url} target="_blank" rel="noreferrer">
                  View Service Privacy Policy
                </a>
              </>
            )}
          </td>
        </tr>
        <tr>
          <td>Type:</td>
          <td>{type}</td>
        </tr>
        <tr>
          <td>Expires in:</td>
          <td>{expires}</td>
        </tr>
      </tbody>
    </table>
  );
};
