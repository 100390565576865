import { Location } from '../../features/game/locationTrackerContextProvider';
import { HEADER_HEIGHT } from '../../global/app/header/header.styles';
import { DisperserLocation } from '../essenceBar';
import { MAX_WIDTH_MODIFIER } from '../card/hooks/useCardDimension';

export type Props = {
  playerId: string;
  count: number;
  animationType: AnimationType;
  disperserLocation: DisperserLocation;
  runEssenceUpdateAction: () => void;
  runAfter?: () => void;
};

export enum AnimationType {
  ADDITION,
  REDUCTION,
}

export const DISPERSER_ANIMATION_MULTIPLIER = 0.5;

export const fixDisperserLocationByHeaderHeight = (location: Location, isMobile: boolean): Location => {
  return {
    x: location.x,
    y: isMobile ? location.y : location.y - HEADER_HEIGHT,
  };
};

export const fixContainerLocationByHeight = (location: Location, dimension: number, isMobile: boolean): Location => {
  const containerCenterYCoordinates = location.y - (dimension * MAX_WIDTH_MODIFIER) / 2;

  return {
    x: location.x,
    y: isMobile ? containerCenterYCoordinates : containerCenterYCoordinates - HEADER_HEIGHT,
  };
};
