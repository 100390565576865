import React from 'react';
import { Props } from './hashLink.types';

export const HashLink: React.FC<Props> = ({ hash, children }) => {
  const handleNavigation = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <a
      href={`#${hash}`}
      onClick={(e) => {
        e.preventDefault();
        handleNavigation(hash);
      }}
    >
      {children}
    </a>
  );
};
