import { logoStyles, SxStyles } from '../../helpers/styles';
import { CONTENT_BOX_SX } from '../../constants/styles';

export const initSx = (): SxStyles => {
  return {
    storyBox: {
      p: 2,
      ...CONTENT_BOX_SX,
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      gap: 2,
    },
    rolesBox: {
      display: 'flex',
      width: '90%',
      justifyContent: 'space-around',
    },
    iconsBox: {
      display: 'flex',
      justifyContent: 'center',
    },
    creatorBox: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    missionText: { fontWeight: 'bold' },
    iconButton: { p: 1 },
    divider: { width: '90%' },
    logoBox: {
      ...logoStyles(),
    },
  };
};
