import React from 'react';
import { UsePrepareMovingCards } from './usePrepareMovingCards.types';
import { FINISHED, TurnAction } from '../../../../../gameTypes';
import { ApplyRule, CardNames, PlayingCard, PlayingCardType } from '../../../../../../card/cardTypes';
import { Location, LocationTrackerContext } from '../../../../../locationTrackerContextProvider';
import { Props as MovingCardsProps } from '../../../../../../../components/movingCards/movingCards.types';
import { roomItems } from '../../../../../../room/hooks/useTrackLocation';
import { filterUndefined, isEmpty, sortByEndingElement } from '../../../../../../../helpers/arrays';
import { closeCards, openCards } from '../../../../../../card/cardTransformers';
import {
  toAppliedAction,
  toDisintegratedAction,
  toImpactedAction,
  toTeleportedAction,
} from '../../../../../utils/turnActions';
import { AppContext } from '../../../../../../../global/context/appContext';
import { useMovingCardsPropsTransformers } from '../../../../../../animation/useAnimateCards/allPlayers/useMovingCardsPropsTransformers';
import { withFunc } from '../../../../../../../helpers/utils';
import { LogContext } from '../../../../../logContextProvider';
import { useSoundEffects } from '../../../../../actions/hooks/useSoundEffects';

export const usePrepareMovingCards: UsePrepareMovingCards = ({ gameState }) => {
  const { allPlayingCards } = React.useContext(AppContext);
  const { getItemLocation } = React.useContext(LocationTrackerContext);
  const { applyLogs } = React.useContext(LogContext);

  const { applyDrawCardSound, applyDiscardCardSound } = useSoundEffects({ gameState: gameState });
  const { lostCardsActionToMovingCards, discardedCardsToMovingCards } = useMovingCardsPropsTransformers({
    gameState: gameState,
  });

  const filterOutEnergySources = (cards: PlayingCard[]) =>
    cards.filter((card) => ![CardNames.ENERGY, CardNames.GAUNTLET].includes(card.name));

  const getCards = (turnAction: TurnAction): PlayingCard[] => {
    const cardIds = turnAction.cardIds;
    if (!cardIds) return [];

    return allPlayingCards.filter((card) => cardIds.includes(card.id));
  };

  const playersToHandLocations = (players: string[]): Location[] =>
    filterUndefined(players.map((playerId) => getItemLocation(roomItems.playerHand(playerId))));

  const playersToRadiancesLocation = (players: string[]): Location[] =>
    filterUndefined(players.map((playerId) => getItemLocation(roomItems.playerRadiances(playerId))));

  const filterOutGauntlets = (cards: PlayingCard[]) => cards.filter((card) => card.name !== CardNames.GAUNTLET);

  const drawCardActionToMovingCard = (
    applier: string,
    cards: PlayingCard[],
    runAfter?: () => void
  ): MovingCardsProps[] => {
    if (isEmpty(cards)) return [];
    const drawerPlayerLocation = getItemLocation(roomItems.playerHand(applier));
    const stops = filterUndefined([getItemLocation(roomItems.drawDeck), drawerPlayerLocation]);

    return [
      {
        cards: closeCards(cards),
        stops: stops,
        runAfter: runAfter,
        runWith: applyDrawCardSound,
        movementType: 'draw',
      },
    ];
  };

  const discardCardActionToMovingCard = (
    applier: string,
    cards: PlayingCard[],
    runAfter?: () => void
  ): MovingCardsProps[] => {
    if (isEmpty(cards)) return [];
    const discarderLocation = getItemLocation(roomItems.playerHand(applier));
    const stops = filterUndefined([discarderLocation, getItemLocation(roomItems.discardPile)]);

    return [
      {
        cards: closeCards(cards),
        stops: stops,
        runAfter: runAfter,
        runWith: applyDiscardCardSound,
        movementType: 'discard',
      },
    ];
  };

  const discardCardFromEngagementZoneActionToMovingCard = (
    cards: PlayingCard[],
    runAfter?: () => void
  ): MovingCardsProps[] => {
    if (isEmpty(cards)) return [];
    if (!isEmpty(gameState.cardsInPlayingArea)) {
      gameState.cardsInPlayingArea = [];
    }
    const stops = filterUndefined([getItemLocation(roomItems.engagementZone), getItemLocation(roomItems.discardPile)]);

    return [
      {
        cards: openCards(cards),
        stops: stops,
        runAfter: runAfter,
        runWith: applyDiscardCardSound,
        movementType: 'discard',
      },
    ];
  };

  const appliedCardsToMovingCards = (
    applier: string,
    cards: PlayingCard[],
    defendingPlayers: string[],
    appliedTo?: string,
    runAfter?: () => void
  ): MovingCardsProps[] => {
    if (isEmpty(cards)) return [];
    const appliedCard = filterOutEnergySources(cards)[0];
    if (!appliedCard) return [];

    const stops = cardToPlayersToStop(appliedCard, applier, defendingPlayers, appliedTo);
    return [{ cards: openCards(filterOutGauntlets(cards)), stops: stops, runAfter: runAfter }];
  };

  const cardToPlayersToStop = (
    card: PlayingCard,
    applier: string,
    defendingPlayers: string[],
    appliedTo?: string
  ): Location[] => {
    const applierLocation = getItemLocation(roomItems.playerHand(applier));
    const engagementZoneLocation = getItemLocation(roomItems.engagementZone);
    if (!engagementZoneLocation || !applierLocation) return [];

    const locations: Location[] = [applierLocation];

    switch (card.applyRule) {
      case ApplyRule.ALL_BUT_ME:
      case ApplyRule.ALL: {
        const playersToStop =
          isEmpty(defendingPlayers) && card.name === CardNames.MASS_ESSENCE_ESCAPE
            ? sortByEndingElement(gameState.playersOrdered, (player) => player === applier)
            : defendingPlayers;
        const playersLocations = playersToHandLocations(playersToStop ?? []);

        locations.push(...playersLocations, engagementZoneLocation);
        break;
      }
      case ApplyRule.ANY_PLAYER:
      case ApplyRule.OTHER_PLAYER: {
        if (isEmpty(defendingPlayers) && card.name === CardNames.REVEALER) {
          locations.push(engagementZoneLocation);
          break;
        }
        if (card.playingCardType === PlayingCardType.RADIANCE && appliedTo) {
          locations.push(applierLocation, ...playersToRadiancesLocation([appliedTo]));
          break;
        }
        if (appliedTo && applier !== appliedTo && card.name === CardNames.UNCHAINED_ESSENCE) {
          locations.push(...playersToHandLocations([appliedTo]), engagementZoneLocation);
          break;
        }
        locations.push(...playersToHandLocations(defendingPlayers), engagementZoneLocation);
        break;
      }
      case ApplyRule.ME:
        locations.push(engagementZoneLocation);
        break;
    }

    return locations;
  };

  const teleportedCardsToMovingCards = (
    applier: string,
    cards: PlayingCard[],
    locationFunc: (playerId: string) => string,
    isCardOpen: boolean,
    appliedTo?: string,
    runAfter?: () => void
  ): MovingCardsProps[] => {
    if (isEmpty(cards) || !appliedTo) return [];
    const visibilityStateFunc = isCardOpen ? openCards : closeCards;
    const stops = filterUndefined([getItemLocation(locationFunc(appliedTo)), getItemLocation(locationFunc(applier))]);

    return [{ cards: visibilityStateFunc(cards), stops: stops, runAfter: runAfter }];
  };

  const currentTurnToMovingCards = (
    turnAction: TurnAction,
    defendingPlayers: string[],
    initialRunAfter?: () => void
  ): MovingCardsProps[] => {
    const [applier, appliedTo, logs] = [turnAction.player, turnAction.appliedTo, turnAction.logs];
    if (!applier) return [];
    let runAfter = initialRunAfter;

    if (logs && !isEmpty(logs)) {
      runAfter = runAfter ? withFunc(() => void applyLogs(logs), runAfter) : () => void applyLogs(logs);
    }

    const cards = getCards(turnAction);

    const cardWithNoSources = filterOutEnergySources(cards);
    switch (turnAction.action) {
      case 'drawCard':
        return drawCardActionToMovingCard(applier, cards, runAfter);
      case 'cardsDiscarded':
        return discardCardActionToMovingCard(applier, cards, runAfter);
      case 'cardsDiscardedFromEngagementZone':
        return discardCardFromEngagementZoneActionToMovingCard(cards, runAfter);
      case !isEmpty(cardWithNoSources) && toAppliedAction(cardWithNoSources[0].name):
        return appliedCardsToMovingCards(applier, cards, defendingPlayers, appliedTo, runAfter);
      case toTeleportedAction('cardsInHand'):
        /* TODO
            will leave it here. Card in hand is a destination, so if applied arsenal
            card is teleported to the hand, it will no be visible during animation.
         */
        return teleportedCardsToMovingCards(applier, cards, roomItems.playerHand, false, appliedTo, runAfter);
      case toTeleportedAction('cardsOnTable'):
        return teleportedCardsToMovingCards(applier, cards, roomItems.playerArsenal, true, appliedTo, runAfter);
      case toTeleportedAction('activeRadiances'):
        return teleportedCardsToMovingCards(applier, cards, roomItems.playerRadiances, true, appliedTo, runAfter);
      case toDisintegratedAction('cardsInHand'):
        return discardedCardsToMovingCards(cards, roomItems.playerHand, false, appliedTo, runAfter);
      case toDisintegratedAction('cardsOnTable'):
        return discardedCardsToMovingCards(cards, roomItems.playerArsenal, true, appliedTo, runAfter);
      case toDisintegratedAction('activeRadiances'):
        return discardedCardsToMovingCards(cards, roomItems.playerRadiances, true, appliedTo, runAfter);
      case 'lost cards upon removal':
      case toImpactedAction(CardNames.DISORIENTATION):
      case toImpactedAction(CardNames.SQUANDER):
        return lostCardsActionToMovingCards(turnAction, appliedTo, runAfter);

      default:
        return [];
    }
  };

  const essenceHuntToMovingCards = (
    applier: string,
    cards: PlayingCard[],
    runAfter?: () => void
  ): MovingCardsProps[] => {
    if (isEmpty(cards)) return [];
    const essenceHunterLocation = getItemLocation(roomItems.playerHand(applier));
    const stops = filterUndefined([essenceHunterLocation, getItemLocation(roomItems.discardPile)]);

    return [
      {
        cards: closeCards(cards),
        stops: stops,
        runAfter: runAfter,
        runWith: applyDiscardCardSound,
        movementType: 'discard',
      },
    ];
  };

  const previousTurnToMovingCards = (turnAction: TurnAction, initialRunAfter?: () => void): MovingCardsProps[] => {
    const [applier, appliedTo, logs] = [turnAction.player, turnAction.appliedTo, turnAction.logs];
    const cards = getCards(turnAction);
    let runAfter = initialRunAfter;

    if (logs && !isEmpty(logs)) {
      runAfter = runAfter ? withFunc(() => void applyLogs(logs), runAfter) : () => void applyLogs(logs);
    }

    switch (turnAction.action) {
      case `essence hunt ${FINISHED}`:
        return essenceHuntToMovingCards(applier, cards, runAfter);
      case 'cardsDiscarded':
        return discardCardActionToMovingCard(applier, cards, runAfter);
      case 'cardsDiscardedFromEngagementZone':
        return discardCardFromEngagementZoneActionToMovingCard(cards, runAfter);
      case 'lost cards upon removal':
      case toImpactedAction(CardNames.DISORIENTATION):
        return lostCardsActionToMovingCards(turnAction, appliedTo, runAfter);
      default:
        return [];
    }
  };

  return {
    currentTurnToMovingCards: currentTurnToMovingCards,
    previousTurnToMovingCards: previousTurnToMovingCards,
  };
};
