import { ProjectColors } from '../../../../themes/mainTheme';
import { PLAYER_STATE_BOX_SX } from '../../../../constants/styles';

const borderStyle = `1px solid ${ProjectColors.BLACK}`;
const boxBasicSx = { display: 'flex', pl: 0, pr: 0.5, pt: 0.5, pb: 0.5, width: '100%' };

export const initSx = (cardDimension: number, radianceCount: number) => {
  const containerBoxSx = { height: cardDimension * 0.25 };
  const sizeSx = {
    default: { borderRight: borderStyle, px: cardDimension * 0.01 },
    handWithinLimit: { flex: 1, width: cardDimension * 3.5 - radianceCount * cardDimension },
    arsenal: { width: cardDimension * 4.3 },
  };

  return {
    mainBox: { p: 0 },
    outerBox: { display: 'flex' },
    radiances: { px: 0.5 },
    default: sizeSx.default,
    roleBox: {
      ...sizeSx.default,
      cursor: 'pointer',
    },
    trayBox: {
      ...boxBasicSx,
    },
    stateBox: {
      ...PLAYER_STATE_BOX_SX,
      zIndex: -1,
    },
    mainActionBox: {
      ...sizeSx.default,
      width: cardDimension * 1.5,
      lineHeight: 1.2,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      my: '5px',
    },
    containerBox: { ...containerBoxSx, width: '100%' },
    arsenal: { ...sizeSx.default, ...sizeSx.arsenal },
    hand: {
      overLimit: { ...sizeSx.default, ...sizeSx.handWithinLimit, backgroundColor: `${ProjectColors.PINK}` },
      withinLimit: sizeSx.handWithinLimit,
      innerBox: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      labelBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 0.7,
        marginRight: 0.5,
      },
    },
    minimizedEssenceBar: { display: 'flex' },
  };
};
