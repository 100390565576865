import { ALLOWED_DUPLICATED_ACTION_CARDS, UseApplyCardRules } from './useApplyCardRules.types';
import { getCurrentPlayer, getPlayerEnergySourceCards } from '../../utils/player';
import {
  AllowedApplyType,
  ApplyRule,
  ApplyRuleGroup,
  ATTACKING_CARDS,
  ATTACKING_NEBULA_CARDS,
  CardNames,
  DICE_MANIPULATION_CARDS,
  ESSENCE_GAIN_CARDS,
  ESSENCE_LOSS_CARDS,
  COMBAT_ALLOWED_APPLY_TYPES,
  PlayingCard,
  PlayingCardType,
  ENERGY_DEPENDENT_CARD_TYPES,
  DEFENDING_NEBULA_CARDS,
} from '../../../../card/cardTypes';
import { APPLIED, IN_PROGRESS } from '../../../gameTypes';
import { hasOneElement, isEmpty, last } from '../../../../../helpers/arrays';
import { APPLY_RULES_BY_GROUP } from '../../../../card/cardTransformers';
import { cardsAndActionTypesToActions, toAppliedAction } from '../../../utils/turnActions';
import {
  canDisintegrate,
  canPlayerFinishDiceAction,
  canTeleport,
  filterIgnoredDefendingActions,
  IsActionActive,
  isCardRuleArsenalSlotEmpty,
  isChallengeActive,
  isDisorientationInProgress,
  isDomeCheckInProgress,
  isEssenceHuntInProgress,
  isOneOfActionsActive,
} from '../../../rules/rules';
import { GameState } from '../useGameState';
import { Player } from '../../../../player/player.types';
import { DraggedCardData } from '../../../../../components/draggable';
import React from 'react';
import { LogContext } from '../../../logContextProvider';

export const useApplyCardRules: UseApplyCardRules = () => {
  const { setNotification } = React.useContext(LogContext);

  const canApplyCardToOtherPlayer = (card: PlayingCard, currentPlayer: Player, gameState: GameState): boolean => {
    if (![ApplyRule.OTHER_PLAYER, ApplyRule.ANY_PLAYER].includes(card.applyRule)) return false;
    if (isCardUsedInTurnByPlayer(card, currentPlayer, gameState)) return false;
    if (
      gameState.activeDefendingPlayer === currentPlayer.id &&
      [...ATTACKING_CARDS, ...ESSENCE_GAIN_CARDS].includes(card.name)
    ) {
      return false;
    }
    if (isOneOfActionsActive(gameState, [isDisorientationInProgress, isDomeCheckInProgress, isChallengeActive])) {
      return false;
    }

    return card.playingCardType === PlayingCardType.CAPSULE || !isEmpty(getPlayerEnergySourceCards(currentPlayer));
  };

  const canApplyCardToPlayingArea = (card: PlayingCard, currentPlayer: Player, gameState: GameState): boolean => {
    if (!APPLY_RULES_BY_GROUP[ApplyRuleGroup.PLAYING_AREA].includes(card.applyRule)) return false;

    if (isEssenceHuntInProgress(gameState.turnActions)) return false;
    if (canTeleport(gameState.turnActions) || canDisintegrate(gameState.turnActions)) return false;
    if (
      isCardUsedInTurnByPlayer(card, currentPlayer, gameState) &&
      !ALLOWED_DUPLICATED_ACTION_CARDS.includes(card.name) &&
      (!ESSENCE_GAIN_CARDS.includes(card.name) ||
        currentPlayer.essences > 1 ||
        gameState.activeDefendingPlayer !== currentPlayer.id)
    ) {
      return false;
    }

    if (gameState.activeDefendingPlayer === currentPlayer.id && ATTACKING_CARDS.includes(card.name)) return false;

    if (
      isOneOfActionsActive(gameState, [isDisorientationInProgress, isDomeCheckInProgress]) &&
      !DICE_MANIPULATION_CARDS.includes(card.name)
    ) {
      return false;
    }

    const lastAttackingAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;
    const essenceLossActions = cardsAndActionTypesToActions(ESSENCE_LOSS_CARDS, [APPLIED, IN_PROGRESS]);

    if (
      gameState.activeDefendingPlayer === currentPlayer.id &&
      ESSENCE_GAIN_CARDS.includes(card.name) &&
      (lastAttackingAction === undefined ||
        !essenceLossActions.includes(lastAttackingAction) ||
        currentPlayer.essences > 1)
    ) {
      return false;
    }

    if (
      card.name === CardNames.ESSENCE_SHIELD &&
      (lastAttackingAction === undefined || !essenceLossActions.includes(lastAttackingAction))
    ) {
      return false;
    }

    const attackingNebulaActions = cardsAndActionTypesToActions(ATTACKING_NEBULA_CARDS, [APPLIED, IN_PROGRESS]);
    if (
      DEFENDING_NEBULA_CARDS.includes(card.name) &&
      (lastAttackingAction === undefined || !attackingNebulaActions.includes(lastAttackingAction))
    ) {
      return false;
    }

    if (
      card.name === CardNames.SPACE_DISTORTION &&
      canPlayerFinishDiceAction(currentPlayer.id, gameState, isChallengeActive)
    ) {
      return false;
    }

    if (
      DICE_MANIPULATION_CARDS.includes(card.name) &&
      !canPlayerFinishOneOfActions(currentPlayer.id, gameState, [
        isChallengeActive,
        isDisorientationInProgress,
        isDomeCheckInProgress,
      ])
    ) {
      return false;
    }

    if (
      isChallengeActive(gameState) &&
      currentPlayer.id === gameState.activePlayer &&
      !DICE_MANIPULATION_CARDS.includes(card.name)
    )
      return false;

    return card.playingCardType === PlayingCardType.CAPSULE || !isEmpty(getPlayerEnergySourceCards(currentPlayer));
  };

  const canCurrentPlayerApplyCards = (currentPlayer: Player, gameState: GameState) => {
    if (gameState.activeDefendingPlayer === '' && currentPlayer.id === gameState.activePlayer) return true;
    return gameState.activeDefendingPlayer !== '' && currentPlayer.id === gameState.activeDefendingPlayer;
  };

  const isPlayerInvolvedInMove = (currentPlayer: Player, gameState: GameState, playerId: string): boolean =>
    currentPlayer.id === playerId &&
    (playerId === gameState.activePlayer || playerId === gameState.activeDefendingPlayer);

  const fillHandCardsAllowedApplyTypes = (gameState: GameState) => {
    gameState.allPlayers = gameState.allPlayers
      .map(({ ...player }) => ({
        ...player,
        cardsInHand: player.cardsInHand.map(({ ...card }) => ({
          ...card,
          allowedApplyTypes: fillHandCardAllowedApplyTypes(card, gameState, player.id),
        })),
      }))
      .map(({ ...player }) => ({
        ...player,
        cardsInHand: player.cardsInHand.map(({ ...card }) => ({
          ...card,
          allowedApplyTypes: fillHandEnergyCardAllowedApplyTypes(card, player),
        })),
      }));
  };

  const fillHandEnergyCardAllowedApplyTypes = (card: PlayingCard, player: Player) => {
    if (!card.isOpen || card.applyRule !== ApplyRule.ENERGY) return card.allowedApplyTypes;

    return isEmpty(
      player.cardsInHand.filter(
        (handCard) =>
          COMBAT_ALLOWED_APPLY_TYPES.some((applyType) => handCard.allowedApplyTypes.includes(applyType)) &&
          ENERGY_DEPENDENT_CARD_TYPES.includes(handCard.playingCardType)
      )
    )
      ? card.allowedApplyTypes
      : [...card.allowedApplyTypes, AllowedApplyType.ENERGY];
  };

  const fillHandCardAllowedApplyTypes = (card: PlayingCard, gameState: GameState, playerId: string) => {
    let currentAllowedApplyTypes = card.allowedApplyTypes;
    if (!card.isOpen) return currentAllowedApplyTypes;

    const currentPlayer = getCurrentPlayer(gameState);
    if (currentPlayer === undefined) return currentAllowedApplyTypes;
    if (!isPlayerInvolvedInMove(currentPlayer, gameState, playerId)) return currentAllowedApplyTypes;
    if (!canCurrentPlayerApplyCards(currentPlayer, gameState)) return [AllowedApplyType.INACTIVE];
    if (canApplyCardToArsenal(card, currentPlayer, gameState)) return [AllowedApplyType.ARSENAL];

    if (canApplyCardToOtherPlayer(card, currentPlayer, gameState)) {
      currentAllowedApplyTypes = [...currentAllowedApplyTypes, AllowedApplyType.OTHER_PLAYER];
    }
    if (canApplyCardToPlayingArea(card, currentPlayer, gameState)) {
      currentAllowedApplyTypes = [...currentAllowedApplyTypes, AllowedApplyType.PLAYING_AREA];
    }
    if (canTeleport(gameState.turnActions)) {
      currentAllowedApplyTypes = [...currentAllowedApplyTypes, AllowedApplyType.ON_TELEPORT];
    }
    if (isEssenceHuntInProgress(gameState.turnActions)) {
      currentAllowedApplyTypes = [...currentAllowedApplyTypes, AllowedApplyType.FOR_ESSENCE_HUNT];
    }

    if (isEmpty(currentAllowedApplyTypes)) {
      currentAllowedApplyTypes = [AllowedApplyType.INACTIVE];
    }

    return currentAllowedApplyTypes;
  };

  const canApplyCardToArsenal = (card: PlayingCard, currentPlayer: Player, gameState: GameState): boolean =>
    currentPlayer.id === gameState.activePlayer &&
    currentPlayer.id !== gameState.activeDefendingPlayer &&
    isCardRuleArsenalSlotEmpty(card, currentPlayer.cardsOnTable) &&
    !isCardUsedInTurnByPlayer(card, currentPlayer, gameState, true) &&
    !isOneOfActionsActive(gameState, [isDisorientationInProgress, isDomeCheckInProgress, isChallengeActive]) &&
    card.isOpen;

  const isCardUsedInTurnByPlayer = (
    card: PlayingCard,
    currentPlayer: Player,
    gameState: GameState,
    onlySelfApplied: boolean = false
  ): boolean =>
    gameState.turnActions.find(
      (action) =>
        action.player === currentPlayer.id &&
        action.action === toAppliedAction(card.name) &&
        (!onlySelfApplied || action.appliedTo === action.player)
    ) !== undefined;

  const canPlayerFinishOneOfActions = (
    playerId: string,
    gameState: GameState,
    isActionActiveFunctions: IsActionActive[]
  ): boolean => {
    return isActionActiveFunctions.some((isActionActive) => {
      return canPlayerFinishDiceAction(playerId, gameState, isActionActive);
    });
  };

  const addApplyActiveEnergyNotificationOnDragging = (isDragging: boolean, draggableData: DraggedCardData) => {
    if (
      isDragging &&
      draggableData.isActive &&
      hasOneElement(draggableData.cards) &&
      draggableData.cards[0].applyRule === ApplyRule.ENERGY &&
      draggableData.cards[0].isOpen
    ) {
      void setNotification({ type: 'warning', text: 'Use Nebula/Radiance for applying card as energy' });
    }
  };

  return {
    fillHandCardsAllowedApplyTypes: fillHandCardsAllowedApplyTypes,
    addApplyActiveEnergyNotificationOnDragging: addApplyActiveEnergyNotificationOnDragging,
  };
};
