import { GameStateApiData, NumberOfPlayers } from './gameTypes';
import { validateGameStateCards } from './gameValidators';
import { PlayingCard, RoleCard } from '../card/cardTypes';
import { filterUndefined } from '../../helpers/arrays';
import { Player } from '../player/player.types';
import { closeCard, openCard } from '../card/cardTransformers';
import {
  ApiGameState,
  ApiPlayerGameState,
  GameApiData,
  GameState,
  RoleData,
} from './gameContextHandler/hooks/useGameState';
import { getAllRoleCards } from './rules/roles';
import { formatMinutesToVisualFormat } from '../../helpers/utils';
import { FullViewGameTableRow } from '../../components/gameStatsBlock';
import { FinishedGameApiData } from '../stats/statsTypes';

export const playerNumberToEnum = (intNumber: number): NumberOfPlayers => {
  const foundNumber = Object.values(NumberOfPlayers).filter((numOfPlayers) => {
    return numOfPlayers !== undefined && numOfPlayers === intNumber;
  })[0];

  if (foundNumber === undefined || foundNumber === '') {
    throw Error(`Invalid number of players: ${intNumber}`);
  }

  return foundNumber as NumberOfPlayers;
};

export const gameStateApiDataToApiGameState = (gameStateApiData: GameStateApiData): ApiGameState => {
  return {
    ...JSON.parse(gameStateApiData.data),
    createdAt: gameStateApiData.createdAt,
  };
};

export const gameStateApiToGameStateDataString = (gameState: ApiGameState): string => {
  return JSON.stringify(gameState);
};

export const roleDataToCard = (data: RoleData): RoleCard => {
  return getAllRoleCards().filter(
    (pickedCard) => pickedCard.role === data.name && pickedCard.image === data.imagePath
  )[0];
};

export const roleCardToData = (card: RoleCard): RoleData => {
  return { name: card.role, imagePath: card.image };
};

export const apiGameStateToGameState = (
  apiGameState: ApiGameState,
  allPlayingCards: PlayingCard[],
  game: GameApiData,
  currentPlayerId: string
): GameState => {
  const createPlayer = (player: ApiPlayerGameState, isCurrent: boolean): Player => {
    const roleCard = roleDataToCard(player.role);
    const isRoleOpen = isCurrent || player.roleVisibleTo.includes(currentPlayerId);

    return {
      id: player.id,
      isCurrent: isCurrent,
      playerName: game.players.filter((playerData) => playerData.id === player.id)[0].name,
      isWinner: player.isWinner,
      roleCard: isRoleOpen ? openCard(roleCard) : closeCard(roleCard),
      roleVisibleTo: game.players
        .filter((playerData) => player.roleVisibleTo.includes(playerData.id))
        .map((playerData) => playerData.id),
      essences: player.essences,
      cardsOnTable: filterUndefined(
        player.cardsOnTable.map((cardId) => allPlayingCards.find((card) => card.id === cardId))
      ).map((card) => openCard(card)),
      cardsInHand: filterUndefined(
        player.cardsInHand.map((cardId) => allPlayingCards.find((card) => card.id === cardId))
      ).map((card) => (isCurrent ? openCard(card) : closeCard(card))),
      activeRadiances: filterUndefined(
        player.activeRadiances.map((cardId) => allPlayingCards.find((card) => card.id === cardId))
      ).map((card) => openCard(card)),
      actionSkips: player.actionSkips,
    };
  };

  const currentPlayerIndex = apiGameState.playersGameState.findIndex((player) => player.id === currentPlayerId);
  const otherSortedPlayers = [...apiGameState.playersGameState.slice(currentPlayerIndex)];
  otherSortedPlayers.push(...apiGameState.playersGameState.slice(0, currentPlayerIndex));
  otherSortedPlayers.shift();

  return {
    roundNumber: apiGameState.roundNumber,
    turnActions: apiGameState.turnActions,
    previousTurnActions: apiGameState.previousTurnActions,
    activePlayer: apiGameState.activePlayer,
    diceRollAllowedFor: apiGameState.diceRollAllowedFor,
    isFinished: apiGameState.isFinished,
    drawCardAmount: apiGameState.drawCardAmount,
    playersOrdered: apiGameState.playersOrdered,
    draggable: apiGameState.draggable,
    droppableList: apiGameState.droppableList,
    dragger: apiGameState.dragger,
    defendingPlayerIds: apiGameState.defendingPlayerIds,
    activeDefendingPlayer: apiGameState.activeDefendingPlayer,
    previousDefendingPlayer: apiGameState.previousDefendingPlayer,
    allPlayers: [
      createPlayer(apiGameState.playersGameState[currentPlayerIndex], true),
      ...otherSortedPlayers.map((player) => createPlayer(player, false)),
    ],
    cardsInDeck: allPlayingCards.filter((card) => apiGameState.cardsInDeck.includes(card.id)),
    cardsInDiscardPile: allPlayingCards.filter((card) => apiGameState.cardsInDiscardPile.includes(card.id)),
    cardsInPlayingArea: filterUndefined(
      apiGameState.cardsInPlayingArea.map((cardId) => allPlayingCards.find((card) => card.id === cardId))
    ).map((card) => openCard(card)),
    createdAt: apiGameState.createdAt,
  };
};

export const gameStateToApiGameState = (gameState: GameState, allPlayingCards: PlayingCard[]): ApiGameState => {
  const createPlayerGameState = (player: Player): ApiPlayerGameState => ({
    id: player.id,
    isWinner: player.isWinner,
    role: roleCardToData(player.roleCard),
    roleVisibleTo: player.roleVisibleTo,
    cardsInHand: player.cardsInHand.map((card) => card.id),
    cardsOnTable: player.cardsOnTable.map((card) => card.id),
    essences: player.essences,
    activeRadiances: player.activeRadiances.map((card) => card.id),
    actionSkips: player.actionSkips,
  });

  const currentPlayerData = createPlayerGameState(gameState.allPlayers.filter((player) => player.isCurrent)[0]);
  const otherPlayersData = gameState.allPlayers
    .filter((player) => !player.isCurrent)
    .map((player) => createPlayerGameState(player));

  const allPlayersData = [currentPlayerData, ...otherPlayersData];

  const gameStateData: ApiGameState = {
    roundNumber: gameState.roundNumber,
    isFinished: gameState.isFinished,
    drawCardAmount: gameState.drawCardAmount,
    playersOrdered: gameState.playersOrdered,
    activePlayer: gameState.activePlayer,
    diceRollAllowedFor: gameState.diceRollAllowedFor,
    allPlayingCards: allPlayingCards.map((card) => card.id),
    cardsInDeck: gameState.cardsInDeck.map((card) => card.id),
    cardsInDiscardPile: gameState.cardsInDiscardPile.map((card) => card.id),
    cardsInPlayingArea: gameState.cardsInPlayingArea.map((card) => card.id),
    playersGameState: allPlayersData,
    defendingPlayerIds: gameState.defendingPlayerIds,
    activeDefendingPlayer: gameState.activeDefendingPlayer,
    previousDefendingPlayer: gameState.previousDefendingPlayer,
    turnActions: gameState.turnActions,
    previousTurnActions: gameState.previousTurnActions,
    draggable: gameState.draggable,
    droppableList: gameState.droppableList,
    dragger: gameState.dragger,
    createdAt: gameState.createdAt,
  };

  validateGameStateCards(gameStateData);

  return gameStateData;
};

export const finishedGameApiDataToStatsTableRow = (gameApiData: FinishedGameApiData): FullViewGameTableRow => {
  return {
    gameId: '..' + gameApiData.uniqueId.slice(-5),
    numberOfPlayers: gameApiData.numberOfPlayers,
    players: gameApiData.players.map((player) => player.name).join(', '),
    playersWon: gameApiData.playersWon.map((player) => player.name).join(', '),
    roleWon: gameApiData.roleWon,
    duration: formatMinutesToVisualFormat(gameApiData.durationInMinutes),
  };
};
