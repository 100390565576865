import React from 'react';
import styles from './backgroundOverlay.module.css';
import { AppContext } from '../../global/context/appContext';
import { HEADER_HEIGHT } from '../../global/app/header/header.styles';

export const BackgroundOverlay: React.FC = () => {
  const { windowSize, isMobile } = React.useContext(AppContext);
  const overlayRef = React.useRef<HTMLDivElement>(null);

  const resolveLogsCardClass = (): string => {
    if (!overlayRef.current) return styles.container;

    overlayRef.current.style.setProperty(
      '--image-height',
      `${isMobile ? `${windowSize.height}px` : `${windowSize.height - HEADER_HEIGHT - 1}px`}`
    );
    return styles.container;
  };

  return <div ref={overlayRef} className={resolveLogsCardClass()} />;
};
