import { Location } from '../../features/game/locationTrackerContextProvider';
import { HEADER_HEIGHT } from '../../global/app/header/header.styles';

export type Props = {
  playerId: string;
  probabilityPercentage: number;
};

export const fixIconLocationByHeaderHeight = (location: Location, isMobile: boolean): Location => {
  return {
    x: location.x,
    y: isMobile ? location.y : location.y - HEADER_HEIGHT / 1.2,
  };
};
