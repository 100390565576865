import { zIndexes } from '../../constants/components';
import { SxStyles } from '../../helpers/styles';
import { Location } from '../../features/game/locationTrackerContextProvider';

export const sxInit = (targetLocation: Location, isMobile: boolean): SxStyles => {
  return {
    targetLocationBox: {
      top: `${targetLocation.y}px`,
      left: `${targetLocation.x}px`,
      position: 'absolute',
      borderRadius: '50%',
      overflow: 'hidden',
      width: '7rem',
      zIndex: zIndexes.movingEssenceAnimation,
    },
    targetIcon: {
      fontSize: isMobile ? '1.4rem' : { xs: '0.9rem', sm: '1rem', md: '1.2rem', lg: '1.5rem' },
    },
  };
};
