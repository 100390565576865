import React from 'react';
import { Props } from './home.type';
import { Box, Typography } from '@mui/material';
import { AppContext } from '../../global/context/appContext';
import { Logo } from '../../components/logo/logo';
import { MotionBlock } from '../../components/motionBlock';
import { sxInit } from './home.styles';
import { YoutubePlayer } from '../../components/youtubePlayer';
import { YOUTUBE_STORY_VIDEO_ID } from '../../constants/socialNetworks';
import { GameStatsBlock } from '../../components/gameStatsBlock';
import { ContentPageWrapper } from '../../components/contentPageWrapper';
import { PlayNowButtons } from '../../features/game/playNowButtons';
import { useDiscordLogin } from '../../features/menu/discordLoginButton/useDiscordLogin';

export const Home: React.FC<Props> = () => {
  const { isMobile } = React.useContext(AppContext);
  useDiscordLogin();

  const sx = sxInit(isMobile);
  const title = `Deltaration is inevitable! Immunars, Deltared and Ortegonists fight for Quadraen's fate`;

  const outerElements = (
    <>
      <Box sx={sx.titleBox}>
        <Typography variant="h1" sx={sx.title}>
          {title}
        </Typography>
      </Box>
      <Box sx={sx.logoBox}>
        <MotionBlock transitionDuration={1.5}>
          <Logo />
        </MotionBlock>
      </Box>
    </>
  );

  const innerElements = (
    <>
      <MotionBlock transitionDuration={2}>
        <Box sx={sx.playNowBox}>
          <PlayNowButtons />
        </Box>
      </MotionBlock>
      <MotionBlock transitionDuration={2}>
        <Box sx={sx.gameStatBox}>
          <GameStatsBlock />
        </Box>
      </MotionBlock>
      <Box sx={sx.storyComicsBox}>
        <YoutubePlayer videoId={YOUTUBE_STORY_VIDEO_ID} height={'100%'} width={'100%'}></YoutubePlayer>
      </Box>
    </>
  );

  return <ContentPageWrapper outerBoxElements={outerElements} innerBoxElements={innerElements} />;
};
