import { UseCardEffects } from './useCardEffects.types';
import { useRevealerEffect } from './useRevealerEffect';
import { useUnchainedEssenceEffect } from './useUnchangedEssenceEffect';
import { useMassEssenceEscapeEffect } from './useMassEssenceEscapeEffect';
import { useDeEssencerEffect } from './useDeEssencerEffect';
import { useEqualizerEffect } from './useEqualizerEffect';
import { useSpyEffect } from './useSpyEffect';
import { useBlackHoleEffect } from './useBlackHoleEffect';
import { useTeleportEffect } from './useTeleportEffect';
import { useSpaceDistortionEffect } from './useSpaceDistortionEffect';
import { useEssenceShieldEffect } from './useEssenceShieldEffect';
import { useDisintegratorEffect } from './useDisintegratorEffect';
import { useShellEffect } from './useShellEffect';
import { useDisorientationEffect } from './useDisorientationEffect';
import { useFoggerEffect } from './useFoggerEffect';
import { useAmnesiaEffect } from './useAmnesiaEffect';
import { useDomeEffect } from './useDomeEffect';
import { useChallengeEffect } from './useChallengeEffect';
import { useConversionerEffect } from './useConversionerEffect';

export const useCardEffects: UseCardEffects = () => {
  const runRevealerEffect = useRevealerEffect();
  const runUnchainedEssenceEffect = useUnchainedEssenceEffect();
  const runMassUnchainedEssenceEffect = useMassEssenceEscapeEffect();
  const runDeEssencerEffect = useDeEssencerEffect();
  const runEqualizerEffect = useEqualizerEffect();
  const runSpyEffect = useSpyEffect();
  const runBlackHoleEffect = useBlackHoleEffect();
  const runTeleportEffect = useTeleportEffect();
  const runDisintegratorEffect = useDisintegratorEffect();
  const runSpaceDistortionEffect = useSpaceDistortionEffect();
  const runEssenceShieldEffect = useEssenceShieldEffect();
  const runShellEffect = useShellEffect();
  const runDisorientationEffect = useDisorientationEffect();
  const runFoggerEffect = useFoggerEffect();
  const runAmnesiaEffect = useAmnesiaEffect();
  const runDomeEffect = useDomeEffect();
  const runChallengeEffect = useChallengeEffect();
  const runConversionEffect = useConversionerEffect();

  return () => {
    runRevealerEffect();
    runUnchainedEssenceEffect();
    runMassUnchainedEssenceEffect();
    runDeEssencerEffect();
    runEqualizerEffect();
    runSpyEffect();
    runBlackHoleEffect();
    runTeleportEffect();
    runDisintegratorEffect();
    runSpaceDistortionEffect();
    runEssenceShieldEffect();
    runShellEffect();
    runDisorientationEffect();
    runFoggerEffect();
    runAmnesiaEffect();
    runDomeEffect();
    runChallengeEffect();
    runConversionEffect();
  };
};
