import React from 'react';
import { Props } from './role.types';
import { initSx } from './role.styles';
import { Box } from '@mui/material';
import { cardToComponent } from '../../../../../helpers/transformers';
import { GameContext } from '../../../../game/gameContext';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { useCardDimension } from '../../../../../components/card/hooks/useCardDimension';
import { Card } from '../../../../../components/card';

export const Role: React.FC<Props> = ({ size, onProfileShow }) => {
  const { gameState } = React.useContext(GameContext);
  const currentPlayer = getCurrentPlayer(gameState);

  const { dimension: cardDimension } = useCardDimension({ size: size });

  const sx = initSx(cardDimension);
  return (
    <Box sx={sx.roleAndRadiancesBox}>
      <Box sx={sx.role} onClick={onProfileShow}>
        <Card
          {...cardToComponent(currentPlayer.roleCard, size, undefined, undefined)}
          customDisplayType={'showFullSizeImageAndName'}
        />
      </Box>
    </Box>
  );
};
