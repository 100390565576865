export const HEADER_HEIGHT = 44;
export const TOOLBAR_HEIGHT = 48;
export const sx = {
  toolbar: { display: 'flex', justifyContent: 'space-between', minHeight: `${TOOLBAR_HEIGHT}px` },
  button: {
    pl: { md: 5 },
    pr: { md: 5 },
    mb: 0.5,
    maxHeight: '45px',
    fontSize: { xs: '0.6rem', lg: '0.8rem' },
  },
};
