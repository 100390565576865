import { PlayingCard } from '../../../features/card/cardTypes';
import { Location } from '../../../features/game/locationTrackerContextProvider';
import { MovementType } from '../movingCards.types';

export type Props = {
  stops: Location[];
  cards: PlayingCard[];
  movementType?: MovementType;
};

export const DEFAULT_COLOR_MOVEMENT_TYPES: MovementType[] = ['draw', 'discard'];
