import React from 'react';
import { UseAmnesiaEffect } from './useAmnesiaEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { last } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import { toAppliedAction, toImpactedAction } from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { useRollQuantumDiceAction } from '../../../../game/actions/hooks/useRollQuantumDiceAction';
import { LogContext } from '../../../../game/logContextProvider';

export const useAmnesiaEffect: UseAmnesiaEffect = () => {
  const { gameState, addTurnAction } = React.useContext(GameContext);
  const { setNotification } = React.useContext(LogContext);
  const { rollQuantumDice } = useRollQuantumDiceAction();

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const lastTurnAction = last(gameState.turnActions.filter((action) => action.player === currentPlayer.id))?.action;

    if (lastTurnAction === undefined || lastTurnAction !== toAppliedAction(CardNames.AMNESIA)) return;

    addTurnAction({ player: currentPlayer.id, action: toImpactedAction(CardNames.AMNESIA) });
    rollQuantumDice();
    void setNotification({ type: 'warning', text: 'Quantum dice was re-rolled' });
  };
};
