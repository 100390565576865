import React, { ReactElement } from 'react';
import { Props } from './otherPlayer.types';
import { Box, SxProps } from '@mui/material';
import { NORMAL_TO_EXTENDED_SIZE } from '../../../helpers/sizes';
import { Card, CardIdentified } from '../../../components/card';
import { BLOCKS_DIRECTION_TO_DISPERSER_LOCATION, cardToComponent } from '../../../helpers/transformers';
import { Name } from '../../../components/name';
import { initSx } from './otherPlayer.styles';
import { Droppable } from '../droppable';
import { GameContext } from '../../game/gameContext';
import { ApplyRuleGroup } from '../../card/cardTypes';
import { canApplyToOtherPlayer } from '../../game/rules/rules';
import { Draggable, DraggedCardData, DragSource } from '../../../components/draggable';
import { getPlayerBoxSxFromState } from '../../../helpers/styles';
import { EssenceBar } from '../../../components/essenceBar';
import { BlocksDirection, definePlayerStateByGameState, sortByDirection } from '../../../helpers/player';
import { useCardDimension } from '../../../components/card/hooks/useCardDimension';
import { roomItems, useTrackLocation } from '../../room/hooks/useTrackLocation';
import { DisplayMode } from '../player.types';
import { HandCompact } from './hand/components/handCompact';
import { HandExpanded } from './hand/components/handExpanded';
import { HandType, useResolveHand } from './hand/hooks/useResolveHand';
import { Arsenal } from '../allPlayers/arsenal';
import { getCurrentPlayer } from '../../game/gameContextHandler/utils/player';
import { SkipActionButton } from '../../../components/skipActionButton';
import { isEmpty } from '../../../helpers/arrays';

export const OtherPlayer: React.FC<Props> = ({
  player,
  blocksDirection,
  onCardShow,
  size,
  onDroppedToPlayer,
  applyCardMenuOptions,
}) => {
  const { id, cardsOnTable, activeRadiances, roleCard, playerName, essences } = player;
  const { gameState, isDraggable, isAllowedToDrag, idlePlayer } = React.useContext(GameContext);
  const { handType } = useResolveHand({ playerId: id });
  const extendedSize = NORMAL_TO_EXTENDED_SIZE[size];

  const { dimension: cardDimension } = useCardDimension({ size: extendedSize });
  const sx = initSx(cardDimension, blocksDirection, roleCard.isOpen);
  const role = (
    <Box key={`role-${playerName}`} sx={sx.role}>
      <Box key={`role-${playerName}-inner-box`} onClick={() => (roleCard.isOpen ? onCardShow(roleCard) : undefined)}>
        <Card {...cardToComponent(roleCard, extendedSize)} customDisplayType={'showFullSizeImageAndName'} />
      </Box>
      {idlePlayer === player.id && <SkipActionButton displayMode={DisplayMode.COMPACT} idlePlayer={player} />}
    </Box>
  );

  const arsenal = (
    <Box key={`arsenal-${playerName}`} sx={sx.arsenalOuterBlock}>
      <Arsenal
        playerId={id}
        size={extendedSize}
        cardsOnTable={cardsOnTable}
        blocksDirection={
          blocksDirection === BlocksDirection.LEFT_TO_RIGHT
            ? BlocksDirection.RIGHT_TO_LEFT
            : BlocksDirection.LEFT_TO_RIGHT
        }
        isDraggable={isAllowedToDrag(getCurrentPlayer(gameState).id) && isDraggable(id)}
        onCardClick={onCardShow}
        cardsHaveActiveAnimation={handType === HandType.INTERACTIVE}
        displayType={'compact'}
      />
    </Box>
  );

  const radiances = activeRadiances.map((card) => (
    <Box key={`player-${playerName}-radiance-${card.name}`} sx={sx.radiance} onClick={() => onCardShow(card)}>
      <Draggable draggableData={{ dragSource: DragSource.APPLIED_RADIANCE, fromPlayerId: id, cards: [card] }}>
        <CardIdentified
          id={card.id}
          {...cardToComponent(card, extendedSize, undefined, undefined)}
          hasActiveAnimation={handType === HandType.INTERACTIVE}
        />
      </Draggable>
    </Box>
  ));

  const resolveLayout = (elements: ReactElement[], label: string, styles: object) => (
    <Box key={`${playerName}-${label}-row`} sx={styles}>
      {sortByDirection(elements, blocksDirection)}
    </Box>
  );

  const { ref: radiancesRef } = useTrackLocation({ items: [roomItems.playerRadiances(id)] });
  const boxedRadiances = (
    <Box key={`radiances-${playerName}`} ref={radiancesRef} sx={sx.radianceBox}>
      {radiances}
    </Box>
  );

  const essencesComponent = (sxStyles: SxProps) => (
    <Box key={`essences-${playerName}`} sx={sxStyles}>
      <EssenceBar
        playerId={id}
        disperserLocation={BLOCKS_DIRECTION_TO_DISPERSER_LOCATION[blocksDirection]}
        count={essences}
      />
    </Box>
  );

  const playerInfoBox = (
    <Box key={`player-info-box-${playerName}`} sx={sx.compactPlayerInfoBox}>
      {essencesComponent(sx.essenceBarCompact)}
      {role}
      <Box sx={sx.nameCompact}>{<Name playerName={playerName} withTooltip />}</Box>
      <HandCompact player={player} applyCardMenuOptions={applyCardMenuOptions} />
    </Box>
  );

  const gridBlocks = <React.Fragment>{resolveLayout([arsenal, playerInfoBox], 'grid-box', sx.gridBox)}</React.Fragment>;

  const { ref: otherPlayerRef } = useTrackLocation({
    items: [roomItems.playerArsenal(id), roomItems.playerHand(id)],
  });

  const playerState = definePlayerStateByGameState(id, gameState);
  const playerBoxSx = getPlayerBoxSxFromState(playerState, DisplayMode.OTHER_PLAYER_COMPACT, true);

  const onDroppedItem = (item: DraggedCardData) => onDroppedToPlayer(otherPlayerRef, item, id);
  const canDrop = (item: DraggedCardData): boolean => canApplyToOtherPlayer(item, gameState);

  const innerBox = (
    <Box key={`main-box-${playerName}`} sx={sx.wrapperBox}>
      <Box ref={otherPlayerRef} sx={{ ...sx.innerBoxPlayer, ...playerBoxSx }}>
        <Droppable playerId={id} applyRuleGroup={ApplyRuleGroup.ALL} onItemDropped={onDroppedItem} canDrop={canDrop}>
          {gridBlocks}
        </Droppable>
      </Box>
      <HandExpanded player={player} sx={{ ...playerBoxSx }} isShown={isEmpty(applyCardMenuOptions)} />
    </Box>
  );
  return resolveLayout([innerBox, boxedRadiances], 'inner-layout', sx.outerBox);
};
