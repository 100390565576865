import React from 'react';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { mainTheme } from '../../themes/mainTheme';
import { Router } from './router';
import { SnackbarProvider } from 'notistack';
import SnackbarNotification from '../../components/snackbarNotification/snackbarNotification';
import { wrap, WrapperWithProps } from '../../helpers/wrappers';
import { BrowserRouter } from 'react-router-dom';
import { AppContextProvider } from '../context';
import { ApiContextProvider } from './api';
import { MainThemeAudioProvider } from './mainThemeAudio';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SocialLogin } from '@capgo/capacitor-social-login';
import { GOOGLE_CLIENT_ID, GOOGLE_CLIENT_ID_FOR_IOS } from '../../constants/login';

export const App: React.FC = () => {
  const queryClient = new QueryClient();

  void SocialLogin.initialize({
    google: {
      iOSClientId: GOOGLE_CLIENT_ID_FOR_IOS,
      webClientId: GOOGLE_CLIENT_ID,
    },
  });

  const wrappers: WrapperWithProps[] = [
    [ThemeProvider, { theme: mainTheme }],
    [
      SnackbarProvider,
      {
        Components: {
          success: SnackbarNotification,
          info: SnackbarNotification,
          warning: SnackbarNotification,
          error: SnackbarNotification,
        },
        maxSnack: 5,
      },
    ],
    [BrowserRouter, []],
    [AppContextProvider, []],
    [ApiContextProvider, []],
    [MainThemeAudioProvider, []],
    [QueryClientProvider, { client: queryClient }],
  ];

  const rootComponent = (
    <>
      <CssBaseline />
      <Router />
    </>
  );

  return wrap(rootComponent, wrappers);
};
