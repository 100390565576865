import { ProjectColors } from '../../themes/mainTheme';

export const sx = {
  circle: {
    position: 'absolute',
    circle: {
      strokeWidth: '2.5px',
    },
    top: '-5px',
    color: `${ProjectColors.PINK_LOGO}`,
  },
};
