import React from 'react';
import { Menu } from './menu';
import { Logo } from './logo';
import styles from './mobileHeader.module.css';
import cn from 'classnames';
import { isPortraitOrientation } from '../../../../helpers/utils';

export const MobileHeader: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <div
      className={cn(styles.container, { [styles.isPortraitMenu]: isPortraitOrientation() })}
      data-testid="mobile-header"
    >
      <Logo data-testid="mobile-menu-logo-open" isOpen={isMenuOpen} toggleMenu={toggleMenu} isVisible={!isMenuOpen} />
      <Menu data-testid="mobile-menu" isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
    </div>
  );
};
