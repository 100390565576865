import { UseDomeEffect } from './useDomeEffect.types';
import { GameContext } from '../../../../game/gameContext';
import {
  getAttackingPlayerId,
  getCurrentPlayer,
  getPlayerAppliedDome,
} from '../../../../game/gameContextHandler/utils/player';
import { last } from '../../../../../helpers/arrays';
import { cardsAndActionTypesToActions, toInProgressAction } from '../../../../game/utils/turnActions';
import { ATTACKING_NEBULA_CARDS, CardNames } from '../../../cardTypes';
import { IN_PROGRESS } from '../../../../game/gameTypes';
import React from 'react';
import {
  filterIgnoredDefendingActions,
  isDomeCheckFinished,
  isDomeCheckInProgress,
  isOneOfActionsActive,
} from '../../../../game/rules/rules';
import { useRollQuantumDiceAction } from '../../../../game/actions/hooks/useRollQuantumDiceAction';
import { LogContext } from '../../../../game/logContextProvider';

export const useDomeEffect: UseDomeEffect = () => {
  const { gameState, addTurnAction } = React.useContext(GameContext);
  const { setNotification } = React.useContext(LogContext);
  const { rollQuantumDice } = useRollQuantumDiceAction();

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    if (currentPlayer.id !== gameState.activeDefendingPlayer) return;
    if (currentPlayer.id === gameState.activePlayer && currentPlayer.id === getAttackingPlayerId(gameState)) return;

    const lastAttackingAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;
    if (lastAttackingAction === undefined) return;

    const actions = cardsAndActionTypesToActions(ATTACKING_NEBULA_CARDS, [IN_PROGRESS]);
    if (!actions.includes(lastAttackingAction)) return;

    if (getPlayerAppliedDome(currentPlayer) === undefined) return;
    if (isOneOfActionsActive(gameState, [isDomeCheckInProgress, isDomeCheckFinished])) return;

    addTurnAction({ player: currentPlayer.id, action: toInProgressAction(CardNames.DOME) });
    rollQuantumDice();
    void setNotification({ type: 'warning', text: 'Quantum dice was rolled for Dome check' });
  };
};
