import React from 'react';
import { UseRollQuantumDiceAction } from './useRollQuantumDiceAction.types';
import { GameContext } from '../../../gameContext';

export const useRollQuantumDiceAction: UseRollQuantumDiceAction = () => {
  const { rollQuantumDice, withUpdatingState } = React.useContext(GameContext);

  return {
    rollQuantumDice: withUpdatingState(rollQuantumDice),
  };
};
