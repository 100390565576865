import React from 'react';
import { Props } from './cardPictogram.types';
import { Tooltip } from '@mui/material';
import { useCardSelection } from '../hooks/useCardSelection';
import { ImagePath } from '../../../assets/assets';
import { roomItems, useTrackLocation } from '../../../features/room/hooks/useTrackLocation';
import { Identity } from '../../../helpers/arrays';
import { Card } from '../card';
import { SizeExtended } from '../../../helpers/sizes';
import { ProjectColors } from '../../../themes/mainTheme';

export const CardPictogram: React.FC<Props & Identity> = (props) => {
  const { isOpen, imagePath, content, onClick, dragProps, selectorProps, id } = props;
  const { handleCardClick } = useCardSelection({ dragProps, selectorProps });
  const { ref } = useTrackLocation({ items: [roomItems.card(id)] });

  const onPictogramClick = (event: React.MouseEvent) => {
    handleCardClick(event);
    onClick();
  };

  return (
    <Tooltip title={content.name} disableInteractive placement="top" ref={ref}>
      <div onClick={(event) => onPictogramClick(event)}>
        <Card
          isOpen={isOpen}
          size={SizeExtended.XXS}
          imagePath={isOpen ? imagePath : ImagePath.noCardPlaceholder}
          backCoverImagePath={ImagePath.roleCardCoverLarge}
          content={content}
          backgroundColor={ProjectColors.ROLE_CARD}
          rotation={0}
          customDisplayType={'showImageOnly'}
        />
      </div>
    </Tooltip>
  );
};
