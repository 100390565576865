import React from 'react';
import { TermlyLogo } from '../../components/termlyLogo';
import { CookieTable } from './cookieTable';
import { Policy } from '../../features/policy';

export const CookiePolicy: React.FC = () => (
  <Policy title="COOKIE POLICY" lastUpdatedAt="February 22, 2025" provider={<TermlyLogo />}>
    <p>
      This Cookie Policy explains how deltaration.com (&quot;
      <strong>Company</strong>&quot;, &quot;<strong>we</strong>&quot;, &quot;
      <strong>us</strong>&quot;, and &quot;<strong>our</strong>&quot;) uses cookies and similar technologies to
      recognize you when you visit our website at <Link text={deltarationUrl} url={deltarationUrl} /> (&quot;
      <strong>Website</strong>&quot;). It explains what these technologies are and why we use them, as well as your
      rights to control our use of them.
    </p>
    <h2>What are cookies?</h2>
    <p>
      Cookies are small data files that are placed on your computer or mobile device when you visit a website. Cookies
      are widely used by website owners in order to make their websites work, or to work more efficiently, as well as to
      provide reporting information.
    </p>
    <p>
      Cookies set by the website owner (in this case, deltaration.com) are called &quot;first-party cookies.&quot;
      Cookies set by parties other than the website owner are called &quot;third-party cookies.&quot; Third-party
      cookies enable third-party features or functionality to be provided on or through the website (e.g., advertising,
      interactive content, and analytics). The parties that set these third-party cookies can recognize your computer
      both when it visits the website in question and also when it visits certain other websites.
    </p>
    <h2>Why do we use cookies?</h2>
    <p>
      We use first- and third-party cookies for several reasons. Some cookies are required for technical reasons in
      order for our Website to operate, and we refer to these as &quot;essential&quot; or &quot;strictly necessary&quot;
      cookies. Other cookies also enable us to track and target the interests of our users to enhance the experience on
      our Online Properties. Third parties serve cookies through our Website for advertising, analytics, and other
      purposes. This is described in more detail below.
    </p>
    <h2>How can I control cookies?</h2>
    <p>
      You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting
      your preferences in the Cookie Consent Manager. The Cookie Consent Manager allows you to select which categories
      of cookies you accept or reject. Essential cookies cannot be rejected as they are strictly necessary to provide
      you with services.
    </p>
    <p>
      The Cookie Consent Manager can be found in the notification banner and on our Website. If you choose to reject
      cookies, you may still use our Website though your access to some functionality and areas of our Website may be
      restricted. You may also set or amend your web browser controls to accept or refuse cookies.
    </p>
    <p>
      The specific types of first- and third-party cookies served through our Website and the purposes they perform are
      described in the table below (please note that the specific cookies served may vary depending on the specific
      Online Properties you visit)
    </p>
    <h3>
      <u>Essential website cookies:</u>
    </h3>
    <p>
      These cookies are strictly necessary to provide you with services available through our Website and to use some of
      its features, such as access to secure areas.
    </p>
    <CookieTable
      name="cf_clearance"
      purpose="Cloudflare clearance cookie stores the proof of challenge passed. It is used to no longer issue a challenge if present. It is required to reach an origin server."
      provider=".deltaration.com"
      service={{
        name: 'Cloudflare',
        url: 'https://www.cloudflare.com/privacypolicy/',
      }}
      type="http_cookie"
      expires="11 months 30 days"
    />
    <h3>
      <u>Performance and functionality cookies:</u>
    </h3>
    <p>
      These cookies are used to enhance the performance and functionality of our Website but are non-essential to their
      use. However, without these cookies, certain functionality (like videos) may become unavailable.
    </p>
    <CookieTable
      name="__Secure-ROLLOUT_TOKEN"
      purpose="is used by YouTube to manage the phased rollout of new features and updates"
      provider=".youtube.com"
      service={{
        name: 'YouTube',
        url: 'https://policies.google.com/privacy',
      }}
      type="server_cookie"
      expires="5 months 27 days"
    />
    <CookieTable
      name="VISITOR_PRIVACY_METADATA"
      purpose="to store the user's cookie consent state for the current domain"
      provider=".youtube.com"
      service={{
        name: 'YouTube',
        url: 'https://policies.google.com/privacy',
      }}
      type="server_cookie"
      expires="5 months 27 days"
    />
    <h3>
      <u>Analytics and customization cookies:</u>
    </h3>
    <p>
      These cookies collect information that is used either in aggregate form to help us understand how our Website is
      being used or how effective our marketing campaigns are, or to help us customize our Website for you.
    </p>
    <CookieTable
      name="s7"
      purpose="Gather data regarding site usage and user behavior on the website."
      provider="deltaration.com"
      service={{ name: 'Adobe Analytics' }}
      type="html_local_storage"
      expires="persistent"
    />
    <CookieTable
      name="NID"
      purpose="Set by Google to set a unique user ID to remember user preferences. Persistent cookie that stays for 182 days"
      provider=".google.com"
      service={{
        name: 'Google',
        url: 'https://business.safety.google/privacy/',
      }}
      type="server_cookie"
      expires="6 months"
    />
    <h3>
      <u>Advertising cookies:</u>
    </h3>
    <p>
      These cookies are used to make advertising messages more relevant to you. They perform functions like preventing
      the same ad from continuously reappearing, ensuring that ads are properly displayed for advertisers, and in some
      cases selecting advertisements that are based on your interests.
    </p>
    <CookieTable
      name="VISITOR_INFO1_LIVE"
      purpose={youtubeAdvertisingCookiePurpose}
      provider=".youtube.com"
      service={{
        name: 'YouTube',
        url: 'https://business.safety.google/privacy/',
      }}
      type="server_cookie"
      expires="5 months 27 days"
    />
    <CookieTable
      name="YSC"
      purpose={youtubeAdvertisingCookiePurpose}
      provider=".youtube.com"
      service={{
        name: 'YouTube',
        url: 'https://business.safety.google/privacy/',
      }}
      type="server_cookie"
      expires="session"
    />
    <h2>How can I control cookies on my browser?</h2>
    <p>
      As the means by which you can refuse cookies through your web browser controls vary from browser to browser, you
      should visit your browser&apos;s help menu for more information. The following is information about how to manage
      cookies on the most popular browsers:
    </p>
    <ul>
      <li>
        <Link text="Chrome" url="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies" />
      </li>
      <li>
        <Link
          text="Internet Explorer"
          url="https://support.microsoft.com/en-us/windows/manage-cookies-in-microsoft-edge-view-allow-block-delete-and-use-168dab11-0753-043d-7c16-ede5947fc64d"
        />
      </li>
      <li>
        <Link
          text="Firefox"
          url="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
        />
      </li>
      <li>
        <Link text="Safari" url="https://support.apple.com/en-ie/guide/safari/sfri11471/mac" />
      </li>
      <li>
        <Link
          text="Edge"
          url="https://support.microsoft.com/en-us/microsoft-edge/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
        />
      </li>
      <li>
        <Link text="Opera" url="https://help.opera.com/en/latest/web-preferences/" />
      </li>
    </ul>
    <p>
      In addition, most advertising networks offer you a way to opt out of targeted advertising. If you would like to
      find out more information, please visit:
    </p>
    <ul>
      <li>
        <Link text="Digital Advertising Alliance" url="https://optout.aboutads.info/?c=2&lang=EN" />
      </li>
      <li>
        <Link text="Digital Advertising Alliance of Canada" url="https://youradchoices.ca/" />
      </li>
      <li>
        <Link text="European Interactive Digital Advertising Alliance" url="https://www.youronlinechoices.com/" />
      </li>
    </ul>
    <h2>What about other tracking technologies, like web beacons?</h2>
    <p>
      Cookies are not the only way to recognize or track visitors to a website. We may use other, similar technologies
      from time to time, like web beacons (sometimes called &quot;tracking pixels&quot; or &quot;clear gifs&quot;).
      These are tiny graphics files that contain a unique identifier that enables us to recognize when someone has
      visited our Website or opened an email including them. This allows us, for example, to monitor the traffic
      patterns of users from one page within a website to another, to deliver or communicate with cookies, to understand
      whether you have come to the website from an online advertisement displayed on a third-party website, to improve
      site performance, and to measure the success of email marketing campaigns. In many instances, these technologies
      are reliant on cookies to function properly, and so declining cookies will impair their functioning.
    </p>
    <h2>Do you use Flash cookies or Local Shared Objects?</h2>
    <p>
      Websites may also use so-called &quot;Flash Cookies&quot; (also known as Local Shared Objects or &quot;LSOs&quot;)
      to, among other things, collect and store information about your use of our services, fraud prevention, and for
      other site operations.
    </p>
    <p>
      If you do not want Flash Cookies stored on your computer, you can adjust the settings of your Flash player to
      block Flash Cookies storage using the tools contained in the{' '}
      <Link
        text="Website Storage Settings Panel"
        url="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
      />
      . You can also control Flash Cookies by going to the{' '}
      <Link
        text="Global Storage Settings Panel"
        url="https://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
      />{' '}
      and following the instructions (which may include instructions that explain, for example, how to delete existing
      Flash Cookies (referred to &quot;information&quot; on the Macromedia site), how to prevent Flash LSOs from being
      placed on your computer without your being asked, and (for Flash Player 8 and later) how to block Flash Cookies
      that are not being delivered by the operator of the page you are on at the time).
    </p>
    <p>
      Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may reduce or impede
      the functionality of some Flash applications, including, potentially, Flash applications used in connection with
      our services or online content.
    </p>
    <h2>Do you serve targeted advertising?</h2>
    <p>
      Third parties may serve cookies on your computer or mobile device to serve advertising through our Website. These
      companies may use information about your visits to this and other websites in order to provide relevant
      advertisements about goods and services that you may be interested in. They may also employ technology that is
      used to measure the effectiveness of advertisements. They can accomplish this by using cookies or web beacons to
      collect information about your visits to this and other sites in order to provide relevant advertisements about
      goods and services of potential interest to you. The information collected through this process does not enable us
      or them to identify your name, contact details, or other details that directly identify you unless you choose to
      provide these.
    </p>
    <h2>How often will you update this Cookie Policy?</h2>
    <p>
      We may update this Cookie Policy from time to time in order to reflect, for example, changes to the cookies we use
      or for other operational, legal, or regulatory reasons. Please therefore revisit this Cookie Policy regularly to
      stay informed about our use of cookies and related technologies.
    </p>
    <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>
    <h2>Where can I get further information?</h2>
    <p>
      If you have any questions about our use of cookies or other technologies, please contact us at:{' '}
      <a href={`mailto:${deltarationEmail}`}>{deltarationEmail}</a>
    </p>
  </Policy>
);

const youtubeAdvertisingCookiePurpose =
  'YouTube is a Google-owned platform for hosting and sharing videos. YouTube collects user data through' +
  ' videos embedded in websites, which is aggregated with profile data from other Google services in order' +
  ' to display targeted advertising to web visitors across a broad range of their own and other websites.' +
  ' Used by Google in combination with SID to verify Google user account and most recent login time.';

type LinkProps = {
  text: string;
  url: string;
};

const Link: React.FC<LinkProps> = ({ url, text }) => (
  <a href={url} target="_blank" rel="noreferrer">
    {text}
  </a>
);

const deltarationUrl = window.location.origin;
const deltarationEmail = 'contact.deltaration@gmail.com';
