import { SizeExtended } from '../../../../helpers/sizes';

export type UseCardDimensionResult = {
  dimension: number;
};

export type UseCardDimensionProps = {
  size: SizeExtended;
};

export type UseCardDimensionType = (props: UseCardDimensionProps) => UseCardDimensionResult;

export const MAX_SIZE_CARD_DIMENSION = 403;
export const MAX_WIDTH_MODIFIER = 0.26;
