import React from 'react';

export type UseTrackLocationProps = {
  items: string[];
};

export type UseTrackLocationResult = {
  ref: React.RefObject<HTMLDivElement>;
};

export type UseTrackLocation = (props: UseTrackLocationProps) => UseTrackLocationResult;

export const roomItems = {
  drawDeck: 'drawDeck',
  discardPile: 'discardPile',
  engagementZone: 'engagementZone',
  playerArsenal: (playerId: string) => `player-${playerId}-arsenal`,
  playerHand: (playerId: string) => `player-${playerId}-hand`,
  playerRadiances: (playerId: string) => `player-${playerId}-radiances`,
  playerEssenceContainer: (playerId: string) => `player-${playerId}-essence-container`,
  card: (cardId: string) => `card-${cardId}`,
} as const;
