import React from 'react';
import { Props } from './cardLContent.types';
import { PlayingCard, RoleCard } from '../../../../../card/cardTypes';
import { SizeExtended } from '../../../../../../helpers/sizes';
import { cardToComponent } from '../../../../../../helpers/transformers';
import { Box } from '@mui/material';
import { Card } from '../../../../../../components/card';
import { sx } from './cardLContent.styles';

export const CardLContent: React.FC<Props> = ({ card }) => {
  const cardProps = (cardData: PlayingCard | RoleCard) => cardToComponent(cardData, SizeExtended.L);

  return (
    <Box sx={sx.outerBox}>
      <Card {...cardProps(card)} />
    </Box>
  );
};
