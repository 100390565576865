import { FinishDefendingType, UseDefendingActions } from './useDefendingActions.types';
import { hasOneElement, isEmpty, last, removeElement } from '../../../../../helpers/arrays';
import {
  cardsToInProgressCanceledActionsMap,
  cardsToInProgressImpactedActionsMap,
  toImpactedAction,
  toImpactedTurnAction,
} from '../../../utils/turnActions';
import { CardNames, INDIVIDUAL_ESSENCE_LOSS_CARDS, MASS_ACTION_CARDS } from '../../../../card/cardTypes';
import { Action } from '../../../gameTypes';
import { filterIgnoredDefendingActions } from '../../../rules/rules';
import { DroppableGroup } from '../useDragAndDrop';
import { getCurrentPlayer, getPlayer } from '../../utils/player';
import { Log, LogContext } from '../../../logContextProvider';
import React from 'react';
import { Player } from '../../../../player/player.types';

export const useDefendingActions: UseDefendingActions = ({ gameState, addTurnAction, enableDragAndDrop }) => {
  const { createLog } = React.useContext(LogContext);

  const finishDefending = (skipLog?: boolean) => {
    const currentPlayerDefendingOrderedIndex = gameState.defendingPlayerIds.indexOf(gameState.activeDefendingPlayer);
    const defendingPlayer = getPlayer(gameState.allPlayers, gameState.activeDefendingPlayer);

    if (currentPlayerDefendingOrderedIndex < 0) {
      throw Error('Player is not in a correct ordered list position');
    }

    if (currentPlayerDefendingOrderedIndex < gameState.defendingPlayerIds.length - 1) {
      gameState.activeDefendingPlayer = gameState.defendingPlayerIds[currentPlayerDefendingOrderedIndex + 1];
      removeElement(gameState.defendingPlayerIds, (player) => player === defendingPlayer.id);
      enableDragAndDrop(gameState.activeDefendingPlayer, DroppableGroup.DRAGGABLE, gameState.activeDefendingPlayer);
    } else {
      gameState.activeDefendingPlayer = '';
      gameState.defendingPlayerIds = [];
      enableDragAndDrop(gameState.activePlayer, DroppableGroup.ALL, gameState.activePlayer);
      if (!skipLog) leaveDefendingFinishedLog(defendingPlayer);
    }

    gameState.diceRollAllowedFor = '';
    gameState.previousDefendingPlayer = '';
  };

  const leaveDefendingFinishedLog = (currentPlayer: Player) => {
    const log: Log = { type: 'info', text: `Defending finished` };
    addTurnAction({ player: currentPlayer.id, action: 'create log', logs: [log] });
    void createLog(log);
  };

  const forceDefendingFinish = () => {
    if (isEmpty(gameState.defendingPlayerIds)) return;

    const currentPlayer = getCurrentPlayer(gameState);
    leaveDefendingFinishedLog(currentPlayer);
    gameState.activeDefendingPlayer = '';
    gameState.defendingPlayerIds = [];
    enableDragAndDrop(gameState.activePlayer, DroppableGroup.ALL, gameState.activePlayer);
  };

  const performFinishDefending = (type: FinishDefendingType) => {
    const currentPlayer = getCurrentPlayer(gameState);
    const defendedLog: Log = {
      type: 'success',
      text: `${[currentPlayer.playerName]}: defended successfully`,
    };

    switch (type) {
      case 'successfulDefending':
        addTurnAction({ player: currentPlayer.id, action: defineFinishDefendingAction(), logs: [defendedLog] });
        void createLog(defendedLog);
        finishDefending();
        break;
      case 'withEssenceLoss':
        addTurnAction({ player: currentPlayer.id, action: defineFinishDefendingAction() });
        finishDefending();
        break;
      case 'withRoleReveal':
        addTurnAction({ player: currentPlayer.id, action: toImpactedAction(CardNames.SPY) });
        finishDefending(true);
        break;
      case 'withCardTeleported': {
        addTurnAction(toImpactedTurnAction(gameState, CardNames.TELEPORT));
        break;
      }
      case 'withCardDisintegrated': {
        addTurnAction(toImpactedTurnAction(gameState, CardNames.DISINTEGRATOR));
        break;
      }
      case 'withConversion': {
        addTurnAction(toImpactedTurnAction(gameState, CardNames.CONVERSIONER));
        finishDefending(currentPlayer.id === gameState.activePlayer);
        break;
      }
    }
  };

  const defineFinishDefendingAction = (): Action => {
    const lastTurnAction = last(filterIgnoredDefendingActions(gameState.turnActions))?.action;

    if (lastTurnAction === undefined) {
      throw Error('Turn action is required');
    }

    const individualAppliedActions = cardsToInProgressImpactedActionsMap([
      ...INDIVIDUAL_ESSENCE_LOSS_CARDS,
      CardNames.SPY,
      CardNames.CONVERSIONER,
    ]);

    const individualImpactedAction = individualAppliedActions.get(lastTurnAction);
    if (individualImpactedAction) {
      return individualImpactedAction;
    }

    const cardMovementAppliedActions = cardsToInProgressCanceledActionsMap([
      CardNames.TELEPORT,
      CardNames.DISINTEGRATOR,
    ]);

    const cardMovementCanceledAction = cardMovementAppliedActions.get(lastTurnAction);
    if (cardMovementCanceledAction) {
      return cardMovementCanceledAction;
    }

    const massAppliedActions = cardsToInProgressImpactedActionsMap(MASS_ACTION_CARDS);

    const massImpactedAction = massAppliedActions.get(lastTurnAction);
    if (
      hasOneElement(gameState.defendingPlayerIds) &&
      gameState.defendingPlayerIds.includes(gameState.activeDefendingPlayer) &&
      massImpactedAction
    ) {
      return massImpactedAction;
    }

    return 'defendingFinished';
  };

  const reflectAttackingCard = () => {
    if (gameState.activePlayer === gameState.activeDefendingPlayer) {
      gameState.activeDefendingPlayer = gameState.previousDefendingPlayer;
      gameState.defendingPlayerIds.shift();
      gameState.defendingPlayerIds.unshift(gameState.previousDefendingPlayer);
      gameState.previousDefendingPlayer = '';
    } else {
      gameState.previousDefendingPlayer = gameState.activeDefendingPlayer;
      gameState.activeDefendingPlayer = gameState.activePlayer;
      gameState.defendingPlayerIds.shift();
      gameState.defendingPlayerIds.unshift(gameState.activePlayer);
    }

    enableDragAndDrop(gameState.activeDefendingPlayer, DroppableGroup.ALL, gameState.activeDefendingPlayer);

    const log: Log = {
      type: 'warning',
      text: `${getCurrentPlayer(gameState).playerName}: Card reflected back to ${
        gameState.allPlayers.filter((player) => player.id === gameState.activeDefendingPlayer)[0].playerName
      }`,
    };
    addTurnAction({ player: getCurrentPlayer(gameState).id, action: 'create log', logs: [log] });
    void createLog(log);
  };

  return {
    finishDefending: finishDefending,
    performFinishDefending: performFinishDefending,
    reflectAttackingCard: reflectAttackingCard,
    forceDefendingFinish: forceDefendingFinish,
  };
};
