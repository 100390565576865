import { Box, IconButton, Typography } from '@mui/material';
import React from 'react';
import { NumberOfPlayers } from '../../../../game/gameTypes';
import { EXTRA_SMALL_SIZE_HEIGHT } from '../../../../../constants/components';
import { useWindowSize } from '../../../../../helpers/utils';
import { initSx } from './useNumberOfPlayers.styles';
import { UseNumberOfPlayers } from './useNumberOfPlayers.types';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

export const useNumberOfPlayers: UseNumberOfPlayers = () => {
  const [numberOfPlayers, setNumberOfPlayers] = React.useState(NumberOfPlayers.THREE.valueOf());
  const window = useWindowSize();
  const extraNarrowScreen = window.width <= EXTRA_SMALL_SIZE_HEIGHT;

  const sx = initSx(extraNarrowScreen);

  const containerSections = Object.values(NumberOfPlayers)
    .filter((value) => Number.isInteger(value))
    .map((number) => (
      <Box
        key={number}
        sx={number === numberOfPlayers ? sx.containerSectionActive : sx.containerSectionInactive}
        onClick={() => setNumberOfPlayers(number as number)}
      >
        {number}
      </Box>
    ));

  const numberOfPlayersBlock = () => {
    return (
      <Box sx={sx.outerBlock}>
        <Typography sx={sx.label}>Players</Typography>
        <Box sx={sx.containerOuterBlock}>
          <Box sx={sx.container}>
            {!extraNarrowScreen && (
              <IconButton
                sx={sx.changeNumberOfPlayersButton}
                disabled={numberOfPlayers <= NumberOfPlayers.THREE}
                onClick={() => setNumberOfPlayers((prevState) => prevState - 1)}
              >
                <KeyboardArrowLeft />
              </IconButton>
            )}
            {containerSections}
            {!extraNarrowScreen && (
              <IconButton
                sx={sx.changeNumberOfPlayersButton}
                disabled={numberOfPlayers >= NumberOfPlayers.EIGHT}
                onClick={() => setNumberOfPlayers((prevState) => prevState + 1)}
              >
                <KeyboardArrowRight />
              </IconButton>
            )}
          </Box>
        </Box>
      </Box>
    );
  };

  return {
    numberOfPlayersBlock: numberOfPlayersBlock,
    numberOfPlayers: numberOfPlayers,
  };
};
