import React from 'react';
import { Props } from './accessCodeLoginModal.types';
import KeyIcon from '@mui/icons-material/Key';
import { Modal } from '../../../../components/modal';
import { ApiAuthRequest, AuthProvider } from '../../../auth/authTypes';
import { PROFILE_STORAGE_KEY } from '../../../../constants/login';
import AuthApi from '../../../auth/authApi';
import { useSnackbar } from 'notistack';
import { AppContext } from '../../../../global/context/appContext';
import style from './accessCodeLoginModal.module.css';
import { InputField } from '../../../../components/textField';
import { Button } from '@mui/material';

export const AccessCodeLoginModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const { refreshProfile } = React.useContext(AppContext);
  const [code, setCode] = React.useState('');
  const { enqueueSnackbar } = useSnackbar();

  const isButtonDisabled = code === '';

  const authenticate = async () => {
    try {
      const request: ApiAuthRequest = {
        code: code,
        authProvider: AuthProvider.ACCESS_CODE,
      };

      const response = await AuthApi.auth(request);
      localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(response));
      refreshProfile();
      enqueueSnackbar('Successfully signed in with Access code', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Failed to login with Access code', { variant: 'error' });
    } finally {
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className={style.title}>
        <KeyIcon data-testid="access-code-login-modal-icon" className={style.icon} />
        <h2 data-testid="access-code-login-modal-title" className={style.text}>
          Login with access code
        </h2>
      </div>
      <div className={style.input}>
        <InputField
          data-testid="access-code-login-modal-input"
          label={'access code'}
          onChange={(event: string) => setCode(event)}
        />
      </div>
      <div className={style.buttonBox}>
        <Button
          data-testid="access-code-login-modal-login-button"
          className={style.button}
          variant="contained"
          disabled={isButtonDisabled}
          onClick={authenticate}
        >
          Login
        </Button>
      </div>
    </Modal>
  );
};
