import React from 'react';
import { Props } from './widgetsPanel.types';
import { Box, IconButton } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import { AudioSettingsWidget } from '../../../global/app/widgets/audioSettingsWidget';
import { settingsWidgetStyles } from '../../../helpers/styles';
import styles from './mobileGameWidgetsPanel.module.css';
import cn from 'classnames';
import { isPortraitOrientation } from '../../../helpers/utils';

export const MobileGameWidgetsPanel: React.FC<Props> = ({ widgets }) => {
  const audioWidgetKey = 'audio-settings-widget';
  const permanentWidgets: React.ReactElement[] = [
    <AudioSettingsWidget key={audioWidgetKey} data-testid={audioWidgetKey} />,
  ];
  const allWidgets = widgets ? [...permanentWidgets, ...widgets] : permanentWidgets;
  const [isOpen, setIsOpen] = React.useState(false);

  const widgetsLogo = (
    <IconButton data-testid="mobile-game-widgets-panel-logo" onClick={() => setIsOpen(true)}>
      <SettingsIcon sx={settingsWidgetStyles()} />
    </IconButton>
  );
  const closeLogo = (
    <IconButton data-testid="mobile-game-widgets-panel-close-logo" onClick={() => setIsOpen(false)}>
      <CloseIcon sx={settingsWidgetStyles()} />
    </IconButton>
  );

  return (
    <Box
      data-testid="mobile-game-widgets-panel"
      className={cn(styles.panel, { [styles.isPortrait]: isPortraitOrientation() })}
    >
      {isOpen ? closeLogo : widgetsLogo}
      {isOpen && allWidgets}
    </Box>
  );
};
