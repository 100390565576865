import React from 'react';
import { Props } from './room.types';
import { Box } from '@mui/material';
import { PlayingCard, RoleCard } from '../card/cardTypes';
import { NORMAL_TO_EXTENDED_SIZE } from '../../helpers/sizes';
import { IS_PLAYER_TRAY_OPEN_STORAGE_KEY } from '../../constants/components';
import { DrawDeck } from '../../components/drawDeck';
import { DiscardPile } from '../../components/discardPile';
import { CurrentPlayerDrawer } from '../player/currentPlayer/currentPlayerDrawer';
import { sxInit } from './room.styles';
import { CardDrawer } from '../../components/cardDrawer';
import { AppContext } from '../../global/context/appContext';
import { GameContext } from '../game/gameContext';
import { useCurrentSize } from '../card/hooks/useCurrentSize';
import { getAllowedCardsInHandCount } from '../game/rules/rules';
import { FinishedGameModal } from './finishedGameModal';
import { getCurrentPlayer } from '../game/gameContextHandler/utils/player';
import { isFlagOn, setFlag } from '../../helpers/featureFlags';
import { EssenceHuntContext } from './essenceHuntContextProvider/essenceHuntContextProvider.types';
import { EssenceHuntModal } from './essenceHuntModal';
import { isTrayAllowed } from '../../helpers/styles';
import { SECOND_IN_MILLISECONDS } from '../../constants/time';
import { AnimationContext } from '../game/animationContextProvider';
import { Logo } from '../../components/logo/logo';
import { isEmpty } from '../game/utils/gameState';
import { useAnimateCards } from '../animation/useAnimateCards';
import { CardDragLayer } from '../card/components/cardDragLayer';
import { RoomLayout } from './roomLayout';
import { useCardEffects } from '../card/hooks/effects';
import { useSetCSSStyleCardDimension } from './hooks/useSetCSSStyleCardDimension';
import { LogJournal } from '../../components/logJournal';
import { usePingIdlePlayer } from '../game/actions/hooks/usePingIdlePlayer';
import { usePredictionApi } from '../prediction/usePredictionApi';
import { BackgroundOverlay } from '../../components/backgroundOverlay';

export const Room: React.FC<Props> = () => {
  const { windowSize, isMobile } = React.useContext(AppContext);
  const { gameState, isUiBlocked } = React.useContext(GameContext);

  const { isEssenceHuntModalOpen } = React.useContext(EssenceHuntContext);
  useAnimateCards();
  usePingIdlePlayer();
  useSetCSSStyleCardDimension();
  usePredictionApi();

  const currentPlayer = getCurrentPlayer(gameState);
  if (currentPlayer === undefined) {
    throw Error('Cannot load room without current player.');
  }

  const runCardEffects = useCardEffects();
  React.useEffect(runCardEffects, [gameState.turnActions.length, gameState.createdAt]);

  const currentSize = useCurrentSize();

  const [playerDrawerOpen, setPlayerDrawerOpen] = React.useState(
    !isFlagOn(IS_PLAYER_TRAY_OPEN_STORAGE_KEY) || !isTrayAllowed(windowSize)
  );
  const [finishedGameModalOpen, setFinishedGameModalOpen] = React.useState(false);
  const [cardToShow, setCardToShow] = React.useState<PlayingCard | RoleCard>(currentPlayer.roleCard);
  const [isCardShown, setIsCardShown] = React.useState(false);
  const closeCardDrawer = () => setIsCardShown(false);
  const openCardDrawer = () => setIsCardShown(true);

  const setAndOpenCard = (card: PlayingCard | RoleCard) => {
    if (isCardShown && card === cardToShow) {
      closeCardDrawer();
      return;
    }

    if (isCardShown) {
      closeCardDrawer();
      setTimeout(() => {
        setCardToShow(card);
        openCardDrawer();
      }, SECOND_IN_MILLISECONDS / 4);
      return;
    }

    setCardToShow(card);
    openCardDrawer();
  };

  const setPlayerDrawerState = (isOpen: boolean) => {
    setFlag(IS_PLAYER_TRAY_OPEN_STORAGE_KEY, !isOpen);
    setPlayerDrawerOpen(isOpen);
  };

  React.useEffect(() => {
    if (!isTrayAllowed(windowSize)) {
      setPlayerDrawerOpen(true);
    }
  }, [windowSize]);

  React.useEffect(() => {
    if (gameState.isFinished) {
      setFinishedGameModalOpen(true);
    }
  }, [gameState.isFinished]);

  const currentPlayerDrawer = (
    <CurrentPlayerDrawer
      isOpen={playerDrawerOpen}
      onOpen={() => setPlayerDrawerState(true)}
      onClose={() => setPlayerDrawerState(false)}
      playerProps={{
        player: currentPlayer,
        allowedCardInHandCount: getAllowedCardsInHandCount(currentPlayer, gameState.playersOrdered),
        onCardShow: setAndOpenCard,
      }}
      closeCardDrawer={closeCardDrawer}
    />
  );

  const extendedSize = NORMAL_TO_EXTENDED_SIZE[currentSize];
  const sx = sxInit(windowSize, isMobile);

  const {
    animatingCards,
    animatedCardsMovement,
    animatingEssences,
    animatedPulsatingCard,
    animatingPredictionTargets,
  } = React.useContext(AnimationContext);

  const roomElements = () => (
    <>
      <LogJournal size={currentSize} />
      {animatingCards}
      {animatedCardsMovement}
      {animatingEssences}
      {animatedPulsatingCard}
      {animatingPredictionTargets}
      <DrawDeck size={currentSize} />
      <DiscardPile size={currentSize} playingCards={gameState.cardsInDiscardPile} />
      <FinishedGameModal isOpen={finishedGameModalOpen} onClose={() => setFinishedGameModalOpen(false)} />
      <EssenceHuntModal isOpen={isEssenceHuntModalOpen} onCardClick={setAndOpenCard} />
      <CardDrawer card={cardToShow} isOpen={isCardShown} openDrawer={openCardDrawer} closeDrawer={closeCardDrawer} />
      <RoomLayout onCardShow={setAndOpenCard} closeCardDrawer={closeCardDrawer} />
      {currentPlayerDrawer}
      <CardDragLayer size={extendedSize} />
    </>
  );

  return (
    <Box sx={isUiBlocked ? { ...sx.mainBox, pointerEvents: 'none' } : sx.mainBox}>
      <BackgroundOverlay />
      {isEmpty(gameState) ? (
        <Box sx={sx.loader}>
          <Logo withLoader />
        </Box>
      ) : (
        roomElements()
      )}
    </Box>
  );
};
