import { zIndexes } from '../../../../../constants/components';
import { ProjectColors } from '../../../../../themes/mainTheme';
import { SxStyles } from '../../../../../helpers/styles';

export const sx: SxStyles = {
  drawer: {
    maxWidth: '98%',
    width: 'fit-content',
    maxHeight: '98%',
    height: 'fit-content',
    ml: 'auto',
    mr: 'auto',
    pt: 0,
    pb: 2,
    px: 2,
    backgroundColor: ProjectColors.ACTION_DRAWER_MOBILE,
    backgroundImage: 'none',
    border: `1px solid ${ProjectColors.PURPLE}`,
    borderBottom: 0,
  },
  drawerBox: {
    zIndex: zIndexes.mobileActionDrawer,
  },
  nameBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    pb: 1,
    borderBottom: `1px solid ${ProjectColors.PURPLE}`,
  },
  name: {
    textAlign: 'center',
  },
  contentBox: { pt: 0.5, overflowY: 'auto', display: 'flex', justifyContent: 'center' },
};
