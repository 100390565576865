import React from 'react';
import { Props } from './engagementZone.types';
import { Box, Typography } from '@mui/material';
import { Droppable } from '../../../../../components/droppable';
import { CardGroup } from '../../../../../components/cardGroup';
import { DragSource } from '../../../../../components/draggable';
import { GameContext } from '../../../../game/gameContext';
import { initSx } from './engagementZone.styles';
import { useCardDimension } from '../../../../../components/card/hooks/useCardDimension';
import { useEngagementZoneDrop } from '../../../hooks/useEngagementZoneDrop';
import { roomItems, useTrackLocation } from '../../../hooks/useTrackLocation';
import { QuantumDice } from '../../../../../components/quantumDice';
import { getOtherPlayers } from '../../../../game/gameContextHandler/utils/player';
import { PlayingCard } from '../../../../card/cardTypes';
import { CardLContent } from '../actionDrawer/cardLContent';
import { ActionDrawerContext } from '../../contexts/actionDrawerContext/actionDrawerContext';

export const EngagementZone: React.FC<Props> = ({ size, setDroppedItem }) => {
  const { gameState } = React.useContext(GameContext);
  const { setContent, setName } = React.useContext(ActionDrawerContext);
  const { onDrop, canDrop } = useEngagementZoneDrop({ setDroppedCard: setDroppedItem });
  const { dimension: cardDimension } = useCardDimension({ size: size });

  const otherPlayersLength = getOtherPlayers(gameState).length;
  const sx = initSx(cardDimension, otherPlayersLength);

  const onCardShow = (openedCard: PlayingCard) => {
    setContent(<CardLContent card={openedCard} />);
    setName('Card from engagement zone');
  };

  const { ref } = useTrackLocation({ items: [roomItems.engagementZone] });
  return (
    <Box sx={sx.engagementZone}>
      <Box sx={sx.quantumDiceBox}>
        <QuantumDice />
      </Box>
      <Box ref={ref} sx={sx.outerBox}>
        <Droppable onItemDropped={onDrop} canDrop={canDrop}>
          <Box sx={sx.textAndCardsBox}>
            {gameState.cardsInPlayingArea.length === 0 && <Typography sx={sx.placeholder}>Engagement Zone</Typography>}
            <Box sx={sx.cardGroup}>
              <Box>
                <CardGroup
                  isActive={false}
                  cards={gameState.cardsInPlayingArea}
                  size={size}
                  placeholdersCount={0}
                  onCardClick={onCardShow}
                  sourceType={DragSource.PLAYING_AREA}
                  selectorType={undefined}
                />
              </Box>
            </Box>
          </Box>
        </Droppable>
      </Box>
    </Box>
  );
};
