import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import { ImagePath } from '../../../assets/assets';
import { sx } from './header.styles';
import { Tooltip } from '@mui/material';
import { ABOUT_US_ROUTE, PROFILE_ROUTE, ROOT_ROUTE, RULES_ROUTE } from '../../../constants/routes';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/appContext';
import { useDropdownMenu } from '../../../helpers/hooks/useDropdownMenu';
import { GoogleLoginButton } from '../../../features/menu/googleButton';
import { SignOutButton } from '../../../features/menu/signOutButton';
import { useGameModal } from '../../../features/menu/hooks/useGameModal';
import { BackToGameButton } from '../../../features/game/backToGameButton';
import { InstallButton } from '../../../features/menu/installButton';
import { DiscordLoginButton } from '../../../features/menu/discordLoginButton';
import styles from './header.module.css';
import { LogoText } from '../../../components/logoText/logoText';
import { useAccessCodeLogin } from '../../../features/menu/accessCodeLogin/useAccessCodeLogin';

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const { profile } = React.useContext(AppContext);
  const { openModal, modal } = useGameModal();

  const gameButtonId = 'open-game-button';
  const { menu: gameMenu, openMenu: openGameMenu } = useDropdownMenu({
    openingButtonId: gameButtonId,
    items: [
      { type: 'data', name: 'Create new game', action: () => openModal('Create') },
      { type: 'data', name: 'Join game', action: () => openModal('Join') },
      { type: 'element', element: <BackToGameButton /> },
    ],
  });

  const gameButtonAndMenu = (
    <div>
      <Tooltip title={'Login to play'} placement={'bottom'} disableHoverListener={Boolean(profile)}>
        <span>
          <Button id={gameButtonId} onClick={openGameMenu} sx={sx.button} disabled={!profile}>
            Game
          </Button>
        </span>
      </Tooltip>
      {gameMenu}
    </div>
  );

  const { accessCodeLoginModal, accessCodeLoginButton } = useAccessCodeLogin();
  const loginButtonId = 'login-button';
  const { menu: loginMenu, openMenu: openLoginMenu } = useDropdownMenu({
    openingButtonId: loginButtonId,
    items: [
      { type: 'element', element: <GoogleLoginButton /> },
      { type: 'element', element: <DiscordLoginButton /> },
      { type: 'element', element: accessCodeLoginButton },
    ],
  });

  const loginButtonAndMenu = (
    <div>
      <Button id={loginButtonId} onClick={openLoginMenu} variant="outlined" color="inherit" sx={sx.button}>
        Login
      </Button>
      {loginMenu}
    </div>
  );

  const profileButtonId = 'profile-button';
  const { menu: profileMenu, openMenu: openProfileMenu } = useDropdownMenu({
    openingButtonId: profileButtonId,
    items: [
      { type: 'data', name: 'Open', action: () => navigate(PROFILE_ROUTE) },
      { type: 'element', element: <SignOutButton /> },
    ],
    useDivider: true,
  });

  const profileButtonAndMenu = (
    <div>
      <Button id={profileButtonId} onClick={openProfileMenu} variant="outlined" color="inherit" sx={sx.button}>
        Profile
      </Button>
      {profileMenu}
    </div>
  );

  return (
    <div className={styles.outerContainer}>
      <AppBar position="static">
        <Toolbar variant="dense" sx={sx.toolbar}>
          <div className={styles.innerContainer} onClick={() => navigate(ROOT_ROUTE)}>
            <div className={styles.logoContainer}>
              <div className={styles.logo}>
                <img alt="deltaration-logo" src={ImagePath.logoOutline} className={styles.logoImage} />
              </div>
            </div>
            <div className={styles.logoTextContainer}>
              <LogoText color={'white'} />
            </div>
          </div>
          <div className={styles.innerContainer}>
            <InstallButton />
            {gameButtonAndMenu}
            <Button onClick={() => navigate(RULES_ROUTE)} sx={sx.button}>
              Rules
            </Button>
            <Button onClick={() => navigate(ABOUT_US_ROUTE)} sx={sx.button}>
              About us
            </Button>
            {profile ? profileButtonAndMenu : loginButtonAndMenu}
          </div>
        </Toolbar>
      </AppBar>
      <div className={styles.modalContainer}>{modal()}</div>
      {accessCodeLoginModal}
    </div>
  );
};
