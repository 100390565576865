import { UseGameApi } from './useGameApi.types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { ApiContext } from '../../../../global/app/api/apiContext';
import {
  CommandExecutionApiData,
  CreatedGameData,
  CreateGameData,
  FinishGameRequestApiData,
  JoinedGameData,
  JoinGameData,
  StartGameRequestApiData,
} from '../../gameTypes';
import { GAME_ID_KEY, GAME_TOKEN_STORAGE_KEY } from '../../../../constants/delarationApi';
import { AppContext } from '../../../../global/context/appContext';
import { useNavigate, useParams } from 'react-router-dom';
import { runAsyncCatching } from '../../../../helpers/utils';
import { ACTIVE_GAME_ROUTE } from '../../../../constants/routes';

export const useGameApi: UseGameApi = () => {
  const { profile, socketClient } = React.useContext(AppContext);
  const queryClient = useQueryClient();
  const { gameApi } = React.useContext(ApiContext);

  const navigate = useNavigate();
  const openGame = (gameId: string) => navigate(ACTIVE_GAME_ROUTE(gameId));

  const { id: urlParamGameId } = useParams();
  const gameId = urlParamGameId ?? localStorage.getItem(GAME_ID_KEY);

  const setCachedGameId = (gameIdToSet: string | null) => {
    gameIdToSet ? localStorage.setItem(GAME_ID_KEY, gameIdToSet) : localStorage.removeItem(GAME_ID_KEY);
  };

  const fetchGame = async () => {
    if (!gameId) return null;
    const game = await runAsyncCatching(() => gameApi.getGame(gameId));

    if (!game) {
      setCachedGameId(null);
      return null;
    }

    if (game.finishedAt) setCachedGameId(null);

    return game;
  };

  const gameQueryKey = (keyGameId: string | null) => ['game', keyGameId];
  const { data: loadedGame } = useQuery({
    queryKey: gameQueryKey(gameId),
    queryFn: fetchGame,
    enabled: !!gameId,
  });

  const invalidateQuery = (keyGameId: string) => {
    void queryClient.invalidateQueries({ queryKey: gameQueryKey(keyGameId) });
  };

  const onCreateSuccess = (data: CreatedGameData) => {
    setCachedGameId(data.uniqueId);
    localStorage.setItem(GAME_TOKEN_STORAGE_KEY, data.gameToken);

    socketClient.socket.emit('createGame', data.uniqueId, profile!.playerId, 0); // TODO add botsNumber logic
    openGame(data.uniqueId);
    invalidateQuery(data.uniqueId);
  };

  const createGameMutation = useMutation({ mutationFn: gameApi.createGame.bind(gameApi), onSuccess: onCreateSuccess });
  const createGame = (data: CreateGameData) => createGameMutation.mutate(data);

  const onJoinSuccess = async (data: JoinedGameData, variables: JoinGameData) => {
    setCachedGameId(variables.gameId);
    localStorage.setItem(GAME_TOKEN_STORAGE_KEY, data.gameToken);

    socketClient.socket.emit('joinGame', variables.gameId, profile!.playerId);
    openGame(variables.gameId);
    invalidateQuery(variables.gameId);
  };

  const joinGameMutation = useMutation({ mutationFn: gameApi.joinGame.bind(gameApi), onSuccess: onJoinSuccess });
  const joinGame = (data: JoinGameData) => joinGameMutation.mutate(data);

  const onStartSuccess = (_: CommandExecutionApiData, variables: StartGameRequestApiData) => {
    socketClient.socket.emit('startGame', variables.gameId);
    invalidateQuery(variables.gameId);
  };

  const startGameMutation = useMutation({ mutationFn: gameApi.startGame.bind(gameApi), onSuccess: onStartSuccess });
  const startGame = (data: StartGameRequestApiData) => startGameMutation.mutate(data);

  const onFinishSuccess = (_: CommandExecutionApiData, variables: FinishGameRequestApiData) => {
    setCachedGameId(null);

    socketClient.socket.emit('finishGame', variables.gameId);
    invalidateQuery(variables.gameId);
  };

  const finishGameMutation = useMutation({ mutationFn: gameApi.finishGame.bind(gameApi), onSuccess: onFinishSuccess });
  const finishGame = (data: FinishGameRequestApiData) => finishGameMutation.mutate(data);

  return {
    game: loadedGame ?? undefined,
    createGame,
    joinGame,
    startGame,
    finishGame,
    invalidateGameCache: invalidateQuery,
  };
};
