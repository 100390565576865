import React from 'react';
import { UseDisorientationEffect } from './useDisorientationEffect.types';
import { GameContext } from '../../../../game/gameContext';
import { isEmpty } from '../../../../../helpers/arrays';
import { CardNames } from '../../../cardTypes';
import { toInProgressAction } from '../../../../game/utils/turnActions';
import { getCurrentPlayer } from '../../../../game/gameContextHandler/utils/player';
import { LogContext } from '../../../../game/logContextProvider';

export const useDisorientationEffect: UseDisorientationEffect = () => {
  const { gameState, addTurnAction, startDisorientationCheck } = React.useContext(GameContext);
  const { setNotification } = React.useContext(LogContext);

  return () => {
    const currentPlayer = getCurrentPlayer(gameState);
    if (currentPlayer.id !== gameState.activePlayer) return;
    if (isEmpty(currentPlayer.activeRadiances.filter((card) => card.name === CardNames.DISORIENTATION))) {
      return;
    }

    if (!isEmpty(gameState.turnActions)) return;

    addTurnAction({ player: currentPlayer.id, action: toInProgressAction(CardNames.DISORIENTATION) });
    startDisorientationCheck();
    void setNotification({
      type: 'warning',
      text: 'Quantum dice was rolled for Disorientation check',
    });
  };
};
