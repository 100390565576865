import { ProjectColors } from '../../themes/mainTheme';
import { containerFontStyle, getActiveActionButtonSx, SxStyles } from '../../helpers/styles';
import { ACTION_BUTTON_SX, CONTAINER_OUTER_COMMON_SX, CONTAINER_INNER_COMMON_SX } from '../../constants/styles';
import { DisplayMode } from '../../features/player/player.types';

export const sx: SxStyles = {
  outerBox: {
    display: 'flex',
    justifyContent: 'center',
    width: '220px',
  },
  container: {
    ...CONTAINER_INNER_COMMON_SX,
    fontSize: containerFontStyle,
  },
  containerSectionActive: {
    ...CONTAINER_OUTER_COMMON_SX,
    background: `linear-gradient(90deg, transparent, ${ProjectColors.BLUE_QUANTUM_DICE}, transparent)`,
    color: ProjectColors.WHITE,
  },
  containerSectionInactive: {
    ...CONTAINER_OUTER_COMMON_SX,
    background: `transparent`,
    color: ProjectColors.GREY,
  },
  retryButton: {
    mx: 0.5,
    py: 0,
    px: 1,
    width: '40px',
    ...ACTION_BUTTON_SX,
    ...getActiveActionButtonSx(4, DisplayMode.COMPACT, ProjectColors.PURPLE),
  },
  retryIcon: { fontSize: { xs: '0.6rem', sm: '0.8rem', md: '0.9rem', lg: '1rem' } },
  containerMotionBlockSx: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
