import { ProjectColors } from '../../../../themes/mainTheme';
import { DisplayMode } from '../../player.types';
import { MAX_WIDTH_MODIFIER } from '../../../../components/card/hooks/useCardDimension';
import { PLAYER_STATE_BOX_SX } from '../../../../constants/styles';

const defaultSx = { p: 1, border: `1px solid ${ProjectColors.BLACK}` };

export const sxInit = (displayMode: DisplayMode, cardDimension: number) => {
  const isCompact = displayMode === DisplayMode.COMPACT;

  const containerBoxSx = { height: cardDimension * MAX_WIDTH_MODIFIER };
  const mainActionSx = {
    my: isCompact ? '0' : '1%',
    width: isCompact ? '35%' : '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  };
  const innerBoxWidth = isCompact ? cardDimension * 4 : cardDimension * 7.5;
  const roleSx = isCompact ? {} : { flexDirection: 'column', cursor: 'pointer' };
  const arsenalSx = { width: isCompact ? '62%' : '59%' };

  return {
    outerBox: { width: 'fit-content', display: 'flex' },
    radiance: { p: 1, pb: 0, cursor: 'pointer' },
    innerBox: {
      ...defaultSx,
      width: innerBoxWidth,
    },
    stateBox: {
      ...PLAYER_STATE_BOX_SX,
      zIndex: -1,
    },
    row: {
      info: {
        box: { display: 'flex', mb: 1, justifyContent: 'space-between', width: '100%' },
        mainAction: mainActionSx,
        mainActionCompact: mainActionSx,
        roleCompact: { ...roleSx, display: 'flex', justifyContent: 'space-around', mx: '2%', width: '100%' },
        role: {
          ...roleSx,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mx: '2%',
          width: { md: '8%', lg: '12%' },
        },
        arsenal: { ...defaultSx, m: 'auto', py: 1, px: 0, ...arsenalSx },
      },
      hand: {
        withinLimit: defaultSx,
        overLimit: { ...defaultSx, backgroundColor: `${ProjectColors.PINK}` },
      },
      containerBox: containerBoxSx,
    },
  } as const;
};
