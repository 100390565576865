import React from 'react';
import { PlayingCard } from '../../card/cardTypes';

export type Props = {
  children?: React.ReactNode;
};

export type PlayerCardGroup = 'cardsInHand' | 'cardsOnTable' | 'activeRadiances';
export const playerCardGroups: PlayerCardGroup[] = ['cardsInHand', 'cardsOnTable', 'activeRadiances'];

export type GroupedCards = Map<PlayerCardGroup, PlayingCard[]>;

export const CARD_AMOUNT_FIRST_SERVE = 5;

export const DICE_VALUES = [0, 1, 2, 3, 4, 5, 6] as const;
export type DiceValue = (typeof DICE_VALUES)[number];

export const ACTIVE_DICE_VALUES: DiceValue[] = DICE_VALUES.slice(1);
export const PASSING_DICE_VALUES: DiceValue[] = [1, 6];
export const SQUANDER_DICE_MODIFIER = 3;
