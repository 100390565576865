import { COOKIE_POLICY_ROUTE, PRIVACY_POLICY_ROUTE } from '../../../constants/routes';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './policyLinks.module.css';

export const PolicyLinks: React.FC = () => (
  <div className={styles.container}>
    <PolicyLink link={PRIVACY_POLICY_ROUTE} text={'Privacy policy'} />
    |
    <PolicyLink link={COOKIE_POLICY_ROUTE} text={'Cookie policy'} />
  </div>
);

type PolicyLinkProps = {
  link: string;
  text: string;
};

export const PolicyLink: React.FC<PolicyLinkProps> = ({ link, text }) => {
  const navigate = useNavigate();

  return (
    <div className={styles.link} onClick={() => navigate(link)}>
      {text}
    </div>
  );
};
