import React from 'react';
import { Props } from './engagementZone.types';
import { QuantumDice } from '../../../components/quantumDice';
import { Droppable } from '../../../components/droppable';
import { CardGroup } from '../../../components/cardGroup';
import { DragSource } from '../../../components/draggable';
import { roomItems, useTrackLocation } from '../hooks/useTrackLocation';
import { useEngagementZoneDrop } from '../hooks/useEngagementZoneDrop';
import styles from './engagementZone.module.css';
import { GameContext } from '../../game/gameContext';
import { useCurrentSize } from '../../card/hooks/useCurrentSize';
import { NORMAL_TO_EXTENDED_SIZE } from '../../../helpers/sizes';

export const EngagementZone: React.FC<Props> = ({ onCardShow, setDroppedItem }) => {
  const { gameState } = React.useContext(GameContext);
  const { onDrop, canDrop } = useEngagementZoneDrop({ setDroppedCard: setDroppedItem });
  const { ref: engagementZoneRef } = useTrackLocation({ items: [roomItems.engagementZone] });
  const size = NORMAL_TO_EXTENDED_SIZE[useCurrentSize()];

  return (
    <div className={styles.container}>
      <div className={styles.text}>
        <div ref={engagementZoneRef} className={styles.playableSpace}>
          <div className={styles.quantumDice}>
            <QuantumDice />
          </div>
          <Droppable onItemDropped={onDrop} canDrop={canDrop}>
            <div className={styles.cardsAndPlaceholderContainer}>
              <p className={styles.placeholder}>Engagement Zone</p>
              <div className={styles.cards}>
                <div>
                  <CardGroup
                    isActive={false}
                    cards={gameState.cardsInPlayingArea}
                    size={size}
                    placeholdersCount={0}
                    onCardClick={onCardShow}
                    sourceType={DragSource.PLAYING_AREA}
                    selectorType={undefined}
                  />
                </div>
              </div>
            </div>
          </Droppable>
        </div>
      </div>
    </div>
  );
};
