import React from 'react';
import { Props } from './game.types';
import { Room } from '../../features/room';
import { PreGameBlock } from '../../components/preGameBlock/preGameBlock';
import { Typography } from '@mui/material';
import { GameContext } from '../../features/game/gameContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { wrap, WrapperWithProps } from '../../helpers/wrappers';
import { EssenceHuntContextProvider } from '../../features/room/essenceHuntContextProvider/essenceHuntContextProvider';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';
import { AppContext } from '../../global/context/appContext';
import { useGameId } from '../../features/game/gameContextHandler/hooks/useGameId';
import { isMobile } from 'react-device-detect';
import { RoomMobile } from '../../features/room/mobile';
import { TouchBackend } from 'react-dnd-touch-backend';
import { ActionDrawerContextProvider } from '../../features/room/mobile/contexts/actionDrawerContext';
import { isPortraitOrientation } from '../../helpers/utils';
import { AnimatedStarsBlock } from '../../components/animatedStarsBlock';
import type { TouchBackendOptions } from 'react-dnd-touch-backend/src/interfaces';
import { SECOND_IN_MILLISECONDS } from '../../constants/time';
import { NotificationWidget } from '../../features/room/notificationWidget';
import { LogWidget } from '../../features/room/logWidget';
import { RuleBookWidget } from '../../features/room/ruleBookWidget';
import { OnboardingWidget } from '../../features/room/onboardingWidget';
import { GameWidgetsPanel, MobileGameWidgetsPanel } from '../../features/menu/widgetsPanel';
import { OnboardingWidgetMobile } from '../../features/room/onboardingWidget/mobile';
import styles from './game.module.css';

export const Game: React.FC<Props> = () => {
  const { profile, isMobile: isMobileContext } = React.useContext(AppContext);

  const { isReadyToPlay, expectedPlayersCount, playerDataList } = React.useContext(GameContext);

  const gameId = useGameId();
  const [preGameNotification, setPreGameNotification] = React.useState('');

  React.useEffect(() => {
    if (expectedPlayersCount === undefined || !profile) {
      setPreGameNotification('Oops! Something went wrong!');
      return;
    }

    if (
      playerDataList.length < expectedPlayersCount &&
      !playerDataList.map((data) => data.id).includes(profile.playerId)
    ) {
      setPreGameNotification('Join now and prove to be the strongest in Deltaration: Menu -> Game -> Join Game');
      return;
    }

    if (playerDataList.length === expectedPlayersCount) {
      setPreGameNotification(
        'No nebulas for you here - this Deltaration game is full. Create new Game: Menu -> Game -> Create new Game'
      );
      return;
    }

    if (playerDataList.length < expectedPlayersCount) {
      setPreGameNotification(
        'Great - you are part of this Deltaration Game! Click on Game Id below to copy it, share with other players'
      );
      return;
    }

    setPreGameNotification('This Deltaration game is out of your reach!');
  }, [playerDataList.length]);

  const pendingGameData = (
    <PreGameBlock
      gameId={gameId}
      playerDataList={playerDataList}
      numberOfPlayers={expectedPlayersCount}
      notification={preGameNotification}
    />
  );

  const touchOption: Partial<TouchBackendOptions> = {
    enableTouchEvents: true,
    delayTouchStart: SECOND_IN_MILLISECONDS * 0.1,
  };

  const roomMobileWrappers: WrapperWithProps[] = [
    [DndProvider, { backend: TouchBackend, options: touchOption }],
    [ActionDrawerContextProvider, {}],
  ];

  const pleaseRotateComponent = (
    <div className={styles.pleaseRotateBox}>
      <ScreenRotationIcon />
      <Typography>Please rotate your phone</Typography>
    </div>
  );

  const WidgetsComponent = isMobileContext ? MobileGameWidgetsPanel : GameWidgetsPanel;

  const widgets = React.useMemo(() => {
    return (
      <div className={styles.widgetsContainer}>
        <WidgetsComponent
          widgets={[
            <NotificationWidget key="notification-widget" />,
            <LogWidget key="log-widget" />,
            <RuleBookWidget key="rule-book-widget" />,
            isMobileContext ? (
              <OnboardingWidgetMobile key="onboarding-widget" />
            ) : (
              <OnboardingWidget key="onboarding-widget" />
            ),
          ]}
        />
      </div>
    );
  }, [isMobileContext]);

  const resolveRoom = isMobile ? (
    isPortraitOrientation() ? (
      pleaseRotateComponent
    ) : (
      wrap(<RoomMobile />, roomMobileWrappers)
    )
  ) : (
    <DndProvider backend={HTML5Backend}>
      <Room />
    </DndProvider>
  );

  const wrappers: WrapperWithProps[] = [[EssenceHuntContextProvider, {}]];

  return isReadyToPlay ? (
    <>
      {widgets}
      {wrap(
        <div>
          {resolveRoom}
          <AnimatedStarsBlock />
        </div>,
        wrappers
      )}
    </>
  ) : (
    <>
      {pendingGameData}
      <AnimatedStarsBlock />
    </>
  );
};
