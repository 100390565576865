import React from 'react';
import { UseDrawCardAction } from './useDrawCardAction.types';
import { getCurrentPlayer } from '../../../gameContextHandler/utils/player';
import { filterUndefined, popRandom, shuffle } from '../../../../../helpers/arrays';
import { openCard, openCards } from '../../../../card/cardTransformers';
import { roomItems } from '../../../../room/hooks/useTrackLocation';
import { AnimationContext } from '../../../animationContextProvider';
import { LocationTrackerContext } from '../../../locationTrackerContextProvider';
import { PlayingCard } from '../../../../card/cardTypes';
import { EssenceUpdateType, GameContext } from '../../../gameContext';
import { DEFAULT_DRAW_CARD_AMOUNT } from '../../../gameTypes';
import { useChangeEssenceCount } from '../../../gameContextHandler/hooks/useChangeEssenceCount';
import { Log, LogContext } from '../../../logContextProvider';
import { useSoundEffects } from '../useSoundEffects';

export const useDrawCardFromDeck: UseDrawCardAction = () => {
  const { gameState, withUpdatingState, addTurnAction } = React.useContext(GameContext);
  const { createLog, setNotification } = React.useContext(LogContext);
  const { changeEssenceCount } = useChangeEssenceCount({ gameState: gameState, addTurnAction: addTurnAction });
  const { getItemLocation } = React.useContext(LocationTrackerContext);
  const { queueMovingCardsProps } = React.useContext(AnimationContext);
  const { applyDrawCardSound } = useSoundEffects({ gameState: gameState });

  const drawCardFromDeck = () => {
    const currentPlayer = getCurrentPlayer(gameState);
    const poppedResult = popRandom(gameState.cardsInDeck);

    const drawnCard = openCard(poppedResult.popped);

    const curPlayerLocation = getItemLocation(roomItems.playerHand(currentPlayer.id));
    const stops = filterUndefined([getItemLocation(roomItems.drawDeck), curPlayerLocation]);

    const runAfter = withUpdatingState(() => finishDrawCardFromDeck(drawnCard));

    queueMovingCardsProps([
      {
        stops: stops,
        cards: openCards([drawnCard]),
        runAfter: runAfter,
        runWith: applyDrawCardSound,
        movementType: 'draw',
      },
    ]);
  };

  const createNewDeckFromDiscardPile = () => {
    shuffle(gameState.cardsInDiscardPile);
    gameState.cardsInDeck = gameState.cardsInDiscardPile;
    gameState.cardsInDiscardPile = [];

    const log: Log = { type: 'info', text: `Discard pile cards are moved back to cards in deck` };
    addTurnAction({ player: gameState.activePlayer, action: 'create log', logs: [log] });
    void createLog(log);
  };

  const finishDrawCardFromDeck = (drawnCard: PlayingCard) => {
    const currentPlayer = getCurrentPlayer(gameState);
    currentPlayer.cardsInHand.unshift(drawnCard);

    const log: Log = { type: 'info', text: `${currentPlayer.playerName}: card drawn` };
    addTurnAction({ player: currentPlayer.id, action: 'drawCard', cardIds: [drawnCard.id], logs: [log] });
    void createLog(log);

    gameState.drawCardAmount -= 1;
    gameState.cardsInDeck = gameState.cardsInDeck.filter((cardInDeck) => cardInDeck.id !== drawnCard.id);
    if (gameState.cardsInDeck.length === 0) {
      createNewDeckFromDiscardPile();
    }
  };

  const addTwoCardsToDrawAmount = () => {
    const log: Log = { type: 'warning', text: `${getCurrentPlayer(gameState).playerName}: 2 cards draw initiated` };
    addTurnAction({ player: gameState.activePlayer, action: 'create log', logs: [log] });
    void createLog(log);

    void setNotification({ type: 'warning', text: 'Draw 2 more cards from the deck, 1 essence was removed' });
    changeEssenceCount(getCurrentPlayer(gameState).id, EssenceUpdateType.REMOVE_ONE);
    gameState.drawCardAmount += DEFAULT_DRAW_CARD_AMOUNT;
  };

  return {
    drawCardFromDeck: drawCardFromDeck,
    addTwoCardsToDrawAmount: withUpdatingState(addTwoCardsToDrawAmount),
  };
};
