import React from 'react';
import { Props } from './accessCodeLoginButton.types';
import KeyIcon from '@mui/icons-material/Key';
import styles from './accessCodeLoginButton.module.css';
import cn from 'classnames';

export const AccessCodeLoginButton: React.FC<Props> = ({ iconOnly, openModal }) => (
  <div
    data-testid="access-code-login-button"
    onClick={openModal}
    className={cn(styles.button, { [styles.isIconOnly]: iconOnly })}
  >
    <KeyIcon data-testid="access-code-login-button-icon" className={styles.icon} />
    {!iconOnly && <span className={styles.textContainer}>Login with code</span>}
  </div>
);
