import React from 'react';
import { CircularProgress } from '@mui/material';
import { ImagePath } from '../../assets/assets';
import { sx } from './logo.styles';
import { glitchProperties, Props } from './logo.type';
import { useGlitch } from 'react-powerglitch';
import styles from './logo.module.css';
import { LogoText } from '../logoText/logoText';

export const Logo: React.FC<Props> = ({ withLoader }) => {
  const glitch = useGlitch(glitchProperties);

  return (
    <div className={styles.outerContainer}>
      {withLoader && <CircularProgress size={110} sx={sx.circle} />}
      <div className={styles.innerContainer} ref={glitch.ref}>
        <div className={styles.logoContainer}>
          <img src={ImagePath.logoOutline} alt={'logo-outline-loader'} className={styles.logoImage} />
        </div>
        <div className={styles.logoTextContainer}>
          <LogoText color={'purple'} />
        </div>
      </div>
    </div>
  );
};
