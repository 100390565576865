import React from 'react';
import { PROFILE_STORAGE_KEY } from '../../../constants/login';
import cn from 'classnames';
import styles from './googleLoginButton.module.css';
import { FcGoogle } from 'react-icons/fc';
import { Props } from './googleLoginButton.types';
import { SocialLogin } from '@capgo/capacitor-social-login';
import { ApiAuthRequest, AuthProvider } from '../../auth/authTypes';
import AuthApi from '../../auth/authApi';
import { AppContext } from '../../../global/context/appContext';
import { useSnackbar } from 'notistack';

export const GoogleLoginButton: React.FC<Props> = ({ onlyIcon }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { refreshProfile } = React.useContext(AppContext);

  const onClick = async () => {
    const res = await SocialLogin.login({
      provider: 'google',
      options: {},
    });

    try {
      const request: ApiAuthRequest = {
        credential: res.result?.idToken ?? '',
        authProvider: AuthProvider.GOOGLE,
      };

      const response = await AuthApi.auth(request);
      localStorage.setItem(PROFILE_STORAGE_KEY, JSON.stringify(response));
      refreshProfile();
      enqueueSnackbar('Successfully signed in with Google', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Failed to login with Google', { variant: 'error' });
    }
  };

  return (
    <div
      data-testid="google-login-button"
      className={cn(styles.button, { [styles.isIconOnly]: onlyIcon })}
      onClick={onClick}
    >
      <div className={styles.iconContainer}>
        <FcGoogle data-testid="google-login-button-icon" className={styles.icon} />
      </div>
      {!onlyIcon && (
        <div data-testid="google-login-button-text" className={styles.textContainer}>
          <p>Login with google</p>
        </div>
      )}
    </div>
  );
};
