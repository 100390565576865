export enum AuthProvider {
  GOOGLE = 'google',
  DISCORD = 'discord',
  ACCESS_CODE = 'access-code',
}

export type ApiAuthRequest = GoogleAuthRequest | DiscordAuthRequest | AccessCodeAuthRequest;

export type GoogleAuthRequest = {
  credential: string;
  authProvider: AuthProvider;
};

export type DiscordAuthRequest = {
  credential: string;
  authProvider: AuthProvider;
  redirectUrl: string;
};

export type AccessCodeAuthRequest = {
  code: string;
  authProvider: AuthProvider;
};

export type Profile = {
  appToken: string;
  playerId: string;
  playerName?: string;
};
