import React from 'react';
import { getAttackingPlayerId, getCurrentPlayer } from '../../../gameContextHandler/utils/player';
import { Log, LogContext } from '../../../logContextProvider';
import { EssenceUpdateType, GameContext } from '../../../gameContext';
import {
  canDisintegrate,
  canTeleport,
  filterIgnoredDefendingActions,
  getAllowedCardsInHandCount,
  isAnyDomeCheckInProgress,
  isCardsInHandCountWithinLimits,
  isChallengeActive,
  isEssenceHuntInProgress,
  isUnchainedEssenceApplied,
} from '../../../rules/rules';
import { SkipAction, FinishTurn, SkipTurnAction, UseSkipAction } from './useSkipAction.types';
import { Action, CANCELED } from '../../../gameTypes';
import { useDiscardPileAction } from '../useDiscardPileAction';
import { last } from '../../../../../helpers/arrays';
import { isActionForAppliedOrInProgressCard, toImpactedAction } from '../../../utils/turnActions';
import { CardNames, ESSENCE_LOSS_CARDS } from '../../../../card/cardTypes';
import { AnyFunction, withFunc } from '../../../../../helpers/utils';

export const useSkipAction: UseSkipAction = ({ idlePlayer }) => {
  const { createLog } = React.useContext(LogContext);

  const {
    finishTurn,
    performFinishAndLoseEssence,
    performFinishAndRevealRole,
    performFinishAndConvert,
    performFinishAndEnableTeleport,
    performFinishAndEnableDisintegrator,
    performFinishDefending,
    forceFinishChallenge,
    withUpdatingState,
    isDiceRolling,
    gameState,
    addTurnAction,
    changePlayerEssenceCount,
    setIdlePlayer,
  } = React.useContext(GameContext);

  const [lastActiveAction, setLastActiveAction] = React.useState<Action>();
  const { discardPlayerRandomCards } = useDiscardPileAction();

  React.useEffect(() => {
    setLastActiveAction(last(filterIgnoredDefendingActions(gameState.turnActions))?.action);
  }, [gameState.turnActions]);

  const currentPlayer = getCurrentPlayer(gameState);
  const isEssenceLossActionInProgress = isActionForAppliedOrInProgressCard(lastActiveAction, ESSENCE_LOSS_CARDS);
  const isRevealRoleActive = isActionForAppliedOrInProgressCard(lastActiveAction, [CardNames.SPY, CardNames.REVEALER]);
  const isConversionerActionInProgress = isActionForAppliedOrInProgressCard(lastActiveAction, [CardNames.CONVERSIONER]);
  const isChallengeInProgress = isChallengeActive(gameState);
  const isTeleportInProgress = isActionForAppliedOrInProgressCard(lastActiveAction, [CardNames.TELEPORT]);
  const isDisintegratorInProgress = isActionForAppliedOrInProgressCard(lastActiveAction, [CardNames.DISINTEGRATOR]);
  const canFinishDefending = gameState.activeDefendingPlayer === idlePlayer.id;
  const isAttacking = getAttackingPlayerId(gameState) === idlePlayer.id;
  const canFinishAttack = isAttacking && (canTeleport(gameState.turnActions) || canDisintegrate(gameState.turnActions));
  const isHandCardsCountWithinLimits = isCardsInHandCountWithinLimits(
    idlePlayer,
    gameState.playersOrdered,
    gameState.turnActions
  );
  const isUnchangedEssenceApplied = isUnchainedEssenceApplied(gameState);

  const performFinishTurn = () => {
    if (!isHandCardsCountWithinLimits) {
      discardPlayerRandomCards(
        idlePlayer,
        idlePlayer.cardsInHand.length - getAllowedCardsInHandCount(idlePlayer, gameState.playersOrdered)
      );
    }

    finishTurn(false);
  };

  const withForcedAction: <T extends AnyFunction>(fn: T) => T = <T extends AnyFunction>(fn: T): T =>
    withFunc(initiateForcedAction, fn) as T;

  const initiateForcedAction = () => {
    idlePlayer.actionSkips++;
    setIdlePlayer(undefined);

    const log: Log = {
      type: 'error',
      text: `${currentPlayer.playerName}: forced action on idle ${idlePlayer.playerName}`,
    };

    void createLog(log);

    if (isAnyDomeCheckInProgress(gameState)) {
      addTurnAction({
        player: idlePlayer.id,
        appliedTo: idlePlayer.id,
        action: toImpactedAction(CardNames.DOME),
        logs: [log],
      });

      return;
    }

    if (isEssenceHuntInProgress(gameState.turnActions)) {
      addTurnAction({ player: idlePlayer.id, action: `essence hunt ${CANCELED}`, logs: [log] });

      return;
    }

    if (isUnchangedEssenceApplied) {
      addTurnAction({ player: idlePlayer.id, action: toImpactedAction(CardNames.UNCHAINED_ESSENCE), logs: [log] });

      return;
    }

    addTurnAction({ player: currentPlayer.id, action: 'create log', logs: [log] });
  };

  const finishTurnAction = new FinishTurn({
    gameState: gameState,
    isHandCardsCountWithinLimits: isHandCardsCountWithinLimits,
    canFinishAction: true,
    finishTurn: withForcedAction(withUpdatingState(performFinishTurn)),
    player: idlePlayer,
  });
  const finishAndLoseEssenceAction = new SkipTurnAction({
    buttonTooltip: 'Finish defending and remove essence',
    canFinishAction: canFinishDefending && isEssenceLossActionInProgress,
    finishAction: withForcedAction(() => performFinishAndLoseEssence(idlePlayer)),
  });
  const finishAndRevealRoleAction = new SkipTurnAction({
    buttonTooltip: 'Finish defending and reveal role',
    canFinishAction: canFinishDefending && isRevealRoleActive,
    finishAction: withForcedAction(performFinishAndRevealRole),
  });
  const finishAndConvertAction = new SkipTurnAction({
    buttonTooltip: 'Finish defending and convert to Ortegonist',
    canFinishAction: canFinishDefending && isConversionerActionInProgress,
    finishAction: withForcedAction(performFinishAndConvert),
  });
  const finishAndAllowTeleportAction = new SkipTurnAction({
    buttonTooltip: 'Finish defending and allow teleport',
    canFinishAction: canFinishDefending && isTeleportInProgress,
    finishAction: withForcedAction(performFinishAndEnableTeleport),
  });
  const finishAndAllowDisintegratorAction = new SkipTurnAction({
    buttonTooltip: 'Finish defending and allow disintegrator',
    canFinishAction: canFinishDefending && isDisintegratorInProgress,
    finishAction: withForcedAction(performFinishAndEnableDisintegrator),
  });
  const finishChallengeAction = new SkipTurnAction({
    buttonTooltip: 'Finish Challenge',
    canFinishAction: isChallengeInProgress && !isDiceRolling,
    finishAction: withForcedAction(forceFinishChallenge),
  });
  const finishAttack = new SkipTurnAction({
    buttonTooltip: 'Finish attack',
    canFinishAction: canFinishAttack,
    finishAction: withForcedAction(() => performFinishDefending('successfulDefending')),
  });
  const addEssence = new SkipTurnAction({
    buttonTooltip: 'Add essence',
    canFinishAction: isUnchangedEssenceApplied,
    finishAction: withForcedAction(() => changePlayerEssenceCount(idlePlayer.id, EssenceUpdateType.ADD_ONE)),
  });

  const defineSkipAction = (): SkipAction => {
    if (isChallengeInProgress) return finishChallengeAction;
    if (canFinishDefending && isEssenceLossActionInProgress) return finishAndLoseEssenceAction;
    if (canFinishDefending && isRevealRoleActive) return finishAndRevealRoleAction;
    if (canFinishDefending && isConversionerActionInProgress) return finishAndConvertAction;
    if (canFinishDefending && isTeleportInProgress) return finishAndAllowTeleportAction;
    if (canFinishDefending && isDisintegratorInProgress) return finishAndAllowDisintegratorAction;
    if (canFinishAttack) return finishAttack;
    if (isUnchangedEssenceApplied) return addEssence;

    return finishTurnAction;
  };

  return {
    defineSkipAction: defineSkipAction,
  };
};
