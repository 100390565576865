export const GOOGLE_CLIENT_ID = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;
export const GOOGLE_CLIENT_ID_FOR_IOS = `${process.env.REACT_APP_GOOGLE_CLIENT_ID_FOR_IOS}`;
export const PROFILE_STORAGE_KEY = 'profile';

export const DISCORD_CLIENT_ID = `${process.env.REACT_APP_DISCORD_CLIENT_ID}`;
export const DISCORD_REDIRECT_URL = `${process.env.REACT_APP_DISCORD_REDIRECT_URI}`;

export const DISCORD_LOGIN_URL = [
  'https://discord.com/oauth2/authorize',
  `?client_id=${DISCORD_CLIENT_ID}`,
  `&response_type=code`,
  `&redirect_uri=${encodeURIComponent(DISCORD_REDIRECT_URL)}`,
  `&scope=identify`,
].join('');
