import { ProjectColors } from '../../../../../themes/mainTheme';
import { CONTAINER_OUTER_COMMON_SX, CONTAINER_INNER_COMMON_SX } from '../../../../../constants/styles';
import { containerFontStyle } from '../../../../../helpers/styles';

const containerSectionCommonSx = {
  ...CONTAINER_OUTER_COMMON_SX,
  cursor: 'pointer',
  mx: '0.3rem',
  px: '0.5rem',
  fontSize: '1rem',
};

export const initSx = (extraNarrowScreen: boolean) => {
  return {
    containerSectionActive: {
      ...containerSectionCommonSx,
      background: `linear-gradient(90deg, transparent, ${ProjectColors.BLUE_QUANTUM_DICE}, ${ProjectColors.BLUE_QUANTUM_DICE}, transparent)`,
      color: ProjectColors.WHITE,
    },
    containerSectionInactive: {
      ...containerSectionCommonSx,
      background: `transparent`,
      color: ProjectColors.GREY,
    },
    label: {
      textAlign: 'center',
      mt: '0.75rem',
      mb: '0.5rem',
      fontSize: '0.75rem',
    },
    outerBlock: {
      display: 'flex',
      justifyContent: 'space-evenly',
      mt: '1.25rem',
    },
    containerOuterBlock: {
      display: 'flex',
      justifyContent: 'center',
      width: `${extraNarrowScreen ? '220px' : '300px'}`,
    },
    container: {
      ...CONTAINER_INNER_COMMON_SX,
      fontSize: containerFontStyle,
    },
    changeNumberOfPlayersButton: {
      backgroundColor: `${ProjectColors.BLUE_TRANSPARENT}`,
    },
  };
};
