import React from 'react';
import { Props } from './roomMobile.types';
import { Box } from '@mui/material';
import { NORMAL_TO_EXTENDED_SIZE } from '../../../helpers/sizes';
import { AppContext } from '../../../global/context/appContext';
import { GameContext } from '../../game/gameContext';
import { useCurrentSize } from '../../card/hooks/useCurrentSize';
import { FinishedGameModal } from '../finishedGameModal';
import { getCurrentPlayer } from '../../game/gameContextHandler/utils/player';
import { EssenceHuntContext } from '../essenceHuntContextProvider/essenceHuntContextProvider.types';
import { EssenceHuntModalMobile } from '../essenceHuntModal';
import { CurrentPlayerMobile } from '../../player/currentPlayer/mobile';
import { initSx } from './roomMobile.styles';
import { isEmpty } from '../../game/utils/gameState';
import { Logo } from '../../../components/logo/logo';
import { AnimationContext } from '../../game/animationContextProvider';
import { CardDragLayer } from '../../card/components/cardDragLayer';
import { useCardEffects } from '../../card/hooks/effects';
import { ActionDrawer } from './components/actionDrawer';
import { RoomLayout } from './components/roomLayout';
import { useSetCSSStyleCardDimension } from '../hooks/useSetCSSStyleCardDimension';
import { NotificationTicker } from './components/notificationTicker';
import { LogJournal } from '../../../components/logJournal';
import { useAnimateCards } from '../../animation/useAnimateCards';
import { useKeepAwake } from './hooks/useKeepAwake';
import { usePingIdlePlayer } from '../../game/actions/hooks/usePingIdlePlayer';
import { usePredictionApi } from '../../prediction/usePredictionApi';
import { BackgroundOverlay } from '../../../components/backgroundOverlay';

export const RoomMobile: React.FC<Props> = () => {
  const { windowSize } = React.useContext(AppContext);
  const { gameState, isUiBlocked } = React.useContext(GameContext);
  const { isEssenceHuntModalOpen } = React.useContext(EssenceHuntContext);

  const currentPlayer = getCurrentPlayer(gameState);
  if (currentPlayer === undefined) {
    throw Error('Cannot load room without current player.');
  }

  const runCardEffects = useCardEffects();
  React.useEffect(runCardEffects, [gameState.turnActions.length, gameState.createdAt]);

  const currentSize = useCurrentSize();
  useAnimateCards();
  usePingIdlePlayer();
  useKeepAwake();
  useSetCSSStyleCardDimension();
  usePredictionApi();

  const [finishedGameModalOpen, setFinishedGameModalOpen] = React.useState(false);

  React.useEffect(() => {
    if (gameState.isFinished) {
      setFinishedGameModalOpen(true);
    }
  }, [gameState.isFinished]);

  const extendedSize = NORMAL_TO_EXTENDED_SIZE[currentSize];
  const sx = initSx(windowSize);

  const {
    animatingCards,
    animatedCardsMovement,
    animatingEssences,
    animatedPulsatingCard,
    animatingPredictionTargets,
  } = React.useContext(AnimationContext);
  const roomElements = () => (
    <>
      {animatingCards}
      {animatedCardsMovement}
      {animatingEssences}
      {animatedPulsatingCard}
      {animatingPredictionTargets}
      <RoomLayout />
      <NotificationTicker variant={'mobile'} />
      <CurrentPlayerMobile />
      <ActionDrawer />
      <CardDragLayer size={extendedSize} />
      <FinishedGameModal isOpen={finishedGameModalOpen} onClose={() => setFinishedGameModalOpen(false)} />
      <EssenceHuntModalMobile isOpen={isEssenceHuntModalOpen} />
      <LogJournal size={currentSize} />
    </>
  );

  return (
    <Box sx={isUiBlocked ? { ...sx.mainBox, pointerEvents: 'none' } : sx.mainBox}>
      <BackgroundOverlay />
      {isEmpty(gameState) ? (
        <Box sx={sx.loader}>
          <Logo withLoader />
        </Box>
      ) : (
        roomElements()
      )}
    </Box>
  );
};
