import { CardTypeDetails, Rule } from '../features/ruleBook';
import { CardNames, EnergyColors, PlayingCardType } from '../features/card/cardTypes';
import { resolveCardImage, ENERGY_COLORS_BY_SHELL_COLOR } from '../features/card/cardTransformers';
import { MAX_POSSIBLE_ESSENCES } from '../features/game/rules/roles';
import { EXTENDED_MODE, GAME_MODES, CLASSIC_MODE } from '../features/game/gameTypes';
import { isMobile } from 'react-device-detect';

export const ROLES_SECTION_TITLE = 'Roles';

export const GAME_STORY = [
  'On the distant planet Quadraen, a strange and deadly virus known as Delta spores landed and began to alter the inhabitants of the planet. Those who were altered by the virus were known as Deltared, and they were ruthless and bloodthirsty creatures that sought to deplete all those who were not infected of their essences.',
  'Dr. Ortega, a brilliant scientist, managed to develop a vaccine that stopped the spread of the Delta virus, and those who were not infected were known as Immunars. But as Ortega worked to save the inhabitants of Quadraen, he made a shocking discovery: the Immunars and Deltared were both doomed to de-essencing.',
  'For reasons unknown, Ortega came to believe that the only way for the planet to thrive was to de-essence both the Immunars and the Deltared. And so, he became a leader of a group known as the Ortegonists, who were dedicated to depleting both groups and establishing a new order on Quadraen.',
  'The Immunars, naturally, were determined to stop the Ortegonists and save themselves from de-essencing. They sought to deplete all the Deltared and Ortegonists, and restore peace to the planet.',
  'The Deltared, on the other hand, were intent on depleting all other living forms on Quadraen, who they saw as a threat to their own survival. They believed that as long as they are the only ones left, they would have no opposition on Quadraen and could rule supreme.',
  'The essence was the life-force of all living beings on Quadraen. It could be stolen, traded, or won through combat, and those who possessed a large number of essences had a greater chance of victory in battle. Quadraen was a planet with four sources of energy: red, blue, black, and yellow. These energies could be harnessed using special gloves called "Nebula Gauntlets", which allowed the wearer to create powerful nebulas, or weapons, that could be used in combat.',
  'And so, a fierce battle raged on Quadraen, as the three groups fought for control of the planet. The Immunars fought for their very survival, the Deltared for their own domination, and the Ortegonists for a twisted vision of a better future. Who will emerge victorious in this deadly struggle for the fate of Quadraen? The outcome remains to be seen...',
];

export const RULES: Rule[] = [
  {
    sectionTitle: 'Overview',
    rules: [
      'The game can be played by 3 to 8 players',
      'Each player is assigned one of the 3 roles: Immunar, Deltared or Ortegonist',
      'The goal of each role is to deplete the players of the opposite roles of their essences (life points)',
      'The game ends, when only the players of one role are left in the game',
      'The energy sources (red, blue, black, and yellow) are required for performing attacking and defending actions with Nebula and Radiance cards',
    ],
  },
  {
    sectionTitle: 'Starting the Game',
    rules: [
      'At the beginning of the game, each player is randomly assigned a role that is hidden from other players' +
        (isMobile ? '' : ` (number of players in each role is shown in the ${ROLES_SECTION_TITLE} section)`) +
        '.',
      'Each player is dealt 5 cards.',
      'The number of essences (life points) possessed by each player depends on the number of players in the game' +
        (isMobile ? '' : ` (detailed distribution is shown in the ${ROLES_SECTION_TITLE} section)`) +
        '.',
      'The game creator takes the first turn, the turns are then passed to other players in a clockwise direction.',
    ],
  },
  {
    sectionTitle: 'Card Types',
    rules: [
      '<u><b>Arsenal</b></u> - Is activated by placing it in the Arsenal slot. The player can only have 1 card of each Arsenal type equipped at a time. Equipped card of any Arsenal type can be changed once per turn.',
      '<u><b>Nebulas</b></u> - Can only be played with the equipped Gauntlet or with the Energy capsule card. Allows to deplete, steal essences, block, redirect nebulas, etc. Can be blocked by shells.',
      '<u><b>Radiances</b></u> - Can only be played with the equipped Gauntlet or with the Energy capsule card. Unlike Nebulas, cannot be blocked by shells. After it is applied to the player, the card remains active until the specified conditions on it are met.',
      '<u><b>Capsule</b></u> - Can be played without the energy. Gives the possibility to get, deplete essences, reveal roles, provide one-time energy source, etc.',
    ],
  },
  {
    sectionTitle: ROLES_SECTION_TITLE,
    rules: [],
  },
  {
    sectionTitle: 'Draw Phase',
    rules: [
      'At the beginning of their turn, players draw 2 cards from the draw deck.',
      'Players may also choose to lose one essence in order to draw 2 additional cards.',
      'This process can be repeated until the player is satisfied with their hand, or until they have only one essence remaining.',
      'Players cannot give up essences once they have begun playing cards.',
      'At the end of their turn, players must have the same number of cards in their hand as the number of active players in the game.',
    ],
  },
  {
    sectionTitle: 'Essence Hunt',
    rules: [
      'If a player does not want to play during their turn, they can drop 2 cards to the discard pile, skip the turn, and gain 1 essence.',
    ],
  },
  {
    sectionTitle: 'Ending the Game',
    rules: [
      'Players cannot play anymore when they are out of essences',
      'If a player loses all of their essences, but their role wins in the end, this player wins as well',
      'When only 2 players remain in the game, each player starts to lose 1 essence at the beginning of their turn until they have only 1 essence remaining',
      '<u><b>Immunars</b></u> win when they have depleted all Ortegonists and Deltared',
      '<u><b>Deltared</b></u> win when they have depleted all Immunars and Ortegonists',
      '<u><b>Ortegonists</b></u> win when they have depleted all Deltared and Immunars',
    ],
  },
  {
    sectionTitle: 'Applying Cards',
    rules: [
      "<b>Arsenal Cards</b>: drag the card from your hand and drop it into the Arsenal section on your Player's card. Each type of Arsenal card can have only one card equipped at a time.",
      "<b>Single-Player Attack Cards</b>: drag the card from your hand and drop it onto the targeted Player's card.",
      '<b>Mass Attack Cards</b>: drag the card from your hand and drop it into the Engagement Zone.',
      '<b>Defending and self applied Cards</b>: drag the card from your hand and drop it into the Engagement Zone.',
      '<b>Discard card</b>: drag the card from your hand and drop it into the Discard pile. Multiple cards can be chosen by holding CTRL (Windows) or CMD (Mac) for mass discard action.',
      'When you drag a card from your hand, all the sections where it can be dropped will be highlighted',
    ],
  },
  {
    sectionTitle: 'Additional rules',
    rules: [
      'The same type card can only be used once per turn when performing an attack. Once a card is played, other cards of the same type in hand become inactive',
      'The same type card limitation is not applicable while using defending cards and "Unchained Essence" cards when the last essence is taken from the player',
      `Each player can only have up to ${MAX_POSSIBLE_ESSENCES} essences`,
      'When the last card from the draw deck is drawn, the discard pile is shuffled to create a new draw deck',
    ],
  },
];

export const allSeparateColors = Object.values(EnergyColors).map((color) => [color]);
export const allColorCardImages = (card: CardNames) =>
  allSeparateColors.flat().map((color) => resolveCardImage(card, [color]));
export const CARD_TYPE_DETAILS: CardTypeDetails[] = [
  {
    type: PlayingCardType.ARSENAL,
    cards: [
      {
        name: CardNames.GAUNTLET,
        colors: allSeparateColors,
        imagePaths: allColorCardImages(CardNames.GAUNTLET),
        details:
          'Gives the ability to create nebulas and radiances of the corresponding energy source. The player can change the gauntlet (remove it from the table and put it to the hand and vice versa) during their turn, but only once',
        amountInDeck: 3,
        gameModes: GAME_MODES,
      },
      {
        name: CardNames.SHELL,
        colors: [ENERGY_COLORS_BY_SHELL_COLOR['YELLOW_BLUE'], ENERGY_COLORS_BY_SHELL_COLOR['RED_BLACK']],
        imagePaths: [
          resolveCardImage(CardNames.SHELL, ENERGY_COLORS_BY_SHELL_COLOR['YELLOW_BLUE']),
          resolveCardImage(CardNames.SHELL, ENERGY_COLORS_BY_SHELL_COLOR['RED_BLACK']),
        ],
        details:
          'Enables protection from any nebula of the same energy source. The player can change the shell (remove it from the table and put it to the hand and vice versa) during their turn, but only once. Does not protect from radiances',
        amountInDeck: 2,
        gameModes: GAME_MODES,
      },
      {
        name: CardNames.BACKPACK,
        details: 'Allows +2 cards on the hand. The player can carry only one backpack',
        amountInDeck: 2,
        imagePaths: [resolveCardImage(CardNames.BACKPACK)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.DOME,
        details:
          "Reduces player's vulnerability to nebulas. If attacked by nebula, the player under the dome rolls the quantum dice. If it shows 1 or 6, the nebula launched toward the player is blocked. Does not protect from radiances",
        amountInDeck: 2,
        imagePaths: [resolveCardImage(CardNames.DOME)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
    ],
  },
  {
    type: PlayingCardType.NEBULA,
    cards: [
      {
        name: CardNames.TELEPORT,
        details:
          'The creator chooses any card from player A and chooses player B to teleport it to. The active radiance can be teleported as well. The creator can be player A or player B',
        amountInDeck: 4,
        imagePaths: [resolveCardImage(CardNames.TELEPORT)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.DE_ESSENCER,
        details: 'Destroys one of the essences of the chosen player',
        amountInDeck: 25,
        imagePaths: [resolveCardImage(CardNames.DE_ESSENCER)],
        gameModes: GAME_MODES,
      },
      {
        name: CardNames.BLACK_HOLE,
        details: 'Absorbs any nebula, launched toward the player',
        amountInDeck: 6,
        imagePaths: [resolveCardImage(CardNames.BLACK_HOLE)],
        gameModes: GAME_MODES,
      },
      {
        name: CardNames.SPY,
        details: 'Reveals identity - gives the possibility to check the role of any player',
        amountInDeck: 2,
        imagePaths: [resolveCardImage(CardNames.SPY)],
        gameModes: GAME_MODES,
      },
      {
        name: CardNames.CONVERSIONER,
        details:
          'Converts any player into Ortegonist and reveals the role to everyone. If the player is already Ortegonist, just reveals the role. The card is available in the game with 4 or more players',
        amountInDeck: 1,
        imagePaths: [resolveCardImage(CardNames.CONVERSIONER)],
        gameModes: GAME_MODES,
      },
      {
        name: CardNames.MASS_DE_ESSENCER,
        details: 'Destroys one essence of all the players, except for the one who created it',
        amountInDeck: 2,
        imagePaths: [resolveCardImage(CardNames.MASS_DE_ESSENCER)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.ESSENCE_THIEF,
        details: 'Allows to steal the essence from any other player',
        amountInDeck: 2,
        imagePaths: [resolveCardImage(CardNames.ESSENCE_THIEF)],
        gameModes: GAME_MODES,
      },
      {
        name: CardNames.CHALLENGE,
        details:
          'Starts dice challenge between 2 players. Both players have to roll the quantum dice, starting with the challenge creator. The one who has a lower number loses the challenge and the essence respectively. If equal, the challenge finishes without a winner. Space distortion dismisses the challenge',
        amountInDeck: 4,
        imagePaths: [resolveCardImage(CardNames.CHALLENGE)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.SPACE_DISTORTION,
        details:
          'Redirects nebula to the creator. Redirected nebula changes energy color to the same color as space distortion',
        amountInDeck: 3,
        imagePaths: [resolveCardImage(CardNames.SPACE_DISTORTION)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.DISINTEGRATOR,
        details:
          "Disintegrates any card from any player's hand or equipped Arsenal and puts it in the discard pile. If the card is taken from the hand, it is not revealed",
        amountInDeck: 4,
        imagePaths: [resolveCardImage(CardNames.DISINTEGRATOR)],
        gameModes: GAME_MODES,
      },
    ],
  },
  {
    type: PlayingCardType.RADIANCE,
    cards: [
      {
        name: CardNames.DISORIENTATION,
        details:
          'The player with the applied radiance has to roll the quantum dice at the beginning of the turn. If the number is 1 or 6, the player liquidated the radiance and can continue playing. If not, the player misses the turn. In either outcome card with the radiance goes to the discard pile after the quantum dice roll. If you want to skip a turn, you anyway need to check the radiance first',
        amountInDeck: 3,
        imagePaths: [resolveCardImage(CardNames.DISORIENTATION)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.SQUANDER,
        details:
          'Next time the player with the applied radiance rolls the quantum dice, 3 is deducted from the result. If the result is less than 3, it is considered to be NULL',
        amountInDeck: 2,
        imagePaths: [resolveCardImage(CardNames.SQUANDER)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
    ],
  },
  {
    type: PlayingCardType.CAPSULE,
    cards: [
      {
        name: CardNames.ESSENCE_SHIELD,
        details:
          "Protects the Player's essence from being destroyed or stolen. Can be played as a defensive card to an attacking Nebula or Capsule that aims to destroy or steal essence.",
        amountInDeck: 7,
        imagePaths: [resolveCardImage(CardNames.ESSENCE_SHIELD)],
        gameModes: GAME_MODES,
      },
      {
        name: CardNames.EQUALIZER,
        details: 'Washes out one essence from the player, against which the card is played',
        amountInDeck: 12,
        imagePaths: [resolveCardImage(CardNames.EQUALIZER)],
        gameModes: GAME_MODES,
      },
      {
        name: CardNames.MASS_EQUALIZER,
        details:
          'Removes 1 essence from all the players, even from the one, who played it. The effect can be dismissed by the "Essence shield"',
        amountInDeck: 2,
        imagePaths: [resolveCardImage(CardNames.MASS_EQUALIZER)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.UNCHAINED_ESSENCE,
        details:
          "Encloses one more essence for any Player. Can be applied during player's turn to self or any other player. Exception - if the last essence from the defending player is being taken, unchained essence can be played right away as a defending card",
        amountInDeck: 8,
        imagePaths: [resolveCardImage(CardNames.UNCHAINED_ESSENCE)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.MASS_ESSENCE_ESCAPE,
        details:
          "Initiates unchained essence release. All the players enclose +1 essence for themselves. Can be applied during player's turn. Exception - if the last essence from the defending player is being taken, can be played right away as a defending card",
        amountInDeck: 1,
        imagePaths: [resolveCardImage(CardNames.MASS_ESSENCE_ESCAPE)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.FOGGER,
        details:
          'Creates a fog so that nobody can see how the player changes the result of the quantum dice. The player can re-roll the quantum dice twice if needed right after it has been randomized',
        amountInDeck: 1,
        imagePaths: [resolveCardImage(CardNames.FOGGER)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.AMNESIA,
        details:
          'Erases the memory of the rolled quantum dice number and allows the player to roll the quantum dice again, right after it has been rolled the first time',
        amountInDeck: 2,
        imagePaths: [resolveCardImage(CardNames.AMNESIA)],
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
      {
        name: CardNames.REVEALER,
        details:
          "Reveals the role of the chosen player, adds one essence to this player. If player's role was already visible to all players, essence is not added. Can be played against any player, even the one who uses the capsule",
        amountInDeck: 2,
        imagePaths: [resolveCardImage(CardNames.REVEALER)],
        gameModes: GAME_MODES,
      },
      {
        name: CardNames.ENERGY,
        colors: allSeparateColors,
        details:
          'Gives the one-time ability to create nebulas and radiances of the corresponding energy source. The card is applied automatically, if chosen in the Choose Energy dialog',
        amountInDeck: 2,
        imagePaths: allColorCardImages(CardNames.ENERGY),
        gameModes: [CLASSIC_MODE, EXTENDED_MODE],
      },
    ],
  },
];
