import { SizeNormal } from './sizes';
import { fromTo } from './transformers';
import { ProjectColors } from '../themes/mainTheme';
import { WindowSize } from './utils';
import { HEADER_HEIGHT } from '../global/app/header/header.styles';
import { ImagePath } from '../assets/assets';
import { Theme } from '@mui/material/styles';
import { MOBILE_CARD_SIZE_MODIFIER } from '../components/card';
import { EXTRA_SMALL_WIDTH, MEDIUM_SIZE_HEIGHT, MOBILE_WIDTH } from '../constants/components';
import { SxProps } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { keyframes } from '@emotion/react';
import { DisplayMode, PlayerState } from '../features/player/player.types';

export type SxStyles = {
  [key: string]: SxProps<Theme>;
};

export const RESOLVE_SCROLLBAR_HEIGHT: fromTo<SizeNormal, string> = {
  [SizeNormal.XS]: '0.438rem',
  [SizeNormal.S]: '0.438rem',
  [SizeNormal.M]: '0.563rem',
};

export const RESOLVE_SCROLLBAR_WIDTH: fromTo<SizeNormal, string> = {
  [SizeNormal.XS]: '0.438rem',
  [SizeNormal.S]: '0.438rem',
  [SizeNormal.M]: '0.563rem',
};

const RESOLVE_BUTTON_ANIMATION_WIDTH: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '230%',
  [DisplayMode.TRAY]: '120%',
  [DisplayMode.COMPACT]: '120%',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '120%',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '120%',
  [DisplayMode.STANDARD]: '120%',
};

const RESOLVE_BUTTON_ANIMATION_HEIGHT: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '80%',
  [DisplayMode.TRAY]: '100%',
  [DisplayMode.COMPACT]: '100%',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '100%',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '100%',
  [DisplayMode.STANDARD]: '100%',
};

const RESOLVE_BUTTON_ANIMATION_START: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '-200%',
  [DisplayMode.TRAY]: '-100%',
  [DisplayMode.COMPACT]: '-100%',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '-100%',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '-100%',
  [DisplayMode.STANDARD]: '-100%',
};

const RESOLVE_BUTTON_MAIN_WAVE_EFFECT_START: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '-240%',
  [DisplayMode.TRAY]: '-240%',
  [DisplayMode.COMPACT]: '-290%',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '-290%',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '-290%',
  [DisplayMode.STANDARD]: '-240%',
};

const RESOLVE_BUTTON_MAIN_WAVE_EFFECT_FINISH: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '-30%',
  [DisplayMode.TRAY]: '-30%',
  [DisplayMode.COMPACT]: '-55%',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '-55%',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '150%',
  [DisplayMode.STANDARD]: '-30%',
};

const RESOLVE_BUTTON_DOUBLE_WAVE_EFFECT_START: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '-40%',
  [DisplayMode.TRAY]: '-80%',
  [DisplayMode.COMPACT]: '-40%',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '-40%',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '-40%',
  [DisplayMode.STANDARD]: '-40%',
};

const RESOLVE_BUTTON_DOUBLE_WAVE_EFFECT_FINISH: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '120%',
  [DisplayMode.TRAY]: '120%',
  [DisplayMode.COMPACT]: '155%',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '155%',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '155%',
  [DisplayMode.STANDARD]: '120%',
};

const RESOLVE_BUTTON_MAIN_WAVE_EFFECT_WIDTH: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '150%',
  [DisplayMode.TRAY]: '150%',
  [DisplayMode.COMPACT]: '210%',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '210%',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '210%',
  [DisplayMode.STANDARD]: '155%',
};

const RESOLVE_BUTTON_DOUBLE_WAVE_EFFECT_DURATION: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '3.6s',
  [DisplayMode.TRAY]: '3.6s',
  [DisplayMode.COMPACT]: '2.4s',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '2.4s',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '2.4s',
  [DisplayMode.STANDARD]: '3.6s',
};

const RESOLVE_BUTTON_MAIN_ZOOM_EFFECT_DURATION: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '3.6s',
  [DisplayMode.TRAY]: '3.6s',
  [DisplayMode.COMPACT]: '2.4s',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '2.4s',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '2.4s',
  [DisplayMode.STANDARD]: '3.6s',
};

const RESOLVE_BUTTON_DOUBLE_ZOOM_EFFECT_DURATION: fromTo<DisplayMode, string> = {
  [DisplayMode.MOBILE]: '4.6s',
  [DisplayMode.TRAY]: '4.6s',
  [DisplayMode.COMPACT]: '3.4s',
  [DisplayMode.OTHER_PLAYER_COMPACT]: '3.4s',
  [DisplayMode.OTHER_PLAYER_MOBILE]: '3.4s',
  [DisplayMode.STANDARD]: '4.6s',
};

const RESOLVE_ANIMATION_Z_INDEX: fromTo<DisplayMode, number> = {
  [DisplayMode.MOBILE]: -1,
  [DisplayMode.TRAY]: -1,
  [DisplayMode.COMPACT]: -1,
  [DisplayMode.OTHER_PLAYER_COMPACT]: 0,
  [DisplayMode.OTHER_PLAYER_MOBILE]: 2,
  [DisplayMode.STANDARD]: -1,
};

const activePlayerBorder = `0.2rem solid ${ProjectColors.ACTIVE_PLAYER_BOARDER}`;

const playerCardBackgroundWaveAnimation = (color: ProjectColors, displayMode: DisplayMode) => {
  const mainBlockWaveEffect = keyframes`
      0% {
          left: ${RESOLVE_BUTTON_MAIN_WAVE_EFFECT_START[displayMode]};
      }
      100% {
          left: ${RESOLVE_BUTTON_MAIN_WAVE_EFFECT_FINISH[displayMode]};
      }
  `;

  const sliderWaveEffect = keyframes`
      0% {
          left: ${RESOLVE_BUTTON_DOUBLE_WAVE_EFFECT_START[displayMode]};
      }
      100% {
          left: ${RESOLVE_BUTTON_DOUBLE_WAVE_EFFECT_FINISH[displayMode]};
      }
  `;

  return {
    overflow: 'hidden',
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      width: `${RESOLVE_BUTTON_MAIN_WAVE_EFFECT_WIDTH[displayMode]}`,
      height: '100%',
      background: `${color}`,
      animation: `${mainBlockWaveEffect} 2.1s forwards`,
      transform: 'skew(45deg)',
      zIndex: RESOLVE_ANIMATION_Z_INDEX[displayMode],
    },
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '30%',
      height: '100%',
      background: `linear-gradient(0deg, transparent, transparent, ${color}, transparent)`,
      transform: 'skew(45deg)',
      animation: `${sliderWaveEffect} ${RESOLVE_BUTTON_DOUBLE_WAVE_EFFECT_DURATION[displayMode]} forwards`,
      zIndex: 2,
    },
  };
};

const playerCardBackgroundZoomAnimation = (color: ProjectColors, displayMode: DisplayMode) => {
  const zoomIn = keyframes`
      0% {
          transform: scale(0);
      }
      100% {
          transform: scale(1);
      }
  `;

  return {
    overflow: 'hidden',
    position: 'relative',
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-50%',
      left: '-50%',
      width: '200%',
      height: '200%',
      background: zoomAnimationBackground(color, displayMode),
      animation: `${zoomIn} ${RESOLVE_BUTTON_MAIN_ZOOM_EFFECT_DURATION[displayMode]} forwards`,
      zIndex: RESOLVE_ANIMATION_Z_INDEX[displayMode],
    },
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: '-75%',
      left: '-75%',
      width: '250%',
      height: '250%',
      background: `radial-gradient(closest-side, ${ProjectColors.TRANSPARENT} 85%, ${color}, ${ProjectColors.TRANSPARENT} 95%)`,
      animation: `${zoomIn} ${RESOLVE_BUTTON_DOUBLE_ZOOM_EFFECT_DURATION[displayMode]} forwards`,
      zIndex: RESOLVE_ANIMATION_Z_INDEX[displayMode],
    },
  };
};

const zoomAnimationBackground = (color: ProjectColors, displayMode: DisplayMode): string => {
  if (displayMode === DisplayMode.OTHER_PLAYER_MOBILE) {
    return `radial-gradient(closest-side, ${ProjectColors.TRANSPARENT} 65%, ${color} 75%, ${ProjectColors.TRANSPARENT})`;
  }

  return `radial-gradient(closest-side, ${color} 90%, ${ProjectColors.TRANSPARENT})`;
};

const defaultScrollbarStyles = {
  '&::-webkit-scrollbar-track': {
    boxShadow: `inset 0 0 6px ${ProjectColors.GREY}`,
    backgroundColor: ProjectColors.TRANSPARENT,
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: ProjectColors.PURPLE_VERY_DARK_TRANSPARENT,
    border: `1px solid ${ProjectColors.GREY}`,
  },
};

export const horizontalScrollbarStyle = (size: SizeNormal) => ({
  ...defaultScrollbarStyles,
  overflowX: 'auto',
  '&::-webkit-scrollbar': { height: RESOLVE_SCROLLBAR_HEIGHT[size] },
});

export const verticalScrollbarStyle = (size: SizeNormal) => ({
  ...defaultScrollbarStyles,
  overflowY: 'auto',
  '&::-webkit-scrollbar': { width: RESOLVE_SCROLLBAR_WIDTH[size] },
});

export const getPlayerBoxSxFromState = (playerState: PlayerState, mode: DisplayMode, useOutline: boolean = false) => {
  const frame = useOutline ? 'outline' : 'border';

  switch (playerState) {
    case PlayerState.WINNER:
      return {
        ...playerCardBackgroundWaveAnimation(ProjectColors.WINNER_PLAYER_BACKGROUND, mode),
        [frame]: `0.2rem solid ${ProjectColors.WINNER_PLAYER_BOARDER}`,
      };
    case PlayerState.OUT_OF_GAME:
      return {
        ...playerCardBackgroundZoomAnimation(ProjectColors.OUT_OF_GAME_PLAYER_BACKGROUND, mode),
        [frame]: `0.2rem solid ${ProjectColors.OUT_OF_GAME_PLAYER_BOARDER}`,
      };
    case PlayerState.ACTIVE_DEFENDING:
      return {
        ...playerCardBackgroundZoomAnimation(ProjectColors.ACTIVE_DEFENDING_PLAYER_BACKGROUND, mode),
        [frame]: activePlayerBorder,
      };
    case PlayerState.DEFENDING_NOW:
      return {
        ...playerCardBackgroundZoomAnimation(ProjectColors.ACTIVE_DEFENDING_PLAYER_BACKGROUND, mode),
        [frame]: `0.2rem solid ${ProjectColors.ACTIVE_DEFENDING_PLAYER_BOARDER}`,
      };
    case PlayerState.ACTIVE_DEFENDING_PENDING:
      return {
        ...playerCardBackgroundWaveAnimation(ProjectColors.DEFENDING_PLAYER_BACKGROUND, mode),
        [frame]: activePlayerBorder,
      };
    case PlayerState.DEFENDING_PENDING:
      return {
        ...playerCardBackgroundWaveAnimation(ProjectColors.DEFENDING_PLAYER_BACKGROUND, mode),
        [frame]: `0.2rem solid ${ProjectColors.DEFENDING_PLAYER_BOARDER}`,
      };
    case PlayerState.ACTIVE_ATTACKING:
      return {
        ...playerCardBackgroundWaveAnimation(ProjectColors.ACTIVE_PLAYER_BACKGROUND, mode),
        [frame]: activePlayerBorder,
      };
    case PlayerState.INACTIVE_ATTACKING:
      return {
        ...playerCardBackgroundWaveAnimation(ProjectColors.ACTIVE_PLAYER_BACKGROUND, mode),
        [frame]: `0.2rem solid ${ProjectColors.ACTIVE_DEFENDING_PLAYER_BOARDER}`,
      };
    case PlayerState.IN_GAME:
      return {
        ...playerCardBackgroundWaveAnimation(ProjectColors.PLAYER_BACKGROUND_TRANSPARENT, mode),
        [frame]: `1px solid ${ProjectColors.BLACK}`,
      };
  }
};

export const getBackgroundBoxSx = (windowSize: WindowSize, isMobileSize: boolean) => {
  const roomBackgroundImage = ImagePath.roomBackground;

  return {
    m: 0,
    p: 5,
    overflowY: 'auto',
    height: isMobileSize ? `${windowSize.height - 1}px` : `${windowSize.height - HEADER_HEIGHT - 1}px`,
    backgroundImage: `url(${roomBackgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };
};

export const calculateScreenSizeModifier = (windowSize: WindowSize) => {
  let heightModifier = windowSize.height >= MEDIUM_SIZE_HEIGHT ? 1 : windowSize.height / MEDIUM_SIZE_HEIGHT;
  let widthModifier = windowSize.width >= MOBILE_WIDTH ? 1 : windowSize.width / MOBILE_WIDTH;

  if (isMobile) {
    heightModifier += MOBILE_CARD_SIZE_MODIFIER;
    widthModifier += MOBILE_CARD_SIZE_MODIFIER;
  }

  return Math.min(heightModifier, widthModifier);
};

export const settingsWidgetStyles = () => ({
  fontSize: isMobile ? '1.5rem' : { xs: '15px', sm: '20px', md: '25px', lg: '30px' },
});

export const tooltipStyles = () => ({ fontSize: { xs: '0.3rem', sm: '0.5rem', md: '0.7rem', lg: '0.8rem' } });
export const containerFontStyle = isMobile ? '0.65rem' : { xs: '0.4rem', sm: '0.6rem', md: '0.7rem', lg: '0.85rem' };

export const isTrayAllowed = (windowSize: WindowSize) => windowSize.width > EXTRA_SMALL_WIDTH;

export const logoStyles = () => ({ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' });

export const roomCommonStyles = (windowSize: WindowSize) => ({
  loader: { mt: `${windowSize.height * 0.35}px` },
});

export const getActiveActionButtonSx = (animateInSeconds: number, displayMode: DisplayMode, color: ProjectColors) => {
  const waveEffect = keyframes`
      0% {
          left: ${RESOLVE_BUTTON_ANIMATION_START[displayMode]};
      }
      100% {
          left: 100%;
      }
  `;

  return {
    overflow: 'hidden',
    position: 'relative',
    '&:after': {
      content: '""',
      display: 'block',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: RESOLVE_BUTTON_ANIMATION_WIDTH[displayMode],
      height: RESOLVE_BUTTON_ANIMATION_HEIGHT[displayMode],
      background: `linear-gradient(0deg, transparent, ${color}, transparent)`,
      transform: 'rotate(45deg)',
      animation: `${waveEffect} ${animateInSeconds}s infinite linear`,
      zIndex: 1,
    },
  };
};

export const animationCollapse = (height: number) => {
  const hiddenEffect = keyframes`
      from {
          height: ${height}px;
      }
      to {
          height: 0;
          display: none;
      }
  `;

  return {
    animation: `${hiddenEffect} 0.5s linear forwards`,
  };
};

export const animationExpand = (height: number) => {
  const visibleEffect = keyframes`
      from {
          height: 0;
          display: none;
      }
      to {
          height: ${height}px;
      }
  `;

  return {
    animation: `${visibleEffect} 0.5s linear forwards`,
  };
};
