import { SizeExtended } from '../../helpers/sizes';
import { CARD_SIZE_MODIFIERS, L_SIZE_RADIUS } from './card.types';
import { ProjectColors } from '../../themes/mainTheme';
import { BOX_SHADOW_ELEMENT_HIGHER_LAYER } from '../../constants/styles';
import { zIndexes } from '../../constants/components';

export const initSx = (
  size: SizeExtended,
  dimension: number,
  screenSizeModifier: number,
  backgroundColor: ProjectColors,
  rotation: number,
  isActive: boolean
) => {
  const scaleValueByCardSize = (value: number) => {
    if (size === SizeExtended.XXS) return value * CARD_SIZE_MODIFIERS[SizeExtended.M];
    return value * CARD_SIZE_MODIFIERS[size];
  };

  const scaleValueByScreenSize = (value: number) => {
    return screenSizeModifier * value;
  };

  const calculatedHeight = () => {
    if (size === SizeExtended.L) return dimension * 1.4;
    return dimension;
  };

  return {
    card: {
      height: calculatedHeight(),
      maxHeight: calculatedHeight(),
      minWidth: dimension,
      width: dimension,
      borderRadius: `${scaleValueByScreenSize(scaleValueByCardSize(L_SIZE_RADIUS))}px`,
      rotate: `${rotation}deg`,
      backgroundColor: ProjectColors.TRANSPARENT,
      boxShadow: backgroundColor !== ProjectColors.TRANSPARENT && isActive ? BOX_SHADOW_ELEMENT_HIGHER_LAYER : 'none',
      position: 'relative',
      zIndex: zIndexes.one,
    },
    ruleBookSize: `${scaleValueByScreenSize(90) + '%'}`,
  };
};
