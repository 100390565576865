import { createApiAuthRequest } from '../../game/gameContextHandler/utils/api';
import { logResponseErr } from '../../../helpers/utils';
import { UsePredictionApi } from './usePredictionApi.types';
import React from 'react';
import { ApiContext } from '../../../global/app/api/apiContext';
import { useGameId } from '../../game/gameContextHandler/hooks/useGameId';
import { PredictionTargetsApiData } from '../predictionTypes';
import { isEmpty } from '../../../helpers/arrays';
import { GameContext } from '../../game/gameContext';
import { getCurrentPlayer } from '../../game/gameContextHandler/utils/player';
import { isFlagOn, isFlagSet } from '../../../helpers/featureFlags';
import { ENABLE_PREDICTIONS } from '../../../constants/featureFlags';
import { AnimationContext } from '../../game/animationContextProvider';

export const usePredictionApi: UsePredictionApi = () => {
  const { predictionApi } = React.useContext(ApiContext);
  const { gameState } = React.useContext(GameContext);
  const { queuePredictionTargetProps, setAnimatingPredictionTargets } = React.useContext(AnimationContext);

  const id = useGameId();

  const [predictionTargets, setPredictionTargets] = React.useState<PredictionTargetsApiData>();

  React.useEffect(() => {
    if (!isFlagSet(ENABLE_PREDICTIONS) || !isFlagOn(ENABLE_PREDICTIONS)) return;

    setAnimatingPredictionTargets(undefined);
    const currentPlayer = getCurrentPlayer(gameState);
    if (currentPlayer.id !== gameState.activePlayer || gameState.activeDefendingPlayer !== '') return;

    void fillPredictionTargets();
  }, [gameState.turnActions.length]);

  React.useEffect(() => {
    if (!isFlagSet(ENABLE_PREDICTIONS) || !isFlagOn(ENABLE_PREDICTIONS)) return;
    if (!predictionTargets || isEmpty(predictionTargets.targets)) return;

    predictionTargets.targets
      .filter((target) => target.shouldAttack)
      .forEach((target) => {
        queuePredictionTargetProps({
          playerId: target.playerId,
          probabilityPercentage: target.probability * 100,
        });
      });

    console.group(`prediction targets: round ${gameState.roundNumber}, action ${gameState.turnActions.length}`);
    console.table(predictionTargets.targets);
    console.groupEnd();
  }, [predictionTargets]);

  const fillPredictionTargets = async () => {
    try {
      const predictionTargetsApiData = await predictionApi.getPredictionTargets(id, createApiAuthRequest());
      setPredictionTargets(predictionTargetsApiData);
    } catch (e: any) {
      logResponseErr(e);
    }
  };
};
